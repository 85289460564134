@import 'src/assets/scss/variables';

// Text Align
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-start {
  text-align: start !important;
}

.va-middle {
  vertical-align: middle!important;
}

.align-top {
  vertical-align: top;
}

.whitespace-pre-line {
  white-space: pre-line;
}

// Text Weight
.f-400 {
  font-weight: 400 !important;
}

.f-500 {
  font-weight: 500 !important;
}

.f-600 {
  font-weight: 600 !important;
}

.f-700 {
  font-weight: 700 !important;
}

.f-800 {
  font-weight: 800 !important;
}

.text-bold {
  font-weight: 700 !important;
}

// Text Color
.c-primary-light {
  color: $primary-light !important;
}

.c-primary {
  color: $primary !important;
}

.c-primary-darker {
  color: $primary-darker !important;
}

.c-accent-light {
  color: $accent-light;
}

.c-accent {
  color: $accent;
}

.c-accent-darker {
  color: #E8C248;
}

.c-warn-light {
  color: $warn-light !important;
}

.c-warn {
  color: $warn !important;
}

.c-gray-light {
  color: $gray-light !important;
}

.c-gray {
  color: $gray !important;
}

.c-gray-deep {
  color: $gray-deep !important;
}

.c-gray-dark {
  color: $gray-dark !important;
}

.c-gray-ca {
  color: #cacaca !important;
}

.c-gray-da {
  color: #dadada !important;
}

.c-gray-ea {
  color: #eaeaea !important;
}

.c-white {
  color: white !important;
}

.c-mint {
  color: #25ccbe !important;
}

.c-mint-dark {
  color: #14b2ae !important;
}

.c-black {
  color: #212121 !important;
}

.c-dark {
  color: #000000;
}

.c-gn-light{
 color: #E7F2EE !important;
}

// Text Size
.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 0.5em !important;
  -webkit-transform: scale(0.7) !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-25 {
  font-size: 25px !important;
}

.f-30 {
  font-size: 30px !important;
}

.f-35 {
  font-size: 35px !important;
}

.f-40 {
  font-size: 40px !important;
}

.f-50 {
  font-size: 50px !important;
}

.f-60 {
  font-size: 60px;
}

.f-70 {
  font-size: 70px !important;
}

.f-80 {
  font-size: 80px !important;
}

.f-90 {
  font-size: 90px;
}

.f-100 {
  font-size: 100px;
}

.f-110 {
  font-size: 110px;
}

.f-120 {
  font-size: 120px;
}

.f-130 {
  font-size: 130px;
}

// Text LineHeight
.lh-0 {
  line-height: 0 !important;
}

.lh-1-4 {
  line-height: 1.4 !important;
}

.lh-1-5 {
  line-height: 1.5 !important;
}

.lh-1-6 {
  line-height: 1.6 !important;
}

.lh-1-7 {
  line-height: 1.7 !important;
}

.lh-1-8 {
  line-height: 1.8 !important;
}

.lh-22 {
  line-height: 22px !important;
}

// Text Circle
.number-circle {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background: #dadada;
  color: #555555;
  text-align: center;
  font-size: 12px;
}

.number-circle-subhead {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  display: inline-block;
  background: $primary-light;
  color: white;
  text-align: center;
  font-size: 12px;
  margin: 0 7px 10px 0;
}

.text-circle {
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
  line-height: 50px !important;
  border-radius: 50%;
  text-align: center;
}

.text-circle-mini {
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
  line-height: 50px !important;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
}

.text-circle-40 {
  display: inline-block;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px !important;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.text-circle-35 {
  display: inline-block;
  min-width: 35px;
  min-height: 35px;
  line-height: 35px !important;
  border-radius: 50%;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
}

.text-circle-32 {
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  text-align: center;
}

.text-circle-30 {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  text-align: center;
}

.text-circle-20 {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  text-align: center;
}

.text-circle-60 {
  display: inline-block;
  min-width: 60px;
  min-height: 60px;
  line-height: 60px !important;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
}

.text-circle-70 {
  display: inline-block;
  min-width: 70px;
  min-height: 70px;
  line-height: 70px !important;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
}

// Text Badge
.text-badge {
  width: fit-content;
  border: 1px solid $gray-light;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.text-badge-gray {
  width: fit-content;
  border: 1px solid $gray;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.text-badge-warn-s {
  width: fit-content;
  border: 1px solid $warn;
  border-radius: 20px;
  padding: 3px 10px 5px 10px !important;
  font-size: 11px !important;
  font-weight: 600;
  line-height: 11px !important;
  color: $warn;
}
.text-badge-warn-fill {
  width: fit-content;
  background-color: $warn;
  border-radius: 20px;
  padding: 4px 10px 6px 10px !important;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 12px !important;
  color: #ffffff;
}

.text-badge-white {
  width: fit-content;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 15px;
  padding: 3px 8px !important;
  font-size: 12px;
  line-height: 12px !important;
  font-weight: 500;
}

.badge {
  background-color: $accent-light;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 11px !important;
  line-height: 11px !important;
  font-weight: 500;
  color: #212121;
}

.badge-title {
  background-color: $primary-darker;
  color: #ffffff;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.badge-kr {
  margin-right: 5px;
  padding: 3px 10px 5px 10px !important;
  border-radius: 20px;
  font-size: 12px !important;
  line-height: 13px !important;
  font-weight: 500;
  text-align: center;
}

.badge-kr-md {
  min-width: 30px;
  padding: 6px 10px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}

// Text Underline
.underline {
  line-height: 1.2;
  background-image: linear-gradient(transparent calc(100% - 3px), #212121 3px);
  background-repeat: no-repeat;
  transition: background-size 0.8s;
  color: #212121;
}

.underline-slim {
  line-height: 1.2;
  background-image: linear-gradient(transparent calc(100% - 3px), #212121 3px);
  background-repeat: no-repeat;
  transition: background-size 0.8s;
  color: inherit;
}

.underline {
  background-size: 100% 90%;
}

.underline-slim {
  background-size: 100% 110%;
}

.underline.primary-light {
  background-image: linear-gradient(transparent 70%, $primary-light 70%);
}

.underline.primary {
  background-image: linear-gradient(transparent 70%, $primary-light 20%);
}

.underline.accent {
  background-image: linear-gradient(transparent 70%, $accent-light 20%);
}

.underline.mint {
  background-image: linear-gradient(transparent 70%, #8CD8D9 20%);
}

.underline.green {
  background-image: linear-gradient(transparent 70%, #85d58f 30%);
}

.underline.warn {
  background-image: linear-gradient(transparent 70%, $warn-light 20%);
}

.underline.gray {
  background-image: linear-gradient(transparent 70%, #dadada 20%);
}

.underline.blue {
  background-image: linear-gradient(transparent 70%, #89AFEEFF 20%);
}

.underline.white {
  background-image: linear-gradient(transparent 70%, #ffffff 20%);
}

.underline-slim.blue {
  background-image: linear-gradient(transparent 85%, #89AFEEFF 20%);
}

.underline-slim.primary {
  background-image: linear-gradient(transparent 85%, $primary-light 20%);
}

.underline-slim.accent {
  background-image: linear-gradient(transparent 85%, $accent 20%);
}

.underline-slim.green {
  background-image: linear-gradient(transparent 85%, #85d58f 20%);
}

.underline-slim.gray {
  background-image: linear-gradient(transparent 85%, #dadada 20%);
}

.underline-slim.warn {
  background-image: linear-gradient(transparent 85%, $warn-light 20%);
}

.underline-slim.white {
  background-image: linear-gradient(transparent 85%, #ffffff 20%);
}

// ETC
.number {
  letter-spacing: 1px;
  font-size: 14px;
}

.text-link {
  text-decoration: underline;
  color: black;
  cursor: pointer;
  font-weight: 600;
}

.text-pre {
  white-space: pre-line;
  line-height: 1.7;
}

.text-loss-100 {
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-loss-57 {
  width: 57px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
