.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #333333;
}

.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 14px !important;
  font-weight: 600 !important;

}

.mat-ink-bar {
  position: absolute;
  bottom: 0;
  height: 3px !important;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
  background-color: #212121 !important;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: 'S-CoreDream-5Medium', "Noto Sans CJK KR";
  --mat-tab-header-label-text-size: 15px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 600;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #212121;

  --mdc-tab-indicator-active-indicator-color: #0fa564;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0fa564;
  --mat-tab-header-active-ripple-color: #0fa564;
  --mat-tab-header-inactive-ripple-color: #0fa564;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0fa564;
  --mat-tab-header-active-hover-label-text-color: #0fa564;
  --mat-tab-header-active-focus-indicator-color: #0fa564;
  --mat-tab-header-active-hover-indicator-color: #0fa564;
}


.mat-mdc-tab-label-container{
  border-bottom: 1px solid #E4E4E4 !important;
}
