@import './variables';

:focus {
  outline: none;
}

.hover:hover {
  background: inherit!important;
  font-weight: 700;
}

.hover-primary:hover {
  background: $primary-darker !important;
  color: #ffffff !important;
}

.hover-gray-deep:hover {
  background: $gray-deep !important;
  color: #ffffff !important;
}

.hover-dark:hover {
  background: #333333 !important;
  color: #ffffff !important;
}
.hover-gray:hover {
  background: #f2f3f4 !important;
  cursor: pointer;
  border: 1px solid #f2f3f4;
}

.hover-gray-fa:hover {
  background: #fafafa !important;
  cursor: pointer;
}

.hover-white:hover {
  background: #ffffff !important;
  color: $primary-darker !important;
}

.hover-primary-line:hover {
  background: #ffffff !important;
  color: $primary-darker !important;
  border: 1px solid $primary-darker !important;
}

.hover-gray-shadow:hover {
  //background: #ffffff !important;
  //color: $gray-dark !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .12), inset 0 -1px 0 0 #dadce0;
  //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  //backdrop-filter: blur(5px);
  //-webkit-backdrop-filter: blur(5px);
}
