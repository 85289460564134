input.text-right::-webkit-outer-spin-button,
input.text-right::-webkit-inner-spin-button {
  display: none !important;
}

input.text-right {
  -moz-appearance: textfield !important;
}

.mat-mdc-form-field-icon-suffix {
  padding: 0 5px 0 4px !important;
}

.mat-mdc-input-element{
  font-weight: 600;
}
