.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33% !important;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.m-w-170 {
  min-width: 170px;
}

.m-h-190 {
  min-height: 190px;
}
