@import "variables";

button {
  box-shadow: none !important;
  font-weight: 500;
}

button:not(.mat-fab):not(.mat-menu-item):not(.swal2-styled):not(.button-mini):not(.mat-mini-fab):not(.mat-icon-button) {
  border-radius: 30px;
  line-height: 25px;
}

.inline-button {
  display: inline !important;
}

.button-mini {
  border-radius: 20px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  padding: 9px 20px !important;
  color: #333333;
  border: 1px solid #dadada;
}

.button-mini-icon {
  border-radius: 20px !important;
  font-size: 13px !important;
  line-height: 13px !important;
  padding: 6px 15px !important;
  color: #333333;
  border: 1px solid #dadada;
}

.button-mini-noborder {
  border-radius: 20px !important;
  font-size: 12px;
  line-height: 13px !important;
  padding: 6px 6px;
  color: #212121;
}

.button-mini-w {
  border-radius: 20px !important;
  font-size: 13px !important;
  line-height: 13px !important;
  padding: 6px 8px !important;
  background-color: inherit !important;
  font-weight: 500;
  color: #444444;
}

.button-category-on {
  border-radius: 20px !important;
  border: 1px solid $primary-darker !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  background-color: inherit;
  padding: 2px 8px !important;
  color: $primary-darker !important;
  margin-bottom: 5px !important;
}

.button-category-off {
  border-radius: 20px !important;
  border: 1px solid $gray !important;
  font-size: 12px !important;
  line-height: 12px !important;
  background-color: inherit;
  padding: 6px 8px !important;
  color: $gray !important;
  margin-bottom: 5px !important;
}

.button-mini-n {
  border-radius: 20px !important;
  font-size: 13px !important;
  line-height: 13px !important;
  background-color: inherit;
  padding: 6px 10px;
  padding-bottom: 5px !important;
}

.button-md {
  border-radius: 20px !important;
  font-size: 13px !important;
  padding: 8px 13px;
  line-height: 13px;
  box-shadow: none !important;
}

.button-md-white {
  border-radius: 10px !important;
  font-size: 13px !important;
  padding: 8px 13px;
  line-height: 13px;
  border: 1px solid #ffffff !important;
}

.button-lg {
  padding: 15px 0px !important;
  border-radius: 20px !important;
  font-size: 14px;
  font-weight: 600 !important;
}

.button-14 {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.button-suffix {
  height: 32px;
  margin-top: 10px !important;
  font-size: 13px !important;
  border: 1px solid $gray !important;
  color: $gray;
  line-height: 30px !important;
}

.button-suffix-primary {
  height: 32px;
  margin-top: 10px !important;
  font-size: 13px !important;
  border: 1px solid $primary-darker !important;
  color: $primary-darker;
  line-height: 32px !important;
}

.cancel-chip {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid $warn;
  background-color: $warn;
  color: #FFFFFF;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.now-change {
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid $accent;
  background-color: $accent;
  color: #212121;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.now-change-guide {
  min-width: 35px;
  padding: 7px 12px 8px 12px;
  border-radius: 20px;
  border: 1px solid $gray-light;
  background-color: $gray-light;
  color: #212121;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
}

.target-chip {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid $primary-darker;
  color: $primary-darker;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.target-chip-bg {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid $primary-darker;
  background-color: $primary-darker;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #212121;
  color: #212121;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip-md {
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid #212121;
  color: #212121;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  text-align: center;
}

.black-chip-bg {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid $gray-light;
  background-color: $gray-light;
  color: #212121;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.accent-chip-bg {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid $accent-light;
  background-color: $accent-light;
  color: #212121;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.primary-bg-chip {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid $primary;
  background-color: $primary;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip-lg {
  min-width: 35px;
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid #212121;
  color: #212121;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-bg-chip {
  min-width: 35px;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-bg {
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid #777777;
  background-color: #777777;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
}

.gray-chip-bg-shot {
  min-width: 24px;
  padding: 5px 9px 6px 9px;
  border-radius: 20px;
  border: 1px solid #999999;
  background-color: #999999;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
}

.black-chip-xs {
  min-width: 25px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #444444;
  color: #444444;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip-bg-xs {
  min-width: 25px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid $gray;
  color: #333333;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip-bg {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #444444;
  background-color: #444444;
  color: white;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}
.black-chip-bg-lg {
  min-width: 35px;
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid #555555;
  background-color: #555555;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.accent-chip-bg {
  min-width: 35px;
  padding: 6px 15px;
  border-radius: 20px;
  border: 1px solid $accent;
  background-color: $accent;
  color: $gray-dark;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.white-chip {
  min-width: 35px;
  padding: 6px 7px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip {
  width: fit-content;
  min-width: 35px;
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid #666666;
  color: #666666;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-open {
  width: fit-content;
  min-width: 35px;
  padding: 5px 2px 6px 2px;
  border-radius: 20px;
  border: 1px solid $gray-dark;
  color: $gray-dark;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-bg {
  width: fit-content;
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid #eaeaea;
  background-color: #eaeaea;
  color: #444444;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-lg {
  width: fit-content;
  min-width: 35px;
  padding: 5px 9px 6px 9px;
  border-radius: 20px;
  border: 1px solid #666666;
  color: #666666;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.primary-bg-chip {
  min-width: 45px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  background-color: $primary;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
}

.send-bg-chip {
  min-width: 45px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: $primary;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.receive-bg-chip {
  min-width: 45px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.title-chip-xs {
  width: fit-content;
  min-width: 30px;
  padding: 4px 9px 5px 9px;
  text-align: center;
  border-radius: 20px;
  background-color: $primary-darker;
  color: #ffffff;
  font-size: 11px !important;
  line-height: 11px !important;
  font-weight: 600;
}

.title-chip-xs-auto {
  width: fit-content;
  min-width: 30px;
  padding: 4px 9px 5px 9px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: $primary;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
}

.title-chip {
  width: fit-content;
  padding: 8px 40px 8px 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #ffffff;
  color: #444444;
  font-size: 13px !important;
  line-height: 13px;
  font-weight: 600 !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09) !important;
}

.title-chip-round {
  width: fit-content;
  padding: 6px 10px 6px 20px;
  border-radius: 50px;
  background-color: $primary;
  color: #ffffff;
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
}

.title-chip-round-info {
  width: fit-content;
  padding: 8px 10px 8px 10px;
  border-radius: 50px;
  background-color: $primary;
  color: #ffffff;
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
}

.expert-type {
  padding: 6px 20px;
  border-radius: 15px;
  font-size: 13px;
  font-weight: 600;
  background: $primary-darker;
  color: white;
  line-height: 13px;
}

.border-inherit {
  border: inherit !important;
}

.circle-step-warn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #F46A70;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 1px solid #F46A70;
}

.circle-step-black {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 1px solid #212121;
}

.circle-step-bg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 14px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  background: #eaeaea;
}

.circle-step-border {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #4283ef;
  text-align: center !important;
  line-height: 60px;
  font-size: 14px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 2px solid #4283ef;
}

.circle-step-bg-lg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center !important;
  line-height: 90px;
  font-size: 15px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  background: #eaeaea;
}

.circle-step-border-lg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  color: #4283ef;
  text-align: center !important;
  line-height: 90px;
  font-size: 15px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 2px solid #4283ef;
}

.cube-step-bg {
  border-radius: 20px;
  color: #212121;
  text-align: center !important;
  font-size: 14px;
  padding: 20px 15px !important;
  font-weight: 600;
  vertical-align: middle !important;
  background: #fafafa;
}

.circle-step-black-dot {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 1px dotted #212121;
}

.icon-circle-wrapper-xs {
  width: 70px;
  border-radius: 20px;
  line-height: 12px;
  padding: 6px 10px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 600;
  text-align: center;
}

.icon-circle-wrapper-xs-primary {
  width: 66px;
  padding: 6px 2px;
  border-radius: 20px;
  line-height: 12px;
  border: 1px solid $primary-darker;
  color: $primary-darker;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 600;
  text-align: center;
}

.mat-badge-accent .mat-badge-content {
  background: $accent !important;
  color: #212121 !important;
}

.img-help {
  padding: 6px 10px;
  border-radius: 4px;
  background-color: $gray-dark;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary !important;
  background-color: $primary !important;
}

.btn-icon-l {
  padding-right: 40%;
  padding-left: 10px;
}

.mdc-button {
  padding: 0 13px;
}
