//hee-su purple
//$primary-light: #A970FF;
//$primary: #964FFF;
//$primary-darker: #6937B3;

//Color
$primary-light: #08C18A;
$primary: #0FA564;
$primary-darker: #097F52;

//dh purple
//$primary-light: #BFACC8;
//$primary: #783F8E;
//$primary-darker: #4F1271;


$accent-light: #FFE07F;
$accent: #FFD54F;
$accent-darker: #D1AF41;

$warn-light: #f2b3b9;
$warn: #F46A70;
$warn-darker: #f1414a;

$gray: #999999;
$gray-light: #f6f7f7;
$gray-deep: #666666;
$gray-dark: #212121;
