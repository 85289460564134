.mat-horizontal-stepper-header-container {
  display: none !important;
}

.mat-horizontal-content-container {
  padding: 0 5px 24px 5px!important;
}

.mat-horizontal-stepper-content {
  padding: 1px;
}


.mat-padding-bottom-60{
  padding-bottom: 60% !important;
}

.mat-padding-bottom-50{
  padding-bottom: 50% !important;
}

.mat-padding-bottom-15{
  padding-bottom: 15% !important;
}

.mat-padding-bottom-5{
  padding-bottom: 5% !important;
}
