.mat-mdc-button {
  //min-width: 50px !important;
}

.mat-mini-fab {
  .mat-button-wrapper {
    font-size: 12px;
    font-weight: 400 !important;
    line-height: 12px !important;
  }
}

.mat-mini-fab {
  font-size: 13px;
  font-weight: 600 !important;
  line-height: 13px !important;
}

.mat-fab {
  .mat-button-wrapper {
    font-size: 13px;
    line-height: 13px !important;
    font-weight: 600;
  }
}

.mat-raised-button {
  padding: 6px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

.mat-stroked-button {
  padding: 6px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}
.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.25);
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: $gray-dark;
  background-color: $gray-dark;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle, .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ffffff;
}
.mat-radio-label-content {
  font-weight: 600;
}

.mat-chip .mat-chip-trailing-icon.mat-icon, .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.mat-chip.mat-standard-chip {
  background-color: #f1f2f3;
  color: rgba(0, 0, 0, 0.87);
}

