.mat-mdc-dialog-title, .mdc-dialog__title{
  font-weight: 700 !important;
}

.mat-mdc-dialog-content, .mdc-dialog__content{
  color: #212121 !important;
  padding-bottom: 60px !important;
  line-height: 1.8 !important;
}

//.mat-mdc-dialog-actions, .mdc-dialog__actions{
//  padding: 0 20px !important;
//}

.mat-mdc-raised-button.mat-mdc-button-base{
  height: 40px !important;
}

.mdc-button__label{
  font-weight: 700 !important;
}


@media (max-width: 400px){
  .mat-mdc-dialog-content, .mdc-dialog__content{
    padding-bottom: 30px !important;
  }
}
