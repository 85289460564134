.m-0 {
  margin: 0px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-h-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.m-v-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-h-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.p-v-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.m-1 {
  margin: 1px !important;
}

.m-l-1 {
  margin-left: 1px !important;
}

.m-t-1 {
  margin-top: 1px !important;
}

.m-r-1 {
  margin-right: 1px !important;
}

.m-b-1 {
  margin-bottom: 1px !important;
}

.m-h-1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.m-v-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.p-1 {
  padding: 1px !important;
}

.p-l-1 {
  padding-left: 1px !important;
}

.p-t-1 {
  padding-top: 1px !important;
}

.p-r-1 {
  padding-right: 1px !important;
}

.p-b-1 {
  padding-bottom: 1px !important;
}

.p-h-1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.p-v-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.m-2 {
  margin: 2px !important;
}

.m-l-2 {
  margin-left: 2px !important;
}

.m-t-2 {
  margin-top: 2px !important;
}

.m-r-2 {
  margin-right: 2px !important;
}

.m-b-2 {
  margin-bottom: 2px !important;
}

.m-h-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.m-v-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.p-2 {
  padding: 2px !important;
}

.p-l-2 {
  padding-left: 2px !important;
}

.p-t-2 {
  padding-top: 2px !important;
}

.p-r-2 {
  padding-right: 2px !important;
}

.p-b-2 {
  padding-bottom: 2px !important;
}

.p-h-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.p-v-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.m-3 {
  margin: 3px !important;
}

.m-l-3 {
  margin-left: 3px !important;
}

.m-t-3 {
  margin-top: 3px !important;
}

.m-r-3 {
  margin-right: 3px !important;
}

.m-b-3 {
  margin-bottom: 3px !important;
}

.m-h-3 {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.m-v-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.p-3 {
  padding: 3px !important;
}

.p-l-3 {
  padding-left: 3px !important;
}

.p-t-3 {
  padding-top: 3px !important;
}

.p-r-3 {
  padding-right: 3px !important;
}

.p-b-3 {
  padding-bottom: 3px !important;
}

.p-h-3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.p-v-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.m-t-4 {
  margin-top: 4px !important;
}

.m-r-4 {
  margin-right: 4px !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-h-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.m-v-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-l-4 {
  padding-left: 4px !important;
}

.p-t-4 {
  padding-top: 4px !important;
}

.p-r-4 {
  padding-right: 4px !important;
}

.p-b-4 {
  padding-bottom: 4px !important;
}

.p-h-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.p-v-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-h-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.m-v-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-h-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.p-v-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.m-6 {
  margin: 6px !important;
}

.m-l-6 {
  margin-left: 6px !important;
}

.m-t-6 {
  margin-top: 6px !important;
}

.m-r-6 {
  margin-right: 6px !important;
}

.m-b-6 {
  margin-bottom: 6px !important;
}

.m-h-6 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.m-v-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.p-6 {
  padding: 6px !important;
}

.p-l-6 {
  padding-left: 6px !important;
}

.p-t-6 {
  padding-top: 6px !important;
}

.p-r-6 {
  padding-right: 6px !important;
}

.p-b-6 {
  padding-bottom: 6px !important;
}

.p-h-6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.p-v-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.m-7 {
  margin: 7px !important;
}

.m-l-7 {
  margin-left: 7px !important;
}

.m-t-7 {
  margin-top: 7px !important;
}

.m-r-7 {
  margin-right: 7px !important;
}

.m-b-7 {
  margin-bottom: 7px !important;
}

.m-h-7 {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.m-v-7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.p-7 {
  padding: 7px !important;
}

.p-l-7 {
  padding-left: 7px !important;
}

.p-t-7 {
  padding-top: 7px !important;
}

.p-r-7 {
  padding-right: 7px !important;
}

.p-b-7 {
  padding-bottom: 7px !important;
}

.p-h-7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.p-v-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-l-8 {
  margin-left: 8px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.m-h-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.m-v-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-l-8 {
  padding-left: 8px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.p-r-8 {
  padding-right: 8px !important;
}

.p-b-8 {
  padding-bottom: 8px !important;
}

.p-h-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.p-v-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.m-9 {
  margin: 9px !important;
}

.m-l-9 {
  margin-left: 9px !important;
}

.m-t-9 {
  margin-top: 9px !important;
}

.m-r-9 {
  margin-right: 9px !important;
}

.m-b-9 {
  margin-bottom: 9px !important;
}

.m-h-9 {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.m-v-9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.p-9 {
  padding: 9px !important;
}

.p-l-9 {
  padding-left: 9px !important;
}

.p-t-9 {
  padding-top: 9px !important;
}

.p-r-9 {
  padding-right: 9px !important;
}

.p-b-9 {
  padding-bottom: 9px !important;
}

.p-h-9 {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.p-v-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-h-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.m-v-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-h-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p-v-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.m-11 {
  margin: 11px !important;
}

.m-l-11 {
  margin-left: 11px !important;
}

.m-t-11 {
  margin-top: 11px !important;
}

.m-r-11 {
  margin-right: 11px !important;
}

.m-b-11 {
  margin-bottom: 11px !important;
}

.m-h-11 {
  margin-left: 11px !important;
  margin-right: 11px !important;
}

.m-v-11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

.p-11 {
  padding: 11px !important;
}

.p-l-11 {
  padding-left: 11px !important;
}

.p-t-11 {
  padding-top: 11px !important;
}

.p-r-11 {
  padding-right: 11px !important;
}

.p-b-11 {
  padding-bottom: 11px !important;
}

.p-h-11 {
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.p-v-11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-l-12 {
  margin-left: 12px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-r-12 {
  margin-right: 12px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.m-h-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.m-v-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-l-12 {
  padding-left: 12px !important;
}

.p-t-12 {
  padding-top: 12px !important;
}

.p-r-12 {
  padding-right: 12px !important;
}

.p-b-12 {
  padding-bottom: 12px !important;
}

.p-h-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.p-v-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.m-13 {
  margin: 13px !important;
}

.m-l-13 {
  margin-left: 13px !important;
}

.m-t-13 {
  margin-top: 13px !important;
}

.m-r-13 {
  margin-right: 13px !important;
}

.m-b-13 {
  margin-bottom: 13px !important;
}

.m-h-13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.m-v-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.p-13 {
  padding: 13px !important;
}

.p-l-13 {
  padding-left: 13px !important;
}

.p-t-13 {
  padding-top: 13px !important;
}

.p-r-13 {
  padding-right: 13px !important;
}

.p-b-13 {
  padding-bottom: 13px !important;
}

.p-h-13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.p-v-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.m-14 {
  margin: 14px !important;
}

.m-l-14 {
  margin-left: 14px !important;
}

.m-t-14 {
  margin-top: 14px !important;
}

.m-r-14 {
  margin-right: 14px !important;
}

.m-b-14 {
  margin-bottom: 14px !important;
}

.m-h-14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.m-v-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.p-14 {
  padding: 14px !important;
}

.p-l-14 {
  padding-left: 14px !important;
}

.p-t-14 {
  padding-top: 14px !important;
}

.p-r-14 {
  padding-right: 14px !important;
}

.p-b-14 {
  padding-bottom: 14px !important;
}

.p-h-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.p-v-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-h-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.m-v-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-h-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p-v-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-l-16 {
  margin-left: 16px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.m-r-16 {
  margin-right: 16px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.m-h-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.m-v-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-l-16 {
  padding-left: 16px !important;
}

.p-t-16 {
  padding-top: 16px !important;
}

.p-r-16 {
  padding-right: 16px !important;
}

.p-b-16 {
  padding-bottom: 16px !important;
}

.p-h-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.p-v-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.m-17 {
  margin: 17px !important;
}

.m-l-17 {
  margin-left: 17px !important;
}

.m-t-17 {
  margin-top: 17px !important;
}

.m-r-17 {
  margin-right: 17px !important;
}

.m-b-17 {
  margin-bottom: 17px !important;
}

.m-h-17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.m-v-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.p-17 {
  padding: 17px !important;
}

.p-l-17 {
  padding-left: 17px !important;
}

.p-t-17 {
  padding-top: 17px !important;
}

.p-r-17 {
  padding-right: 17px !important;
}

.p-b-17 {
  padding-bottom: 17px !important;
}

.p-h-17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.p-v-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.m-18 {
  margin: 18px !important;
}

.m-l-18 {
  margin-left: 18px !important;
}

.m-t-18 {
  margin-top: 18px !important;
}

.m-r-18 {
  margin-right: 18px !important;
}

.m-b-18 {
  margin-bottom: 18px !important;
}

.m-h-18 {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.m-v-18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.p-18 {
  padding: 18px !important;
}

.p-l-18 {
  padding-left: 18px !important;
}

.p-t-18 {
  padding-top: 18px !important;
}

.p-r-18 {
  padding-right: 18px !important;
}

.p-b-18 {
  padding-bottom: 18px !important;
}

.p-h-18 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.p-v-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.m-19 {
  margin: 19px !important;
}

.m-l-19 {
  margin-left: 19px !important;
}

.m-t-19 {
  margin-top: 19px !important;
}

.m-r-19 {
  margin-right: 19px !important;
}

.m-b-19 {
  margin-bottom: 19px !important;
}

.m-h-19 {
  margin-left: 19px !important;
  margin-right: 19px !important;
}

.m-v-19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important;
}

.p-19 {
  padding: 19px !important;
}

.p-l-19 {
  padding-left: 19px !important;
}

.p-t-19 {
  padding-top: 19px !important;
}

.p-r-19 {
  padding-right: 19px !important;
}

.p-b-19 {
  padding-bottom: 19px !important;
}

.p-h-19 {
  padding-left: 19px !important;
  padding-right: 19px !important;
}

.p-v-19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-h-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.m-v-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-h-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-v-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.m-21 {
  margin: 21px !important;
}

.m-l-21 {
  margin-left: 21px !important;
}

.m-t-21 {
  margin-top: 21px !important;
}

.m-r-21 {
  margin-right: 21px !important;
}

.m-b-21 {
  margin-bottom: 21px !important;
}

.m-h-21 {
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.m-v-21 {
  margin-top: 21px !important;
  margin-bottom: 21px !important;
}

.p-21 {
  padding: 21px !important;
}

.p-l-21 {
  padding-left: 21px !important;
}

.p-t-21 {
  padding-top: 21px !important;
}

.p-r-21 {
  padding-right: 21px !important;
}

.p-b-21 {
  padding-bottom: 21px !important;
}

.p-h-21 {
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.p-v-21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important;
}

.m-22 {
  margin: 22px !important;
}

.m-l-22 {
  margin-left: 22px !important;
}

.m-t-22 {
  margin-top: 22px !important;
}

.m-r-22 {
  margin-right: 22px !important;
}

.m-b-22 {
  margin-bottom: 22px !important;
}

.m-h-22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.m-v-22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.p-22 {
  padding: 22px !important;
}

.p-l-22 {
  padding-left: 22px !important;
}

.p-t-22 {
  padding-top: 22px !important;
}

.p-r-22 {
  padding-right: 22px !important;
}

.p-b-22 {
  padding-bottom: 22px !important;
}

.p-h-22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.p-v-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.m-23 {
  margin: 23px !important;
}

.m-l-23 {
  margin-left: 23px !important;
}

.m-t-23 {
  margin-top: 23px !important;
}

.m-r-23 {
  margin-right: 23px !important;
}

.m-b-23 {
  margin-bottom: 23px !important;
}

.m-h-23 {
  margin-left: 23px !important;
  margin-right: 23px !important;
}

.m-v-23 {
  margin-top: 23px !important;
  margin-bottom: 23px !important;
}

.p-23 {
  padding: 23px !important;
}

.p-l-23 {
  padding-left: 23px !important;
}

.p-t-23 {
  padding-top: 23px !important;
}

.p-r-23 {
  padding-right: 23px !important;
}

.p-b-23 {
  padding-bottom: 23px !important;
}

.p-h-23 {
  padding-left: 23px !important;
  padding-right: 23px !important;
}

.p-v-23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-l-24 {
  margin-left: 24px !important;
}

.m-t-24 {
  margin-top: 24px !important;
}

.m-r-24 {
  margin-right: 24px !important;
}

.m-b-24 {
  margin-bottom: 24px !important;
}

.m-h-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.m-v-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-l-24 {
  padding-left: 24px !important;
}

.p-t-24 {
  padding-top: 24px !important;
}

.p-r-24 {
  padding-right: 24px !important;
}

.p-b-24 {
  padding-bottom: 24px !important;
}

.p-h-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.p-v-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-h-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.m-v-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-h-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.p-v-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.m-26 {
  margin: 26px !important;
}

.m-l-26 {
  margin-left: 26px !important;
}

.m-t-26 {
  margin-top: 26px !important;
}

.m-r-26 {
  margin-right: 26px !important;
}

.m-b-26 {
  margin-bottom: 26px !important;
}

.m-h-26 {
  margin-left: 26px !important;
  margin-right: 26px !important;
}

.m-v-26 {
  margin-top: 26px !important;
  margin-bottom: 26px !important;
}

.p-26 {
  padding: 26px !important;
}

.p-l-26 {
  padding-left: 26px !important;
}

.p-t-26 {
  padding-top: 26px !important;
}

.p-r-26 {
  padding-right: 26px !important;
}

.p-b-26 {
  padding-bottom: 26px !important;
}

.p-h-26 {
  padding-left: 26px !important;
  padding-right: 26px !important;
}

.p-v-26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}

.m-27 {
  margin: 27px !important;
}

.m-l-27 {
  margin-left: 27px !important;
}

.m-t-27 {
  margin-top: 27px !important;
}

.m-r-27 {
  margin-right: 27px !important;
}

.m-b-27 {
  margin-bottom: 27px !important;
}

.m-h-27 {
  margin-left: 27px !important;
  margin-right: 27px !important;
}

.m-v-27 {
  margin-top: 27px !important;
  margin-bottom: 27px !important;
}

.p-27 {
  padding: 27px !important;
}

.p-l-27 {
  padding-left: 27px !important;
}

.p-t-27 {
  padding-top: 27px !important;
}

.p-r-27 {
  padding-right: 27px !important;
}

.p-b-27 {
  padding-bottom: 27px !important;
}

.p-h-27 {
  padding-left: 27px !important;
  padding-right: 27px !important;
}

.p-v-27 {
  padding-top: 27px !important;
  padding-bottom: 27px !important;
}

.m-28 {
  margin: 28px !important;
}

.m-l-28 {
  margin-left: 28px !important;
}

.m-t-28 {
  margin-top: 28px !important;
}

.m-r-28 {
  margin-right: 28px !important;
}

.m-b-28 {
  margin-bottom: 28px !important;
}

.m-h-28 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.m-v-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.p-28 {
  padding: 28px !important;
}

.p-l-28 {
  padding-left: 28px !important;
}

.p-t-28 {
  padding-top: 28px !important;
}

.p-r-28 {
  padding-right: 28px !important;
}

.p-b-28 {
  padding-bottom: 28px !important;
}

.p-h-28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.p-v-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.m-29 {
  margin: 29px !important;
}

.m-l-29 {
  margin-left: 29px !important;
}

.m-t-29 {
  margin-top: 29px !important;
}

.m-r-29 {
  margin-right: 29px !important;
}

.m-b-29 {
  margin-bottom: 29px !important;
}

.m-h-29 {
  margin-left: 29px !important;
  margin-right: 29px !important;
}

.m-v-29 {
  margin-top: 29px !important;
  margin-bottom: 29px !important;
}

.p-29 {
  padding: 29px !important;
}

.p-l-29 {
  padding-left: 29px !important;
}

.p-t-29 {
  padding-top: 29px !important;
}

.p-r-29 {
  padding-right: 29px !important;
}

.p-b-29 {
  padding-bottom: 29px !important;
}

.p-h-29 {
  padding-left: 29px !important;
  padding-right: 29px !important;
}

.p-v-29 {
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-h-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.m-v-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-h-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.p-v-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.m-31 {
  margin: 31px !important;
}

.m-l-31 {
  margin-left: 31px !important;
}

.m-t-31 {
  margin-top: 31px !important;
}

.m-r-31 {
  margin-right: 31px !important;
}

.m-b-31 {
  margin-bottom: 31px !important;
}

.m-h-31 {
  margin-left: 31px !important;
  margin-right: 31px !important;
}

.m-v-31 {
  margin-top: 31px !important;
  margin-bottom: 31px !important;
}

.p-31 {
  padding: 31px !important;
}

.p-l-31 {
  padding-left: 31px !important;
}

.p-t-31 {
  padding-top: 31px !important;
}

.p-r-31 {
  padding-right: 31px !important;
}

.p-b-31 {
  padding-bottom: 31px !important;
}

.p-h-31 {
  padding-left: 31px !important;
  padding-right: 31px !important;
}

.p-v-31 {
  padding-top: 31px !important;
  padding-bottom: 31px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-l-32 {
  margin-left: 32px !important;
}

.m-t-32 {
  margin-top: 32px !important;
}

.m-r-32 {
  margin-right: 32px !important;
}

.m-b-32 {
  margin-bottom: 32px !important;
}

.m-h-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.m-v-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-l-32 {
  padding-left: 32px !important;
}

.p-t-32 {
  padding-top: 32px !important;
}

.p-r-32 {
  padding-right: 32px !important;
}

.p-b-32 {
  padding-bottom: 32px !important;
}

.p-h-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.p-v-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.m-33 {
  margin: 33px !important;
}

.m-l-33 {
  margin-left: 33px !important;
}

.m-t-33 {
  margin-top: 33px !important;
}

.m-r-33 {
  margin-right: 33px !important;
}

.m-b-33 {
  margin-bottom: 33px !important;
}

.m-h-33 {
  margin-left: 33px !important;
  margin-right: 33px !important;
}

.m-v-33 {
  margin-top: 33px !important;
  margin-bottom: 33px !important;
}

.p-33 {
  padding: 33px !important;
}

.p-l-33 {
  padding-left: 33px !important;
}

.p-t-33 {
  padding-top: 33px !important;
}

.p-r-33 {
  padding-right: 33px !important;
}

.p-b-33 {
  padding-bottom: 33px !important;
}

.p-h-33 {
  padding-left: 33px !important;
  padding-right: 33px !important;
}

.p-v-33 {
  padding-top: 33px !important;
  padding-bottom: 33px !important;
}

.m-34 {
  margin: 34px !important;
}

.m-l-34 {
  margin-left: 34px !important;
}

.m-t-34 {
  margin-top: 34px !important;
}

.m-r-34 {
  margin-right: 34px !important;
}

.m-b-34 {
  margin-bottom: 34px !important;
}

.m-h-34 {
  margin-left: 34px !important;
  margin-right: 34px !important;
}

.m-v-34 {
  margin-top: 34px !important;
  margin-bottom: 34px !important;
}

.p-34 {
  padding: 34px !important;
}

.p-l-34 {
  padding-left: 34px !important;
}

.p-t-34 {
  padding-top: 34px !important;
}

.p-r-34 {
  padding-right: 34px !important;
}

.p-b-34 {
  padding-bottom: 34px !important;
}

.p-h-34 {
  padding-left: 34px !important;
  padding-right: 34px !important;
}

.p-v-34 {
  padding-top: 34px !important;
  padding-bottom: 34px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-h-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.m-v-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-h-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.p-v-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.m-36 {
  margin: 36px !important;
}

.m-l-36 {
  margin-left: 36px !important;
}

.m-t-36 {
  margin-top: 36px !important;
}

.m-r-36 {
  margin-right: 36px !important;
}

.m-b-36 {
  margin-bottom: 36px !important;
}

.m-h-36 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.m-v-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.p-36 {
  padding: 36px !important;
}

.p-l-36 {
  padding-left: 36px !important;
}

.p-t-36 {
  padding-top: 36px !important;
}

.p-r-36 {
  padding-right: 36px !important;
}

.p-b-36 {
  padding-bottom: 36px !important;
}

.p-h-36 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.p-v-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.m-37 {
  margin: 37px !important;
}

.m-l-37 {
  margin-left: 37px !important;
}

.m-t-37 {
  margin-top: 37px !important;
}

.m-r-37 {
  margin-right: 37px !important;
}

.m-b-37 {
  margin-bottom: 37px !important;
}

.m-h-37 {
  margin-left: 37px !important;
  margin-right: 37px !important;
}

.m-v-37 {
  margin-top: 37px !important;
  margin-bottom: 37px !important;
}

.p-37 {
  padding: 37px !important;
}

.p-l-37 {
  padding-left: 37px !important;
}

.p-t-37 {
  padding-top: 37px !important;
}

.p-r-37 {
  padding-right: 37px !important;
}

.p-b-37 {
  padding-bottom: 37px !important;
}

.p-h-37 {
  padding-left: 37px !important;
  padding-right: 37px !important;
}

.p-v-37 {
  padding-top: 37px !important;
  padding-bottom: 37px !important;
}

.m-38 {
  margin: 38px !important;
}

.m-l-38 {
  margin-left: 38px !important;
}

.m-t-38 {
  margin-top: 38px !important;
}

.m-r-38 {
  margin-right: 38px !important;
}

.m-b-38 {
  margin-bottom: 38px !important;
}

.m-h-38 {
  margin-left: 38px !important;
  margin-right: 38px !important;
}

.m-v-38 {
  margin-top: 38px !important;
  margin-bottom: 38px !important;
}

.p-38 {
  padding: 38px !important;
}

.p-l-38 {
  padding-left: 38px !important;
}

.p-t-38 {
  padding-top: 38px !important;
}

.p-r-38 {
  padding-right: 38px !important;
}

.p-b-38 {
  padding-bottom: 38px !important;
}

.p-h-38 {
  padding-left: 38px !important;
  padding-right: 38px !important;
}

.p-v-38 {
  padding-top: 38px !important;
  padding-bottom: 38px !important;
}

.m-39 {
  margin: 39px !important;
}

.m-l-39 {
  margin-left: 39px !important;
}

.m-t-39 {
  margin-top: 39px !important;
}

.m-r-39 {
  margin-right: 39px !important;
}

.m-b-39 {
  margin-bottom: 39px !important;
}

.m-h-39 {
  margin-left: 39px !important;
  margin-right: 39px !important;
}

.m-v-39 {
  margin-top: 39px !important;
  margin-bottom: 39px !important;
}

.p-39 {
  padding: 39px !important;
}

.p-l-39 {
  padding-left: 39px !important;
}

.p-t-39 {
  padding-top: 39px !important;
}

.p-r-39 {
  padding-right: 39px !important;
}

.p-b-39 {
  padding-bottom: 39px !important;
}

.p-h-39 {
  padding-left: 39px !important;
  padding-right: 39px !important;
}

.p-v-39 {
  padding-top: 39px !important;
  padding-bottom: 39px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-h-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.m-v-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-h-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.p-v-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.m-41 {
  margin: 41px !important;
}

.m-l-41 {
  margin-left: 41px !important;
}

.m-t-41 {
  margin-top: 41px !important;
}

.m-r-41 {
  margin-right: 41px !important;
}

.m-b-41 {
  margin-bottom: 41px !important;
}

.m-h-41 {
  margin-left: 41px !important;
  margin-right: 41px !important;
}

.m-v-41 {
  margin-top: 41px !important;
  margin-bottom: 41px !important;
}

.p-41 {
  padding: 41px !important;
}

.p-l-41 {
  padding-left: 41px !important;
}

.p-t-41 {
  padding-top: 41px !important;
}

.p-r-41 {
  padding-right: 41px !important;
}

.p-b-41 {
  padding-bottom: 41px !important;
}

.p-h-41 {
  padding-left: 41px !important;
  padding-right: 41px !important;
}

.p-v-41 {
  padding-top: 41px !important;
  padding-bottom: 41px !important;
}

.m-42 {
  margin: 42px !important;
}

.m-l-42 {
  margin-left: 42px !important;
}

.m-t-42 {
  margin-top: 42px !important;
}

.m-r-42 {
  margin-right: 42px !important;
}

.m-b-42 {
  margin-bottom: 42px !important;
}

.m-h-42 {
  margin-left: 42px !important;
  margin-right: 42px !important;
}

.m-v-42 {
  margin-top: 42px !important;
  margin-bottom: 42px !important;
}

.p-42 {
  padding: 42px !important;
}

.p-l-42 {
  padding-left: 42px !important;
}

.p-t-42 {
  padding-top: 42px !important;
}

.p-r-42 {
  padding-right: 42px !important;
}

.p-b-42 {
  padding-bottom: 42px !important;
}

.p-h-42 {
  padding-left: 42px !important;
  padding-right: 42px !important;
}

.p-v-42 {
  padding-top: 42px !important;
  padding-bottom: 42px !important;
}

.m-43 {
  margin: 43px !important;
}

.m-l-43 {
  margin-left: 43px !important;
}

.m-t-43 {
  margin-top: 43px !important;
}

.m-r-43 {
  margin-right: 43px !important;
}

.m-b-43 {
  margin-bottom: 43px !important;
}

.m-h-43 {
  margin-left: 43px !important;
  margin-right: 43px !important;
}

.m-v-43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important;
}

.p-43 {
  padding: 43px !important;
}

.p-l-43 {
  padding-left: 43px !important;
}

.p-t-43 {
  padding-top: 43px !important;
}

.p-r-43 {
  padding-right: 43px !important;
}

.p-b-43 {
  padding-bottom: 43px !important;
}

.p-h-43 {
  padding-left: 43px !important;
  padding-right: 43px !important;
}

.p-v-43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important;
}

.m-44 {
  margin: 44px !important;
}

.m-l-44 {
  margin-left: 44px !important;
}

.m-t-44 {
  margin-top: 44px !important;
}

.m-r-44 {
  margin-right: 44px !important;
}

.m-b-44 {
  margin-bottom: 44px !important;
}

.m-h-44 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}

.m-v-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.p-44 {
  padding: 44px !important;
}

.p-l-44 {
  padding-left: 44px !important;
}

.p-t-44 {
  padding-top: 44px !important;
}

.p-r-44 {
  padding-right: 44px !important;
}

.p-b-44 {
  padding-bottom: 44px !important;
}

.p-h-44 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.p-v-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-h-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.m-v-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-h-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.p-v-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.m-46 {
  margin: 46px !important;
}

.m-l-46 {
  margin-left: 46px !important;
}

.m-t-46 {
  margin-top: 46px !important;
}

.m-r-46 {
  margin-right: 46px !important;
}

.m-b-46 {
  margin-bottom: 46px !important;
}

.m-h-46 {
  margin-left: 46px !important;
  margin-right: 46px !important;
}

.m-v-46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important;
}

.p-46 {
  padding: 46px !important;
}

.p-l-46 {
  padding-left: 46px !important;
}

.p-t-46 {
  padding-top: 46px !important;
}

.p-r-46 {
  padding-right: 46px !important;
}

.p-b-46 {
  padding-bottom: 46px !important;
}

.p-h-46 {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

.p-v-46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important;
}

.m-47 {
  margin: 47px !important;
}

.m-l-47 {
  margin-left: 47px !important;
}

.m-t-47 {
  margin-top: 47px !important;
}

.m-r-47 {
  margin-right: 47px !important;
}

.m-b-47 {
  margin-bottom: 47px !important;
}

.m-h-47 {
  margin-left: 47px !important;
  margin-right: 47px !important;
}

.m-v-47 {
  margin-top: 47px !important;
  margin-bottom: 47px !important;
}

.p-47 {
  padding: 47px !important;
}

.p-l-47 {
  padding-left: 47px !important;
}

.p-t-47 {
  padding-top: 47px !important;
}

.p-r-47 {
  padding-right: 47px !important;
}

.p-b-47 {
  padding-bottom: 47px !important;
}

.p-h-47 {
  padding-left: 47px !important;
  padding-right: 47px !important;
}

.p-v-47 {
  padding-top: 47px !important;
  padding-bottom: 47px !important;
}

.m-48 {
  margin: 48px !important;
}

.m-l-48 {
  margin-left: 48px !important;
}

.m-t-48 {
  margin-top: 48px !important;
}

.m-r-48 {
  margin-right: 48px !important;
}

.m-b-48 {
  margin-bottom: 48px !important;
}

.m-h-48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.m-v-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.p-48 {
  padding: 48px !important;
}

.p-l-48 {
  padding-left: 48px !important;
}

.p-t-48 {
  padding-top: 48px !important;
}

.p-r-48 {
  padding-right: 48px !important;
}

.p-b-48 {
  padding-bottom: 48px !important;
}

.p-h-48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.p-v-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.m-49 {
  margin: 49px !important;
}

.m-l-49 {
  margin-left: 49px !important;
}

.m-t-49 {
  margin-top: 49px !important;
}

.m-r-49 {
  margin-right: 49px !important;
}

.m-b-49 {
  margin-bottom: 49px !important;
}

.m-h-49 {
  margin-left: 49px !important;
  margin-right: 49px !important;
}

.m-v-49 {
  margin-top: 49px !important;
  margin-bottom: 49px !important;
}

.p-49 {
  padding: 49px !important;
}

.p-l-49 {
  padding-left: 49px !important;
}

.p-t-49 {
  padding-top: 49px !important;
}

.p-r-49 {
  padding-right: 49px !important;
}

.p-b-49 {
  padding-bottom: 49px !important;
}

.p-h-49 {
  padding-left: 49px !important;
  padding-right: 49px !important;
}

.p-v-49 {
  padding-top: 49px !important;
  padding-bottom: 49px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-h-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.m-v-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.p-h-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.p-v-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.m-51 {
  margin: 51px !important;
}

.m-l-51 {
  margin-left: 51px !important;
}

.m-t-51 {
  margin-top: 51px !important;
}

.m-r-51 {
  margin-right: 51px !important;
}

.m-b-51 {
  margin-bottom: 51px !important;
}

.m-h-51 {
  margin-left: 51px !important;
  margin-right: 51px !important;
}

.m-v-51 {
  margin-top: 51px !important;
  margin-bottom: 51px !important;
}

.p-51 {
  padding: 51px !important;
}

.p-l-51 {
  padding-left: 51px !important;
}

.p-t-51 {
  padding-top: 51px !important;
}

.p-r-51 {
  padding-right: 51px !important;
}

.p-b-51 {
  padding-bottom: 51px !important;
}

.p-h-51 {
  padding-left: 51px !important;
  padding-right: 51px !important;
}

.p-v-51 {
  padding-top: 51px !important;
  padding-bottom: 51px !important;
}

.m-52 {
  margin: 52px !important;
}

.m-l-52 {
  margin-left: 52px !important;
}

.m-t-52 {
  margin-top: 52px !important;
}

.m-r-52 {
  margin-right: 52px !important;
}

.m-b-52 {
  margin-bottom: 52px !important;
}

.m-h-52 {
  margin-left: 52px !important;
  margin-right: 52px !important;
}

.m-v-52 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

.p-52 {
  padding: 52px !important;
}

.p-l-52 {
  padding-left: 52px !important;
}

.p-t-52 {
  padding-top: 52px !important;
}

.p-r-52 {
  padding-right: 52px !important;
}

.p-b-52 {
  padding-bottom: 52px !important;
}

.p-h-52 {
  padding-left: 52px !important;
  padding-right: 52px !important;
}

.p-v-52 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.m-53 {
  margin: 53px !important;
}

.m-l-53 {
  margin-left: 53px !important;
}

.m-t-53 {
  margin-top: 53px !important;
}

.m-r-53 {
  margin-right: 53px !important;
}

.m-b-53 {
  margin-bottom: 53px !important;
}

.m-h-53 {
  margin-left: 53px !important;
  margin-right: 53px !important;
}

.m-v-53 {
  margin-top: 53px !important;
  margin-bottom: 53px !important;
}

.p-53 {
  padding: 53px !important;
}

.p-l-53 {
  padding-left: 53px !important;
}

.p-t-53 {
  padding-top: 53px !important;
}

.p-r-53 {
  padding-right: 53px !important;
}

.p-b-53 {
  padding-bottom: 53px !important;
}

.p-h-53 {
  padding-left: 53px !important;
  padding-right: 53px !important;
}

.p-v-53 {
  padding-top: 53px !important;
  padding-bottom: 53px !important;
}

.m-54 {
  margin: 54px !important;
}

.m-l-54 {
  margin-left: 54px !important;
}

.m-t-54 {
  margin-top: 54px !important;
}

.m-r-54 {
  margin-right: 54px !important;
}

.m-b-54 {
  margin-bottom: 54px !important;
}

.m-h-54 {
  margin-left: 54px !important;
  margin-right: 54px !important;
}

.m-v-54 {
  margin-top: 54px !important;
  margin-bottom: 54px !important;
}

.p-54 {
  padding: 54px !important;
}

.p-l-54 {
  padding-left: 54px !important;
}

.p-t-54 {
  padding-top: 54px !important;
}

.p-r-54 {
  padding-right: 54px !important;
}

.p-b-54 {
  padding-bottom: 54px !important;
}

.p-h-54 {
  padding-left: 54px !important;
  padding-right: 54px !important;
}

.p-v-54 {
  padding-top: 54px !important;
  padding-bottom: 54px !important;
}

.m-55 {
  margin: 55px !important;
}

.m-l-55 {
  margin-left: 55px !important;
}

.m-t-55 {
  margin-top: 55px !important;
}

.m-r-55 {
  margin-right: 55px !important;
}

.m-b-55 {
  margin-bottom: 55px !important;
}

.m-h-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.m-v-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.p-55 {
  padding: 55px !important;
}

.p-l-55 {
  padding-left: 55px !important;
}

.p-t-55 {
  padding-top: 55px !important;
}

.p-r-55 {
  padding-right: 55px !important;
}

.p-b-55 {
  padding-bottom: 55px !important;
}

.p-h-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.p-v-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.m-56 {
  margin: 56px !important;
}

.m-l-56 {
  margin-left: 56px !important;
}

.m-t-56 {
  margin-top: 56px !important;
}

.m-r-56 {
  margin-right: 56px !important;
}

.m-b-56 {
  margin-bottom: 56px !important;
}

.m-h-56 {
  margin-left: 56px !important;
  margin-right: 56px !important;
}

.m-v-56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.p-56 {
  padding: 56px !important;
}

.p-l-56 {
  padding-left: 56px !important;
}

.p-t-56 {
  padding-top: 56px !important;
}

.p-r-56 {
  padding-right: 56px !important;
}

.p-b-56 {
  padding-bottom: 56px !important;
}

.p-h-56 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.p-v-56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.m-57 {
  margin: 57px !important;
}

.m-l-57 {
  margin-left: 57px !important;
}

.m-t-57 {
  margin-top: 57px !important;
}

.m-r-57 {
  margin-right: 57px !important;
}

.m-b-57 {
  margin-bottom: 57px !important;
}

.m-h-57 {
  margin-left: 57px !important;
  margin-right: 57px !important;
}

.m-v-57 {
  margin-top: 57px !important;
  margin-bottom: 57px !important;
}

.p-57 {
  padding: 57px !important;
}

.p-l-57 {
  padding-left: 57px !important;
}

.p-t-57 {
  padding-top: 57px !important;
}

.p-r-57 {
  padding-right: 57px !important;
}

.p-b-57 {
  padding-bottom: 57px !important;
}

.p-h-57 {
  padding-left: 57px !important;
  padding-right: 57px !important;
}

.p-v-57 {
  padding-top: 57px !important;
  padding-bottom: 57px !important;
}

.m-58 {
  margin: 58px !important;
}

.m-l-58 {
  margin-left: 58px !important;
}

.m-t-58 {
  margin-top: 58px !important;
}

.m-r-58 {
  margin-right: 58px !important;
}

.m-b-58 {
  margin-bottom: 58px !important;
}

.m-h-58 {
  margin-left: 58px !important;
  margin-right: 58px !important;
}

.m-v-58 {
  margin-top: 58px !important;
  margin-bottom: 58px !important;
}

.p-58 {
  padding: 58px !important;
}

.p-l-58 {
  padding-left: 58px !important;
}

.p-t-58 {
  padding-top: 58px !important;
}

.p-r-58 {
  padding-right: 58px !important;
}

.p-b-58 {
  padding-bottom: 58px !important;
}

.p-h-58 {
  padding-left: 58px !important;
  padding-right: 58px !important;
}

.p-v-58 {
  padding-top: 58px !important;
  padding-bottom: 58px !important;
}

.m-59 {
  margin: 59px !important;
}

.m-l-59 {
  margin-left: 59px !important;
}

.m-t-59 {
  margin-top: 59px !important;
}

.m-r-59 {
  margin-right: 59px !important;
}

.m-b-59 {
  margin-bottom: 59px !important;
}

.m-h-59 {
  margin-left: 59px !important;
  margin-right: 59px !important;
}

.m-v-59 {
  margin-top: 59px !important;
  margin-bottom: 59px !important;
}

.p-59 {
  padding: 59px !important;
}

.p-l-59 {
  padding-left: 59px !important;
}

.p-t-59 {
  padding-top: 59px !important;
}

.p-r-59 {
  padding-right: 59px !important;
}

.p-b-59 {
  padding-bottom: 59px !important;
}

.p-h-59 {
  padding-left: 59px !important;
  padding-right: 59px !important;
}

.p-v-59 {
  padding-top: 59px !important;
  padding-bottom: 59px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-l-60 {
  margin-left: 60px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-r-60 {
  margin-right: 60px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.m-h-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.m-v-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-r-60 {
  padding-right: 60px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.p-h-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.p-v-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.m-61 {
  margin: 61px !important;
}

.m-l-61 {
  margin-left: 61px !important;
}

.m-t-61 {
  margin-top: 61px !important;
}

.m-r-61 {
  margin-right: 61px !important;
}

.m-b-61 {
  margin-bottom: 61px !important;
}

.m-h-61 {
  margin-left: 61px !important;
  margin-right: 61px !important;
}

.m-v-61 {
  margin-top: 61px !important;
  margin-bottom: 61px !important;
}

.p-61 {
  padding: 61px !important;
}

.p-l-61 {
  padding-left: 61px !important;
}

.p-t-61 {
  padding-top: 61px !important;
}

.p-r-61 {
  padding-right: 61px !important;
}

.p-b-61 {
  padding-bottom: 61px !important;
}

.p-h-61 {
  padding-left: 61px !important;
  padding-right: 61px !important;
}

.p-v-61 {
  padding-top: 61px !important;
  padding-bottom: 61px !important;
}

.m-62 {
  margin: 62px !important;
}

.m-l-62 {
  margin-left: 62px !important;
}

.m-t-62 {
  margin-top: 62px !important;
}

.m-r-62 {
  margin-right: 62px !important;
}

.m-b-62 {
  margin-bottom: 62px !important;
}

.m-h-62 {
  margin-left: 62px !important;
  margin-right: 62px !important;
}

.m-v-62 {
  margin-top: 62px !important;
  margin-bottom: 62px !important;
}

.p-62 {
  padding: 62px !important;
}

.p-l-62 {
  padding-left: 62px !important;
}

.p-t-62 {
  padding-top: 62px !important;
}

.p-r-62 {
  padding-right: 62px !important;
}

.p-b-62 {
  padding-bottom: 62px !important;
}

.p-h-62 {
  padding-left: 62px !important;
  padding-right: 62px !important;
}

.p-v-62 {
  padding-top: 62px !important;
  padding-bottom: 62px !important;
}

.m-63 {
  margin: 63px !important;
}

.m-l-63 {
  margin-left: 63px !important;
}

.m-t-63 {
  margin-top: 63px !important;
}

.m-r-63 {
  margin-right: 63px !important;
}

.m-b-63 {
  margin-bottom: 63px !important;
}

.m-h-63 {
  margin-left: 63px !important;
  margin-right: 63px !important;
}

.m-v-63 {
  margin-top: 63px !important;
  margin-bottom: 63px !important;
}

.p-63 {
  padding: 63px !important;
}

.p-l-63 {
  padding-left: 63px !important;
}

.p-t-63 {
  padding-top: 63px !important;
}

.p-r-63 {
  padding-right: 63px !important;
}

.p-b-63 {
  padding-bottom: 63px !important;
}

.p-h-63 {
  padding-left: 63px !important;
  padding-right: 63px !important;
}

.p-v-63 {
  padding-top: 63px !important;
  padding-bottom: 63px !important;
}

.m-64 {
  margin: 64px !important;
}

.m-l-64 {
  margin-left: 64px !important;
}

.m-t-64 {
  margin-top: 64px !important;
}

.m-r-64 {
  margin-right: 64px !important;
}

.m-b-64 {
  margin-bottom: 64px !important;
}

.m-h-64 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.m-v-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.p-64 {
  padding: 64px !important;
}

.p-l-64 {
  padding-left: 64px !important;
}

.p-t-64 {
  padding-top: 64px !important;
}

.p-r-64 {
  padding-right: 64px !important;
}

.p-b-64 {
  padding-bottom: 64px !important;
}

.p-h-64 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.p-v-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.m-65 {
  margin: 65px !important;
}

.m-l-65 {
  margin-left: 65px !important;
}

.m-t-65 {
  margin-top: 65px !important;
}

.m-r-65 {
  margin-right: 65px !important;
}

.m-b-65 {
  margin-bottom: 65px !important;
}

.m-h-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.m-v-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.p-65 {
  padding: 65px !important;
}

.p-l-65 {
  padding-left: 65px !important;
}

.p-t-65 {
  padding-top: 65px !important;
}

.p-r-65 {
  padding-right: 65px !important;
}

.p-b-65 {
  padding-bottom: 65px !important;
}

.p-h-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.p-v-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.m-66 {
  margin: 66px !important;
}

.m-l-66 {
  margin-left: 66px !important;
}

.m-t-66 {
  margin-top: 66px !important;
}

.m-r-66 {
  margin-right: 66px !important;
}

.m-b-66 {
  margin-bottom: 66px !important;
}

.m-h-66 {
  margin-left: 66px !important;
  margin-right: 66px !important;
}

.m-v-66 {
  margin-top: 66px !important;
  margin-bottom: 66px !important;
}

.p-66 {
  padding: 66px !important;
}

.p-l-66 {
  padding-left: 66px !important;
}

.p-t-66 {
  padding-top: 66px !important;
}

.p-r-66 {
  padding-right: 66px !important;
}

.p-b-66 {
  padding-bottom: 66px !important;
}

.p-h-66 {
  padding-left: 66px !important;
  padding-right: 66px !important;
}

.p-v-66 {
  padding-top: 66px !important;
  padding-bottom: 66px !important;
}

.m-67 {
  margin: 67px !important;
}

.m-l-67 {
  margin-left: 67px !important;
}

.m-t-67 {
  margin-top: 67px !important;
}

.m-r-67 {
  margin-right: 67px !important;
}

.m-b-67 {
  margin-bottom: 67px !important;
}

.m-h-67 {
  margin-left: 67px !important;
  margin-right: 67px !important;
}

.m-v-67 {
  margin-top: 67px !important;
  margin-bottom: 67px !important;
}

.p-67 {
  padding: 67px !important;
}

.p-l-67 {
  padding-left: 67px !important;
}

.p-t-67 {
  padding-top: 67px !important;
}

.p-r-67 {
  padding-right: 67px !important;
}

.p-b-67 {
  padding-bottom: 67px !important;
}

.p-h-67 {
  padding-left: 67px !important;
  padding-right: 67px !important;
}

.p-v-67 {
  padding-top: 67px !important;
  padding-bottom: 67px !important;
}

.m-68 {
  margin: 68px !important;
}

.m-l-68 {
  margin-left: 68px !important;
}

.m-t-68 {
  margin-top: 68px !important;
}

.m-r-68 {
  margin-right: 68px !important;
}

.m-b-68 {
  margin-bottom: 68px !important;
}

.m-h-68 {
  margin-left: 68px !important;
  margin-right: 68px !important;
}

.m-v-68 {
  margin-top: 68px !important;
  margin-bottom: 68px !important;
}

.p-68 {
  padding: 68px !important;
}

.p-l-68 {
  padding-left: 68px !important;
}

.p-t-68 {
  padding-top: 68px !important;
}

.p-r-68 {
  padding-right: 68px !important;
}

.p-b-68 {
  padding-bottom: 68px !important;
}

.p-h-68 {
  padding-left: 68px !important;
  padding-right: 68px !important;
}

.p-v-68 {
  padding-top: 68px !important;
  padding-bottom: 68px !important;
}

.m-69 {
  margin: 69px !important;
}

.m-l-69 {
  margin-left: 69px !important;
}

.m-t-69 {
  margin-top: 69px !important;
}

.m-r-69 {
  margin-right: 69px !important;
}

.m-b-69 {
  margin-bottom: 69px !important;
}

.m-h-69 {
  margin-left: 69px !important;
  margin-right: 69px !important;
}

.m-v-69 {
  margin-top: 69px !important;
  margin-bottom: 69px !important;
}

.p-69 {
  padding: 69px !important;
}

.p-l-69 {
  padding-left: 69px !important;
}

.p-t-69 {
  padding-top: 69px !important;
}

.p-r-69 {
  padding-right: 69px !important;
}

.p-b-69 {
  padding-bottom: 69px !important;
}

.p-h-69 {
  padding-left: 69px !important;
  padding-right: 69px !important;
}

.p-v-69 {
  padding-top: 69px !important;
  padding-bottom: 69px !important;
}

.m-70 {
  margin: 70px !important;
}

.m-l-70 {
  margin-left: 70px !important;
}

.m-t-70 {
  margin-top: 70px !important;
}

.m-r-70 {
  margin-right: 70px !important;
}

.m-b-70 {
  margin-bottom: 70px !important;
}

.m-h-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.m-v-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.p-70 {
  padding: 70px !important;
}

.p-l-70 {
  padding-left: 70px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.p-r-70 {
  padding-right: 70px !important;
}

.p-b-70 {
  padding-bottom: 70px !important;
}

.p-h-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.p-v-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.m-71 {
  margin: 71px !important;
}

.m-l-71 {
  margin-left: 71px !important;
}

.m-t-71 {
  margin-top: 71px !important;
}

.m-r-71 {
  margin-right: 71px !important;
}

.m-b-71 {
  margin-bottom: 71px !important;
}

.m-h-71 {
  margin-left: 71px !important;
  margin-right: 71px !important;
}

.m-v-71 {
  margin-top: 71px !important;
  margin-bottom: 71px !important;
}

.p-71 {
  padding: 71px !important;
}

.p-l-71 {
  padding-left: 71px !important;
}

.p-t-71 {
  padding-top: 71px !important;
}

.p-r-71 {
  padding-right: 71px !important;
}

.p-b-71 {
  padding-bottom: 71px !important;
}

.p-h-71 {
  padding-left: 71px !important;
  padding-right: 71px !important;
}

.p-v-71 {
  padding-top: 71px !important;
  padding-bottom: 71px !important;
}

.m-72 {
  margin: 72px !important;
}

.m-l-72 {
  margin-left: 72px !important;
}

.m-t-72 {
  margin-top: 72px !important;
}

.m-r-72 {
  margin-right: 72px !important;
}

.m-b-72 {
  margin-bottom: 72px !important;
}

.m-h-72 {
  margin-left: 72px !important;
  margin-right: 72px !important;
}

.m-v-72 {
  margin-top: 72px !important;
  margin-bottom: 72px !important;
}

.p-72 {
  padding: 72px !important;
}

.p-l-72 {
  padding-left: 72px !important;
}

.p-t-72 {
  padding-top: 72px !important;
}

.p-r-72 {
  padding-right: 72px !important;
}

.p-b-72 {
  padding-bottom: 72px !important;
}

.p-h-72 {
  padding-left: 72px !important;
  padding-right: 72px !important;
}

.p-v-72 {
  padding-top: 72px !important;
  padding-bottom: 72px !important;
}

.m-73 {
  margin: 73px !important;
}

.m-l-73 {
  margin-left: 73px !important;
}

.m-t-73 {
  margin-top: 73px !important;
}

.m-r-73 {
  margin-right: 73px !important;
}

.m-b-73 {
  margin-bottom: 73px !important;
}

.m-h-73 {
  margin-left: 73px !important;
  margin-right: 73px !important;
}

.m-v-73 {
  margin-top: 73px !important;
  margin-bottom: 73px !important;
}

.p-73 {
  padding: 73px !important;
}

.p-l-73 {
  padding-left: 73px !important;
}

.p-t-73 {
  padding-top: 73px !important;
}

.p-r-73 {
  padding-right: 73px !important;
}

.p-b-73 {
  padding-bottom: 73px !important;
}

.p-h-73 {
  padding-left: 73px !important;
  padding-right: 73px !important;
}

.p-v-73 {
  padding-top: 73px !important;
  padding-bottom: 73px !important;
}

.m-74 {
  margin: 74px !important;
}

.m-l-74 {
  margin-left: 74px !important;
}

.m-t-74 {
  margin-top: 74px !important;
}

.m-r-74 {
  margin-right: 74px !important;
}

.m-b-74 {
  margin-bottom: 74px !important;
}

.m-h-74 {
  margin-left: 74px !important;
  margin-right: 74px !important;
}

.m-v-74 {
  margin-top: 74px !important;
  margin-bottom: 74px !important;
}

.p-74 {
  padding: 74px !important;
}

.p-l-74 {
  padding-left: 74px !important;
}

.p-t-74 {
  padding-top: 74px !important;
}

.p-r-74 {
  padding-right: 74px !important;
}

.p-b-74 {
  padding-bottom: 74px !important;
}

.p-h-74 {
  padding-left: 74px !important;
  padding-right: 74px !important;
}

.p-v-74 {
  padding-top: 74px !important;
  padding-bottom: 74px !important;
}

.m-75 {
  margin: 75px !important;
}

.m-l-75 {
  margin-left: 75px !important;
}

.m-t-75 {
  margin-top: 75px !important;
}

.m-r-75 {
  margin-right: 75px !important;
}

.m-b-75 {
  margin-bottom: 75px !important;
}

.m-h-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.m-v-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.p-75 {
  padding: 75px !important;
}

.p-l-75 {
  padding-left: 75px !important;
}

.p-t-75 {
  padding-top: 75px !important;
}

.p-r-75 {
  padding-right: 75px !important;
}

.p-b-75 {
  padding-bottom: 75px !important;
}

.p-h-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.p-v-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.m-76 {
  margin: 76px !important;
}

.m-l-76 {
  margin-left: 76px !important;
}

.m-t-76 {
  margin-top: 76px !important;
}

.m-r-76 {
  margin-right: 76px !important;
}

.m-b-76 {
  margin-bottom: 76px !important;
}

.m-h-76 {
  margin-left: 76px !important;
  margin-right: 76px !important;
}

.m-v-76 {
  margin-top: 76px !important;
  margin-bottom: 76px !important;
}

.p-76 {
  padding: 76px !important;
}

.p-l-76 {
  padding-left: 76px !important;
}

.p-t-76 {
  padding-top: 76px !important;
}

.p-r-76 {
  padding-right: 76px !important;
}

.p-b-76 {
  padding-bottom: 76px !important;
}

.p-h-76 {
  padding-left: 76px !important;
  padding-right: 76px !important;
}

.p-v-76 {
  padding-top: 76px !important;
  padding-bottom: 76px !important;
}

.m-77 {
  margin: 77px !important;
}

.m-l-77 {
  margin-left: 77px !important;
}

.m-t-77 {
  margin-top: 77px !important;
}

.m-r-77 {
  margin-right: 77px !important;
}

.m-b-77 {
  margin-bottom: 77px !important;
}

.m-h-77 {
  margin-left: 77px !important;
  margin-right: 77px !important;
}

.m-v-77 {
  margin-top: 77px !important;
  margin-bottom: 77px !important;
}

.p-77 {
  padding: 77px !important;
}

.p-l-77 {
  padding-left: 77px !important;
}

.p-t-77 {
  padding-top: 77px !important;
}

.p-r-77 {
  padding-right: 77px !important;
}

.p-b-77 {
  padding-bottom: 77px !important;
}

.p-h-77 {
  padding-left: 77px !important;
  padding-right: 77px !important;
}

.p-v-77 {
  padding-top: 77px !important;
  padding-bottom: 77px !important;
}

.m-78 {
  margin: 78px !important;
}

.m-l-78 {
  margin-left: 78px !important;
}

.m-t-78 {
  margin-top: 78px !important;
}

.m-r-78 {
  margin-right: 78px !important;
}

.m-b-78 {
  margin-bottom: 78px !important;
}

.m-h-78 {
  margin-left: 78px !important;
  margin-right: 78px !important;
}

.m-v-78 {
  margin-top: 78px !important;
  margin-bottom: 78px !important;
}

.p-78 {
  padding: 78px !important;
}

.p-l-78 {
  padding-left: 78px !important;
}

.p-t-78 {
  padding-top: 78px !important;
}

.p-r-78 {
  padding-right: 78px !important;
}

.p-b-78 {
  padding-bottom: 78px !important;
}

.p-h-78 {
  padding-left: 78px !important;
  padding-right: 78px !important;
}

.p-v-78 {
  padding-top: 78px !important;
  padding-bottom: 78px !important;
}

.m-79 {
  margin: 79px !important;
}

.m-l-79 {
  margin-left: 79px !important;
}

.m-t-79 {
  margin-top: 79px !important;
}

.m-r-79 {
  margin-right: 79px !important;
}

.m-b-79 {
  margin-bottom: 79px !important;
}

.m-h-79 {
  margin-left: 79px !important;
  margin-right: 79px !important;
}

.m-v-79 {
  margin-top: 79px !important;
  margin-bottom: 79px !important;
}

.p-79 {
  padding: 79px !important;
}

.p-l-79 {
  padding-left: 79px !important;
}

.p-t-79 {
  padding-top: 79px !important;
}

.p-r-79 {
  padding-right: 79px !important;
}

.p-b-79 {
  padding-bottom: 79px !important;
}

.p-h-79 {
  padding-left: 79px !important;
  padding-right: 79px !important;
}

.p-v-79 {
  padding-top: 79px !important;
  padding-bottom: 79px !important;
}

.m-80 {
  margin: 80px !important;
}

.m-l-80 {
  margin-left: 80px !important;
}

.m-t-80 {
  margin-top: 80px !important;
}

.m-r-80 {
  margin-right: 80px !important;
}

.m-b-80 {
  margin-bottom: 80px !important;
}

.m-h-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.m-v-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.p-80 {
  padding: 80px !important;
}

.p-l-80 {
  padding-left: 80px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.p-r-80 {
  padding-right: 80px !important;
}

.p-b-80 {
  padding-bottom: 80px !important;
}

.p-h-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.p-v-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.m-81 {
  margin: 81px !important;
}

.m-l-81 {
  margin-left: 81px !important;
}

.m-t-81 {
  margin-top: 81px !important;
}

.m-r-81 {
  margin-right: 81px !important;
}

.m-b-81 {
  margin-bottom: 81px !important;
}

.m-h-81 {
  margin-left: 81px !important;
  margin-right: 81px !important;
}

.m-v-81 {
  margin-top: 81px !important;
  margin-bottom: 81px !important;
}

.p-81 {
  padding: 81px !important;
}

.p-l-81 {
  padding-left: 81px !important;
}

.p-t-81 {
  padding-top: 81px !important;
}

.p-r-81 {
  padding-right: 81px !important;
}

.p-b-81 {
  padding-bottom: 81px !important;
}

.p-h-81 {
  padding-left: 81px !important;
  padding-right: 81px !important;
}

.p-v-81 {
  padding-top: 81px !important;
  padding-bottom: 81px !important;
}

.m-82 {
  margin: 82px !important;
}

.m-l-82 {
  margin-left: 82px !important;
}

.m-t-82 {
  margin-top: 82px !important;
}

.m-r-82 {
  margin-right: 82px !important;
}

.m-b-82 {
  margin-bottom: 82px !important;
}

.m-h-82 {
  margin-left: 82px !important;
  margin-right: 82px !important;
}

.m-v-82 {
  margin-top: 82px !important;
  margin-bottom: 82px !important;
}

.p-82 {
  padding: 82px !important;
}

.p-l-82 {
  padding-left: 82px !important;
}

.p-t-82 {
  padding-top: 82px !important;
}

.p-r-82 {
  padding-right: 82px !important;
}

.p-b-82 {
  padding-bottom: 82px !important;
}

.p-h-82 {
  padding-left: 82px !important;
  padding-right: 82px !important;
}

.p-v-82 {
  padding-top: 82px !important;
  padding-bottom: 82px !important;
}

.m-83 {
  margin: 83px !important;
}

.m-l-83 {
  margin-left: 83px !important;
}

.m-t-83 {
  margin-top: 83px !important;
}

.m-r-83 {
  margin-right: 83px !important;
}

.m-b-83 {
  margin-bottom: 83px !important;
}

.m-h-83 {
  margin-left: 83px !important;
  margin-right: 83px !important;
}

.m-v-83 {
  margin-top: 83px !important;
  margin-bottom: 83px !important;
}

.p-83 {
  padding: 83px !important;
}

.p-l-83 {
  padding-left: 83px !important;
}

.p-t-83 {
  padding-top: 83px !important;
}

.p-r-83 {
  padding-right: 83px !important;
}

.p-b-83 {
  padding-bottom: 83px !important;
}

.p-h-83 {
  padding-left: 83px !important;
  padding-right: 83px !important;
}

.p-v-83 {
  padding-top: 83px !important;
  padding-bottom: 83px !important;
}

.m-84 {
  margin: 84px !important;
}

.m-l-84 {
  margin-left: 84px !important;
}

.m-t-84 {
  margin-top: 84px !important;
}

.m-r-84 {
  margin-right: 84px !important;
}

.m-b-84 {
  margin-bottom: 84px !important;
}

.m-h-84 {
  margin-left: 84px !important;
  margin-right: 84px !important;
}

.m-v-84 {
  margin-top: 84px !important;
  margin-bottom: 84px !important;
}

.p-84 {
  padding: 84px !important;
}

.p-l-84 {
  padding-left: 84px !important;
}

.p-t-84 {
  padding-top: 84px !important;
}

.p-r-84 {
  padding-right: 84px !important;
}

.p-b-84 {
  padding-bottom: 84px !important;
}

.p-h-84 {
  padding-left: 84px !important;
  padding-right: 84px !important;
}

.p-v-84 {
  padding-top: 84px !important;
  padding-bottom: 84px !important;
}

.m-85 {
  margin: 85px !important;
}

.m-l-85 {
  margin-left: 85px !important;
}

.m-t-85 {
  margin-top: 85px !important;
}

.m-r-85 {
  margin-right: 85px !important;
}

.m-b-85 {
  margin-bottom: 85px !important;
}

.m-h-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.m-v-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.p-85 {
  padding: 85px !important;
}

.p-l-85 {
  padding-left: 85px !important;
}

.p-t-85 {
  padding-top: 85px !important;
}

.p-r-85 {
  padding-right: 85px !important;
}

.p-b-85 {
  padding-bottom: 85px !important;
}

.p-h-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.p-v-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.m-86 {
  margin: 86px !important;
}

.m-l-86 {
  margin-left: 86px !important;
}

.m-t-86 {
  margin-top: 86px !important;
}

.m-r-86 {
  margin-right: 86px !important;
}

.m-b-86 {
  margin-bottom: 86px !important;
}

.m-h-86 {
  margin-left: 86px !important;
  margin-right: 86px !important;
}

.m-v-86 {
  margin-top: 86px !important;
  margin-bottom: 86px !important;
}

.p-86 {
  padding: 86px !important;
}

.p-l-86 {
  padding-left: 86px !important;
}

.p-t-86 {
  padding-top: 86px !important;
}

.p-r-86 {
  padding-right: 86px !important;
}

.p-b-86 {
  padding-bottom: 86px !important;
}

.p-h-86 {
  padding-left: 86px !important;
  padding-right: 86px !important;
}

.p-v-86 {
  padding-top: 86px !important;
  padding-bottom: 86px !important;
}

.m-87 {
  margin: 87px !important;
}

.m-l-87 {
  margin-left: 87px !important;
}

.m-t-87 {
  margin-top: 87px !important;
}

.m-r-87 {
  margin-right: 87px !important;
}

.m-b-87 {
  margin-bottom: 87px !important;
}

.m-h-87 {
  margin-left: 87px !important;
  margin-right: 87px !important;
}

.m-v-87 {
  margin-top: 87px !important;
  margin-bottom: 87px !important;
}

.p-87 {
  padding: 87px !important;
}

.p-l-87 {
  padding-left: 87px !important;
}

.p-t-87 {
  padding-top: 87px !important;
}

.p-r-87 {
  padding-right: 87px !important;
}

.p-b-87 {
  padding-bottom: 87px !important;
}

.p-h-87 {
  padding-left: 87px !important;
  padding-right: 87px !important;
}

.p-v-87 {
  padding-top: 87px !important;
  padding-bottom: 87px !important;
}

.m-88 {
  margin: 88px !important;
}

.m-l-88 {
  margin-left: 88px !important;
}

.m-t-88 {
  margin-top: 88px !important;
}

.m-r-88 {
  margin-right: 88px !important;
}

.m-b-88 {
  margin-bottom: 88px !important;
}

.m-h-88 {
  margin-left: 88px !important;
  margin-right: 88px !important;
}

.m-v-88 {
  margin-top: 88px !important;
  margin-bottom: 88px !important;
}

.p-88 {
  padding: 88px !important;
}

.p-l-88 {
  padding-left: 88px !important;
}

.p-t-88 {
  padding-top: 88px !important;
}

.p-r-88 {
  padding-right: 88px !important;
}

.p-b-88 {
  padding-bottom: 88px !important;
}

.p-h-88 {
  padding-left: 88px !important;
  padding-right: 88px !important;
}

.p-v-88 {
  padding-top: 88px !important;
  padding-bottom: 88px !important;
}

.m-89 {
  margin: 89px !important;
}

.m-l-89 {
  margin-left: 89px !important;
}

.m-t-89 {
  margin-top: 89px !important;
}

.m-r-89 {
  margin-right: 89px !important;
}

.m-b-89 {
  margin-bottom: 89px !important;
}

.m-h-89 {
  margin-left: 89px !important;
  margin-right: 89px !important;
}

.m-v-89 {
  margin-top: 89px !important;
  margin-bottom: 89px !important;
}

.p-89 {
  padding: 89px !important;
}

.p-l-89 {
  padding-left: 89px !important;
}

.p-t-89 {
  padding-top: 89px !important;
}

.p-r-89 {
  padding-right: 89px !important;
}

.p-b-89 {
  padding-bottom: 89px !important;
}

.p-h-89 {
  padding-left: 89px !important;
  padding-right: 89px !important;
}

.p-v-89 {
  padding-top: 89px !important;
  padding-bottom: 89px !important;
}

.m-90 {
  margin: 90px !important;
}

.m-l-90 {
  margin-left: 90px !important;
}

.m-t-90 {
  margin-top: 90px !important;
}

.m-r-90 {
  margin-right: 90px !important;
}

.m-b-90 {
  margin-bottom: 90px !important;
}

.m-h-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.m-v-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.p-90 {
  padding: 90px !important;
}

.p-l-90 {
  padding-left: 90px !important;
}

.p-t-90 {
  padding-top: 90px !important;
}

.p-r-90 {
  padding-right: 90px !important;
}

.p-b-90 {
  padding-bottom: 90px !important;
}

.p-h-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.p-v-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.m-91 {
  margin: 91px !important;
}

.m-l-91 {
  margin-left: 91px !important;
}

.m-t-91 {
  margin-top: 91px !important;
}

.m-r-91 {
  margin-right: 91px !important;
}

.m-b-91 {
  margin-bottom: 91px !important;
}

.m-h-91 {
  margin-left: 91px !important;
  margin-right: 91px !important;
}

.m-v-91 {
  margin-top: 91px !important;
  margin-bottom: 91px !important;
}

.p-91 {
  padding: 91px !important;
}

.p-l-91 {
  padding-left: 91px !important;
}

.p-t-91 {
  padding-top: 91px !important;
}

.p-r-91 {
  padding-right: 91px !important;
}

.p-b-91 {
  padding-bottom: 91px !important;
}

.p-h-91 {
  padding-left: 91px !important;
  padding-right: 91px !important;
}

.p-v-91 {
  padding-top: 91px !important;
  padding-bottom: 91px !important;
}

.m-92 {
  margin: 92px !important;
}

.m-l-92 {
  margin-left: 92px !important;
}

.m-t-92 {
  margin-top: 92px !important;
}

.m-r-92 {
  margin-right: 92px !important;
}

.m-b-92 {
  margin-bottom: 92px !important;
}

.m-h-92 {
  margin-left: 92px !important;
  margin-right: 92px !important;
}

.m-v-92 {
  margin-top: 92px !important;
  margin-bottom: 92px !important;
}

.p-92 {
  padding: 92px !important;
}

.p-l-92 {
  padding-left: 92px !important;
}

.p-t-92 {
  padding-top: 92px !important;
}

.p-r-92 {
  padding-right: 92px !important;
}

.p-b-92 {
  padding-bottom: 92px !important;
}

.p-h-92 {
  padding-left: 92px !important;
  padding-right: 92px !important;
}

.p-v-92 {
  padding-top: 92px !important;
  padding-bottom: 92px !important;
}

.m-93 {
  margin: 93px !important;
}

.m-l-93 {
  margin-left: 93px !important;
}

.m-t-93 {
  margin-top: 93px !important;
}

.m-r-93 {
  margin-right: 93px !important;
}

.m-b-93 {
  margin-bottom: 93px !important;
}

.m-h-93 {
  margin-left: 93px !important;
  margin-right: 93px !important;
}

.m-v-93 {
  margin-top: 93px !important;
  margin-bottom: 93px !important;
}

.p-93 {
  padding: 93px !important;
}

.p-l-93 {
  padding-left: 93px !important;
}

.p-t-93 {
  padding-top: 93px !important;
}

.p-r-93 {
  padding-right: 93px !important;
}

.p-b-93 {
  padding-bottom: 93px !important;
}

.p-h-93 {
  padding-left: 93px !important;
  padding-right: 93px !important;
}

.p-v-93 {
  padding-top: 93px !important;
  padding-bottom: 93px !important;
}

.m-94 {
  margin: 94px !important;
}

.m-l-94 {
  margin-left: 94px !important;
}

.m-t-94 {
  margin-top: 94px !important;
}

.m-r-94 {
  margin-right: 94px !important;
}

.m-b-94 {
  margin-bottom: 94px !important;
}

.m-h-94 {
  margin-left: 94px !important;
  margin-right: 94px !important;
}

.m-v-94 {
  margin-top: 94px !important;
  margin-bottom: 94px !important;
}

.p-94 {
  padding: 94px !important;
}

.p-l-94 {
  padding-left: 94px !important;
}

.p-t-94 {
  padding-top: 94px !important;
}

.p-r-94 {
  padding-right: 94px !important;
}

.p-b-94 {
  padding-bottom: 94px !important;
}

.p-h-94 {
  padding-left: 94px !important;
  padding-right: 94px !important;
}

.p-v-94 {
  padding-top: 94px !important;
  padding-bottom: 94px !important;
}

.m-95 {
  margin: 95px !important;
}

.m-l-95 {
  margin-left: 95px !important;
}

.m-t-95 {
  margin-top: 95px !important;
}

.m-r-95 {
  margin-right: 95px !important;
}

.m-b-95 {
  margin-bottom: 95px !important;
}

.m-h-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.m-v-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.p-95 {
  padding: 95px !important;
}

.p-l-95 {
  padding-left: 95px !important;
}

.p-t-95 {
  padding-top: 95px !important;
}

.p-r-95 {
  padding-right: 95px !important;
}

.p-b-95 {
  padding-bottom: 95px !important;
}

.p-h-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.p-v-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.m-96 {
  margin: 96px !important;
}

.m-l-96 {
  margin-left: 96px !important;
}

.m-t-96 {
  margin-top: 96px !important;
}

.m-r-96 {
  margin-right: 96px !important;
}

.m-b-96 {
  margin-bottom: 96px !important;
}

.m-h-96 {
  margin-left: 96px !important;
  margin-right: 96px !important;
}

.m-v-96 {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}

.p-96 {
  padding: 96px !important;
}

.p-l-96 {
  padding-left: 96px !important;
}

.p-t-96 {
  padding-top: 96px !important;
}

.p-r-96 {
  padding-right: 96px !important;
}

.p-b-96 {
  padding-bottom: 96px !important;
}

.p-h-96 {
  padding-left: 96px !important;
  padding-right: 96px !important;
}

.p-v-96 {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}

.m-97 {
  margin: 97px !important;
}

.m-l-97 {
  margin-left: 97px !important;
}

.m-t-97 {
  margin-top: 97px !important;
}

.m-r-97 {
  margin-right: 97px !important;
}

.m-b-97 {
  margin-bottom: 97px !important;
}

.m-h-97 {
  margin-left: 97px !important;
  margin-right: 97px !important;
}

.m-v-97 {
  margin-top: 97px !important;
  margin-bottom: 97px !important;
}

.p-97 {
  padding: 97px !important;
}

.p-l-97 {
  padding-left: 97px !important;
}

.p-t-97 {
  padding-top: 97px !important;
}

.p-r-97 {
  padding-right: 97px !important;
}

.p-b-97 {
  padding-bottom: 97px !important;
}

.p-h-97 {
  padding-left: 97px !important;
  padding-right: 97px !important;
}

.p-v-97 {
  padding-top: 97px !important;
  padding-bottom: 97px !important;
}

.m-98 {
  margin: 98px !important;
}

.m-l-98 {
  margin-left: 98px !important;
}

.m-t-98 {
  margin-top: 98px !important;
}

.m-r-98 {
  margin-right: 98px !important;
}

.m-b-98 {
  margin-bottom: 98px !important;
}

.m-h-98 {
  margin-left: 98px !important;
  margin-right: 98px !important;
}

.m-v-98 {
  margin-top: 98px !important;
  margin-bottom: 98px !important;
}

.p-98 {
  padding: 98px !important;
}

.p-l-98 {
  padding-left: 98px !important;
}

.p-t-98 {
  padding-top: 98px !important;
}

.p-r-98 {
  padding-right: 98px !important;
}

.p-b-98 {
  padding-bottom: 98px !important;
}

.p-h-98 {
  padding-left: 98px !important;
  padding-right: 98px !important;
}

.p-v-98 {
  padding-top: 98px !important;
  padding-bottom: 98px !important;
}

.m-99 {
  margin: 99px !important;
}

.m-l-99 {
  margin-left: 99px !important;
}

.m-t-99 {
  margin-top: 99px !important;
}

.m-r-99 {
  margin-right: 99px !important;
}

.m-b-99 {
  margin-bottom: 99px !important;
}

.m-h-99 {
  margin-left: 99px !important;
  margin-right: 99px !important;
}

.m-v-99 {
  margin-top: 99px !important;
  margin-bottom: 99px !important;
}

.p-99 {
  padding: 99px !important;
}

.p-l-99 {
  padding-left: 99px !important;
}

.p-t-99 {
  padding-top: 99px !important;
}

.p-r-99 {
  padding-right: 99px !important;
}

.p-b-99 {
  padding-bottom: 99px !important;
}

.p-h-99 {
  padding-left: 99px !important;
  padding-right: 99px !important;
}

.p-v-99 {
  padding-top: 99px !important;
  padding-bottom: 99px !important;
}

.m-100 {
  margin: 100px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-r-100 {
  margin-right: 100px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.m-h-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.m-v-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.p-100 {
  padding: 100px !important;
}

.p-l-100 {
  padding-left: 100px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.p-r-100 {
  padding-right: 100px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.p-h-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.p-v-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.m-101 {
  margin: 101px !important;
}

.m-l-101 {
  margin-left: 101px !important;
}

.m-t-101 {
  margin-top: 101px !important;
}

.m-r-101 {
  margin-right: 101px !important;
}

.m-b-101 {
  margin-bottom: 101px !important;
}

.m-h-101 {
  margin-left: 101px !important;
  margin-right: 101px !important;
}

.m-v-101 {
  margin-top: 101px !important;
  margin-bottom: 101px !important;
}

.p-101 {
  padding: 101px !important;
}

.p-l-101 {
  padding-left: 101px !important;
}

.p-t-101 {
  padding-top: 101px !important;
}

.p-r-101 {
  padding-right: 101px !important;
}

.p-b-101 {
  padding-bottom: 101px !important;
}

.p-h-101 {
  padding-left: 101px !important;
  padding-right: 101px !important;
}

.p-v-101 {
  padding-top: 101px !important;
  padding-bottom: 101px !important;
}

.m-102 {
  margin: 102px !important;
}

.m-l-102 {
  margin-left: 102px !important;
}

.m-t-102 {
  margin-top: 102px !important;
}

.m-r-102 {
  margin-right: 102px !important;
}

.m-b-102 {
  margin-bottom: 102px !important;
}

.m-h-102 {
  margin-left: 102px !important;
  margin-right: 102px !important;
}

.m-v-102 {
  margin-top: 102px !important;
  margin-bottom: 102px !important;
}

.p-102 {
  padding: 102px !important;
}

.p-l-102 {
  padding-left: 102px !important;
}

.p-t-102 {
  padding-top: 102px !important;
}

.p-r-102 {
  padding-right: 102px !important;
}

.p-b-102 {
  padding-bottom: 102px !important;
}

.p-h-102 {
  padding-left: 102px !important;
  padding-right: 102px !important;
}

.p-v-102 {
  padding-top: 102px !important;
  padding-bottom: 102px !important;
}

.m-103 {
  margin: 103px !important;
}

.m-l-103 {
  margin-left: 103px !important;
}

.m-t-103 {
  margin-top: 103px !important;
}

.m-r-103 {
  margin-right: 103px !important;
}

.m-b-103 {
  margin-bottom: 103px !important;
}

.m-h-103 {
  margin-left: 103px !important;
  margin-right: 103px !important;
}

.m-v-103 {
  margin-top: 103px !important;
  margin-bottom: 103px !important;
}

.p-103 {
  padding: 103px !important;
}

.p-l-103 {
  padding-left: 103px !important;
}

.p-t-103 {
  padding-top: 103px !important;
}

.p-r-103 {
  padding-right: 103px !important;
}

.p-b-103 {
  padding-bottom: 103px !important;
}

.p-h-103 {
  padding-left: 103px !important;
  padding-right: 103px !important;
}

.p-v-103 {
  padding-top: 103px !important;
  padding-bottom: 103px !important;
}

.m-104 {
  margin: 104px !important;
}

.m-l-104 {
  margin-left: 104px !important;
}

.m-t-104 {
  margin-top: 104px !important;
}

.m-r-104 {
  margin-right: 104px !important;
}

.m-b-104 {
  margin-bottom: 104px !important;
}

.m-h-104 {
  margin-left: 104px !important;
  margin-right: 104px !important;
}

.m-v-104 {
  margin-top: 104px !important;
  margin-bottom: 104px !important;
}

.p-104 {
  padding: 104px !important;
}

.p-l-104 {
  padding-left: 104px !important;
}

.p-t-104 {
  padding-top: 104px !important;
}

.p-r-104 {
  padding-right: 104px !important;
}

.p-b-104 {
  padding-bottom: 104px !important;
}

.p-h-104 {
  padding-left: 104px !important;
  padding-right: 104px !important;
}

.p-v-104 {
  padding-top: 104px !important;
  padding-bottom: 104px !important;
}

.m-105 {
  margin: 105px !important;
}

.m-l-105 {
  margin-left: 105px !important;
}

.m-t-105 {
  margin-top: 105px !important;
}

.m-r-105 {
  margin-right: 105px !important;
}

.m-b-105 {
  margin-bottom: 105px !important;
}

.m-h-105 {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

.m-v-105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

.p-105 {
  padding: 105px !important;
}

.p-l-105 {
  padding-left: 105px !important;
}

.p-t-105 {
  padding-top: 105px !important;
}

.p-r-105 {
  padding-right: 105px !important;
}

.p-b-105 {
  padding-bottom: 105px !important;
}

.p-h-105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

.p-v-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

.m-106 {
  margin: 106px !important;
}

.m-l-106 {
  margin-left: 106px !important;
}

.m-t-106 {
  margin-top: 106px !important;
}

.m-r-106 {
  margin-right: 106px !important;
}

.m-b-106 {
  margin-bottom: 106px !important;
}

.m-h-106 {
  margin-left: 106px !important;
  margin-right: 106px !important;
}

.m-v-106 {
  margin-top: 106px !important;
  margin-bottom: 106px !important;
}

.p-106 {
  padding: 106px !important;
}

.p-l-106 {
  padding-left: 106px !important;
}

.p-t-106 {
  padding-top: 106px !important;
}

.p-r-106 {
  padding-right: 106px !important;
}

.p-b-106 {
  padding-bottom: 106px !important;
}

.p-h-106 {
  padding-left: 106px !important;
  padding-right: 106px !important;
}

.p-v-106 {
  padding-top: 106px !important;
  padding-bottom: 106px !important;
}

.m-107 {
  margin: 107px !important;
}

.m-l-107 {
  margin-left: 107px !important;
}

.m-t-107 {
  margin-top: 107px !important;
}

.m-r-107 {
  margin-right: 107px !important;
}

.m-b-107 {
  margin-bottom: 107px !important;
}

.m-h-107 {
  margin-left: 107px !important;
  margin-right: 107px !important;
}

.m-v-107 {
  margin-top: 107px !important;
  margin-bottom: 107px !important;
}

.p-107 {
  padding: 107px !important;
}

.p-l-107 {
  padding-left: 107px !important;
}

.p-t-107 {
  padding-top: 107px !important;
}

.p-r-107 {
  padding-right: 107px !important;
}

.p-b-107 {
  padding-bottom: 107px !important;
}

.p-h-107 {
  padding-left: 107px !important;
  padding-right: 107px !important;
}

.p-v-107 {
  padding-top: 107px !important;
  padding-bottom: 107px !important;
}

.m-108 {
  margin: 108px !important;
}

.m-l-108 {
  margin-left: 108px !important;
}

.m-t-108 {
  margin-top: 108px !important;
}

.m-r-108 {
  margin-right: 108px !important;
}

.m-b-108 {
  margin-bottom: 108px !important;
}

.m-h-108 {
  margin-left: 108px !important;
  margin-right: 108px !important;
}

.m-v-108 {
  margin-top: 108px !important;
  margin-bottom: 108px !important;
}

.p-108 {
  padding: 108px !important;
}

.p-l-108 {
  padding-left: 108px !important;
}

.p-t-108 {
  padding-top: 108px !important;
}

.p-r-108 {
  padding-right: 108px !important;
}

.p-b-108 {
  padding-bottom: 108px !important;
}

.p-h-108 {
  padding-left: 108px !important;
  padding-right: 108px !important;
}

.p-v-108 {
  padding-top: 108px !important;
  padding-bottom: 108px !important;
}

.m-109 {
  margin: 109px !important;
}

.m-l-109 {
  margin-left: 109px !important;
}

.m-t-109 {
  margin-top: 109px !important;
}

.m-r-109 {
  margin-right: 109px !important;
}

.m-b-109 {
  margin-bottom: 109px !important;
}

.m-h-109 {
  margin-left: 109px !important;
  margin-right: 109px !important;
}

.m-v-109 {
  margin-top: 109px !important;
  margin-bottom: 109px !important;
}

.p-109 {
  padding: 109px !important;
}

.p-l-109 {
  padding-left: 109px !important;
}

.p-t-109 {
  padding-top: 109px !important;
}

.p-r-109 {
  padding-right: 109px !important;
}

.p-b-109 {
  padding-bottom: 109px !important;
}

.p-h-109 {
  padding-left: 109px !important;
  padding-right: 109px !important;
}

.p-v-109 {
  padding-top: 109px !important;
  padding-bottom: 109px !important;
}

.m-110 {
  margin: 110px !important;
}

.m-l-110 {
  margin-left: 110px !important;
}

.m-t-110 {
  margin-top: 110px !important;
}

.m-r-110 {
  margin-right: 110px !important;
}

.m-b-110 {
  margin-bottom: 110px !important;
}

.m-h-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.m-v-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.p-110 {
  padding: 110px !important;
}

.p-l-110 {
  padding-left: 110px !important;
}

.p-t-110 {
  padding-top: 110px !important;
}

.p-r-110 {
  padding-right: 110px !important;
}

.p-b-110 {
  padding-bottom: 110px !important;
}

.p-h-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.p-v-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.m-111 {
  margin: 111px !important;
}

.m-l-111 {
  margin-left: 111px !important;
}

.m-t-111 {
  margin-top: 111px !important;
}

.m-r-111 {
  margin-right: 111px !important;
}

.m-b-111 {
  margin-bottom: 111px !important;
}

.m-h-111 {
  margin-left: 111px !important;
  margin-right: 111px !important;
}

.m-v-111 {
  margin-top: 111px !important;
  margin-bottom: 111px !important;
}

.p-111 {
  padding: 111px !important;
}

.p-l-111 {
  padding-left: 111px !important;
}

.p-t-111 {
  padding-top: 111px !important;
}

.p-r-111 {
  padding-right: 111px !important;
}

.p-b-111 {
  padding-bottom: 111px !important;
}

.p-h-111 {
  padding-left: 111px !important;
  padding-right: 111px !important;
}

.p-v-111 {
  padding-top: 111px !important;
  padding-bottom: 111px !important;
}

.m-112 {
  margin: 112px !important;
}

.m-l-112 {
  margin-left: 112px !important;
}

.m-t-112 {
  margin-top: 112px !important;
}

.m-r-112 {
  margin-right: 112px !important;
}

.m-b-112 {
  margin-bottom: 112px !important;
}

.m-h-112 {
  margin-left: 112px !important;
  margin-right: 112px !important;
}

.m-v-112 {
  margin-top: 112px !important;
  margin-bottom: 112px !important;
}

.p-112 {
  padding: 112px !important;
}

.p-l-112 {
  padding-left: 112px !important;
}

.p-t-112 {
  padding-top: 112px !important;
}

.p-r-112 {
  padding-right: 112px !important;
}

.p-b-112 {
  padding-bottom: 112px !important;
}

.p-h-112 {
  padding-left: 112px !important;
  padding-right: 112px !important;
}

.p-v-112 {
  padding-top: 112px !important;
  padding-bottom: 112px !important;
}

.m-113 {
  margin: 113px !important;
}

.m-l-113 {
  margin-left: 113px !important;
}

.m-t-113 {
  margin-top: 113px !important;
}

.m-r-113 {
  margin-right: 113px !important;
}

.m-b-113 {
  margin-bottom: 113px !important;
}

.m-h-113 {
  margin-left: 113px !important;
  margin-right: 113px !important;
}

.m-v-113 {
  margin-top: 113px !important;
  margin-bottom: 113px !important;
}

.p-113 {
  padding: 113px !important;
}

.p-l-113 {
  padding-left: 113px !important;
}

.p-t-113 {
  padding-top: 113px !important;
}

.p-r-113 {
  padding-right: 113px !important;
}

.p-b-113 {
  padding-bottom: 113px !important;
}

.p-h-113 {
  padding-left: 113px !important;
  padding-right: 113px !important;
}

.p-v-113 {
  padding-top: 113px !important;
  padding-bottom: 113px !important;
}

.m-114 {
  margin: 114px !important;
}

.m-l-114 {
  margin-left: 114px !important;
}

.m-t-114 {
  margin-top: 114px !important;
}

.m-r-114 {
  margin-right: 114px !important;
}

.m-b-114 {
  margin-bottom: 114px !important;
}

.m-h-114 {
  margin-left: 114px !important;
  margin-right: 114px !important;
}

.m-v-114 {
  margin-top: 114px !important;
  margin-bottom: 114px !important;
}

.p-114 {
  padding: 114px !important;
}

.p-l-114 {
  padding-left: 114px !important;
}

.p-t-114 {
  padding-top: 114px !important;
}

.p-r-114 {
  padding-right: 114px !important;
}

.p-b-114 {
  padding-bottom: 114px !important;
}

.p-h-114 {
  padding-left: 114px !important;
  padding-right: 114px !important;
}

.p-v-114 {
  padding-top: 114px !important;
  padding-bottom: 114px !important;
}

.m-115 {
  margin: 115px !important;
}

.m-l-115 {
  margin-left: 115px !important;
}

.m-t-115 {
  margin-top: 115px !important;
}

.m-r-115 {
  margin-right: 115px !important;
}

.m-b-115 {
  margin-bottom: 115px !important;
}

.m-h-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.m-v-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.p-115 {
  padding: 115px !important;
}

.p-l-115 {
  padding-left: 115px !important;
}

.p-t-115 {
  padding-top: 115px !important;
}

.p-r-115 {
  padding-right: 115px !important;
}

.p-b-115 {
  padding-bottom: 115px !important;
}

.p-h-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.p-v-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.m-116 {
  margin: 116px !important;
}

.m-l-116 {
  margin-left: 116px !important;
}

.m-t-116 {
  margin-top: 116px !important;
}

.m-r-116 {
  margin-right: 116px !important;
}

.m-b-116 {
  margin-bottom: 116px !important;
}

.m-h-116 {
  margin-left: 116px !important;
  margin-right: 116px !important;
}

.m-v-116 {
  margin-top: 116px !important;
  margin-bottom: 116px !important;
}

.p-116 {
  padding: 116px !important;
}

.p-l-116 {
  padding-left: 116px !important;
}

.p-t-116 {
  padding-top: 116px !important;
}

.p-r-116 {
  padding-right: 116px !important;
}

.p-b-116 {
  padding-bottom: 116px !important;
}

.p-h-116 {
  padding-left: 116px !important;
  padding-right: 116px !important;
}

.p-v-116 {
  padding-top: 116px !important;
  padding-bottom: 116px !important;
}

.m-117 {
  margin: 117px !important;
}

.m-l-117 {
  margin-left: 117px !important;
}

.m-t-117 {
  margin-top: 117px !important;
}

.m-r-117 {
  margin-right: 117px !important;
}

.m-b-117 {
  margin-bottom: 117px !important;
}

.m-h-117 {
  margin-left: 117px !important;
  margin-right: 117px !important;
}

.m-v-117 {
  margin-top: 117px !important;
  margin-bottom: 117px !important;
}

.p-117 {
  padding: 117px !important;
}

.p-l-117 {
  padding-left: 117px !important;
}

.p-t-117 {
  padding-top: 117px !important;
}

.p-r-117 {
  padding-right: 117px !important;
}

.p-b-117 {
  padding-bottom: 117px !important;
}

.p-h-117 {
  padding-left: 117px !important;
  padding-right: 117px !important;
}

.p-v-117 {
  padding-top: 117px !important;
  padding-bottom: 117px !important;
}

.m-118 {
  margin: 118px !important;
}

.m-l-118 {
  margin-left: 118px !important;
}

.m-t-118 {
  margin-top: 118px !important;
}

.m-r-118 {
  margin-right: 118px !important;
}

.m-b-118 {
  margin-bottom: 118px !important;
}

.m-h-118 {
  margin-left: 118px !important;
  margin-right: 118px !important;
}

.m-v-118 {
  margin-top: 118px !important;
  margin-bottom: 118px !important;
}

.p-118 {
  padding: 118px !important;
}

.p-l-118 {
  padding-left: 118px !important;
}

.p-t-118 {
  padding-top: 118px !important;
}

.p-r-118 {
  padding-right: 118px !important;
}

.p-b-118 {
  padding-bottom: 118px !important;
}

.p-h-118 {
  padding-left: 118px !important;
  padding-right: 118px !important;
}

.p-v-118 {
  padding-top: 118px !important;
  padding-bottom: 118px !important;
}

.m-119 {
  margin: 119px !important;
}

.m-l-119 {
  margin-left: 119px !important;
}

.m-t-119 {
  margin-top: 119px !important;
}

.m-r-119 {
  margin-right: 119px !important;
}

.m-b-119 {
  margin-bottom: 119px !important;
}

.m-h-119 {
  margin-left: 119px !important;
  margin-right: 119px !important;
}

.m-v-119 {
  margin-top: 119px !important;
  margin-bottom: 119px !important;
}

.p-119 {
  padding: 119px !important;
}

.p-l-119 {
  padding-left: 119px !important;
}

.p-t-119 {
  padding-top: 119px !important;
}

.p-r-119 {
  padding-right: 119px !important;
}

.p-b-119 {
  padding-bottom: 119px !important;
}

.p-h-119 {
  padding-left: 119px !important;
  padding-right: 119px !important;
}

.p-v-119 {
  padding-top: 119px !important;
  padding-bottom: 119px !important;
}

.m-120 {
  margin: 120px !important;
}

.m-l-120 {
  margin-left: 120px !important;
}

.m-t-120 {
  margin-top: 120px !important;
}

.m-r-120 {
  margin-right: 120px !important;
}

.m-b-120 {
  margin-bottom: 120px !important;
}

.m-h-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.m-v-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.p-120 {
  padding: 120px !important;
}

.p-l-120 {
  padding-left: 120px !important;
}

.p-t-120 {
  padding-top: 120px !important;
}

.p-r-120 {
  padding-right: 120px !important;
}

.p-b-120 {
  padding-bottom: 120px !important;
}

.p-h-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.p-v-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.m-121 {
  margin: 121px !important;
}

.m-l-121 {
  margin-left: 121px !important;
}

.m-t-121 {
  margin-top: 121px !important;
}

.m-r-121 {
  margin-right: 121px !important;
}

.m-b-121 {
  margin-bottom: 121px !important;
}

.m-h-121 {
  margin-left: 121px !important;
  margin-right: 121px !important;
}

.m-v-121 {
  margin-top: 121px !important;
  margin-bottom: 121px !important;
}

.p-121 {
  padding: 121px !important;
}

.p-l-121 {
  padding-left: 121px !important;
}

.p-t-121 {
  padding-top: 121px !important;
}

.p-r-121 {
  padding-right: 121px !important;
}

.p-b-121 {
  padding-bottom: 121px !important;
}

.p-h-121 {
  padding-left: 121px !important;
  padding-right: 121px !important;
}

.p-v-121 {
  padding-top: 121px !important;
  padding-bottom: 121px !important;
}

.m-122 {
  margin: 122px !important;
}

.m-l-122 {
  margin-left: 122px !important;
}

.m-t-122 {
  margin-top: 122px !important;
}

.m-r-122 {
  margin-right: 122px !important;
}

.m-b-122 {
  margin-bottom: 122px !important;
}

.m-h-122 {
  margin-left: 122px !important;
  margin-right: 122px !important;
}

.m-v-122 {
  margin-top: 122px !important;
  margin-bottom: 122px !important;
}

.p-122 {
  padding: 122px !important;
}

.p-l-122 {
  padding-left: 122px !important;
}

.p-t-122 {
  padding-top: 122px !important;
}

.p-r-122 {
  padding-right: 122px !important;
}

.p-b-122 {
  padding-bottom: 122px !important;
}

.p-h-122 {
  padding-left: 122px !important;
  padding-right: 122px !important;
}

.p-v-122 {
  padding-top: 122px !important;
  padding-bottom: 122px !important;
}

.m-123 {
  margin: 123px !important;
}

.m-l-123 {
  margin-left: 123px !important;
}

.m-t-123 {
  margin-top: 123px !important;
}

.m-r-123 {
  margin-right: 123px !important;
}

.m-b-123 {
  margin-bottom: 123px !important;
}

.m-h-123 {
  margin-left: 123px !important;
  margin-right: 123px !important;
}

.m-v-123 {
  margin-top: 123px !important;
  margin-bottom: 123px !important;
}

.p-123 {
  padding: 123px !important;
}

.p-l-123 {
  padding-left: 123px !important;
}

.p-t-123 {
  padding-top: 123px !important;
}

.p-r-123 {
  padding-right: 123px !important;
}

.p-b-123 {
  padding-bottom: 123px !important;
}

.p-h-123 {
  padding-left: 123px !important;
  padding-right: 123px !important;
}

.p-v-123 {
  padding-top: 123px !important;
  padding-bottom: 123px !important;
}

.m-124 {
  margin: 124px !important;
}

.m-l-124 {
  margin-left: 124px !important;
}

.m-t-124 {
  margin-top: 124px !important;
}

.m-r-124 {
  margin-right: 124px !important;
}

.m-b-124 {
  margin-bottom: 124px !important;
}

.m-h-124 {
  margin-left: 124px !important;
  margin-right: 124px !important;
}

.m-v-124 {
  margin-top: 124px !important;
  margin-bottom: 124px !important;
}

.p-124 {
  padding: 124px !important;
}

.p-l-124 {
  padding-left: 124px !important;
}

.p-t-124 {
  padding-top: 124px !important;
}

.p-r-124 {
  padding-right: 124px !important;
}

.p-b-124 {
  padding-bottom: 124px !important;
}

.p-h-124 {
  padding-left: 124px !important;
  padding-right: 124px !important;
}

.p-v-124 {
  padding-top: 124px !important;
  padding-bottom: 124px !important;
}

.m-125 {
  margin: 125px !important;
}

.m-l-125 {
  margin-left: 125px !important;
}

.m-t-125 {
  margin-top: 125px !important;
}

.m-r-125 {
  margin-right: 125px !important;
}

.m-b-125 {
  margin-bottom: 125px !important;
}

.m-h-125 {
  margin-left: 125px !important;
  margin-right: 125px !important;
}

.m-v-125 {
  margin-top: 125px !important;
  margin-bottom: 125px !important;
}

.p-125 {
  padding: 125px !important;
}

.p-l-125 {
  padding-left: 125px !important;
}

.p-t-125 {
  padding-top: 125px !important;
}

.p-r-125 {
  padding-right: 125px !important;
}

.p-b-125 {
  padding-bottom: 125px !important;
}

.p-h-125 {
  padding-left: 125px !important;
  padding-right: 125px !important;
}

.p-v-125 {
  padding-top: 125px !important;
  padding-bottom: 125px !important;
}

.m-126 {
  margin: 126px !important;
}

.m-l-126 {
  margin-left: 126px !important;
}

.m-t-126 {
  margin-top: 126px !important;
}

.m-r-126 {
  margin-right: 126px !important;
}

.m-b-126 {
  margin-bottom: 126px !important;
}

.m-h-126 {
  margin-left: 126px !important;
  margin-right: 126px !important;
}

.m-v-126 {
  margin-top: 126px !important;
  margin-bottom: 126px !important;
}

.p-126 {
  padding: 126px !important;
}

.p-l-126 {
  padding-left: 126px !important;
}

.p-t-126 {
  padding-top: 126px !important;
}

.p-r-126 {
  padding-right: 126px !important;
}

.p-b-126 {
  padding-bottom: 126px !important;
}

.p-h-126 {
  padding-left: 126px !important;
  padding-right: 126px !important;
}

.p-v-126 {
  padding-top: 126px !important;
  padding-bottom: 126px !important;
}

.m-127 {
  margin: 127px !important;
}

.m-l-127 {
  margin-left: 127px !important;
}

.m-t-127 {
  margin-top: 127px !important;
}

.m-r-127 {
  margin-right: 127px !important;
}

.m-b-127 {
  margin-bottom: 127px !important;
}

.m-h-127 {
  margin-left: 127px !important;
  margin-right: 127px !important;
}

.m-v-127 {
  margin-top: 127px !important;
  margin-bottom: 127px !important;
}

.p-127 {
  padding: 127px !important;
}

.p-l-127 {
  padding-left: 127px !important;
}

.p-t-127 {
  padding-top: 127px !important;
}

.p-r-127 {
  padding-right: 127px !important;
}

.p-b-127 {
  padding-bottom: 127px !important;
}

.p-h-127 {
  padding-left: 127px !important;
  padding-right: 127px !important;
}

.p-v-127 {
  padding-top: 127px !important;
  padding-bottom: 127px !important;
}

.m-128 {
  margin: 128px !important;
}

.m-l-128 {
  margin-left: 128px !important;
}

.m-t-128 {
  margin-top: 128px !important;
}

.m-r-128 {
  margin-right: 128px !important;
}

.m-b-128 {
  margin-bottom: 128px !important;
}

.m-h-128 {
  margin-left: 128px !important;
  margin-right: 128px !important;
}

.m-v-128 {
  margin-top: 128px !important;
  margin-bottom: 128px !important;
}

.p-128 {
  padding: 128px !important;
}

.p-l-128 {
  padding-left: 128px !important;
}

.p-t-128 {
  padding-top: 128px !important;
}

.p-r-128 {
  padding-right: 128px !important;
}

.p-b-128 {
  padding-bottom: 128px !important;
}

.p-h-128 {
  padding-left: 128px !important;
  padding-right: 128px !important;
}

.p-v-128 {
  padding-top: 128px !important;
  padding-bottom: 128px !important;
}

.m-129 {
  margin: 129px !important;
}

.m-l-129 {
  margin-left: 129px !important;
}

.m-t-129 {
  margin-top: 129px !important;
}

.m-r-129 {
  margin-right: 129px !important;
}

.m-b-129 {
  margin-bottom: 129px !important;
}

.m-h-129 {
  margin-left: 129px !important;
  margin-right: 129px !important;
}

.m-v-129 {
  margin-top: 129px !important;
  margin-bottom: 129px !important;
}

.p-129 {
  padding: 129px !important;
}

.p-l-129 {
  padding-left: 129px !important;
}

.p-t-129 {
  padding-top: 129px !important;
}

.p-r-129 {
  padding-right: 129px !important;
}

.p-b-129 {
  padding-bottom: 129px !important;
}

.p-h-129 {
  padding-left: 129px !important;
  padding-right: 129px !important;
}

.p-v-129 {
  padding-top: 129px !important;
  padding-bottom: 129px !important;
}

.m-130 {
  margin: 130px !important;
}

.m-l-130 {
  margin-left: 130px !important;
}

.m-t-130 {
  margin-top: 130px !important;
}

.m-r-130 {
  margin-right: 130px !important;
}

.m-b-130 {
  margin-bottom: 130px !important;
}

.m-h-130 {
  margin-left: 130px !important;
  margin-right: 130px !important;
}

.m-v-130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important;
}

.p-130 {
  padding: 130px !important;
}

.p-l-130 {
  padding-left: 130px !important;
}

.p-t-130 {
  padding-top: 130px !important;
}

.p-r-130 {
  padding-right: 130px !important;
}

.p-b-130 {
  padding-bottom: 130px !important;
}

.p-h-130 {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.p-v-130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.m-131 {
  margin: 131px !important;
}

.m-l-131 {
  margin-left: 131px !important;
}

.m-t-131 {
  margin-top: 131px !important;
}

.m-r-131 {
  margin-right: 131px !important;
}

.m-b-131 {
  margin-bottom: 131px !important;
}

.m-h-131 {
  margin-left: 131px !important;
  margin-right: 131px !important;
}

.m-v-131 {
  margin-top: 131px !important;
  margin-bottom: 131px !important;
}

.p-131 {
  padding: 131px !important;
}

.p-l-131 {
  padding-left: 131px !important;
}

.p-t-131 {
  padding-top: 131px !important;
}

.p-r-131 {
  padding-right: 131px !important;
}

.p-b-131 {
  padding-bottom: 131px !important;
}

.p-h-131 {
  padding-left: 131px !important;
  padding-right: 131px !important;
}

.p-v-131 {
  padding-top: 131px !important;
  padding-bottom: 131px !important;
}

.m-132 {
  margin: 132px !important;
}

.m-l-132 {
  margin-left: 132px !important;
}

.m-t-132 {
  margin-top: 132px !important;
}

.m-r-132 {
  margin-right: 132px !important;
}

.m-b-132 {
  margin-bottom: 132px !important;
}

.m-h-132 {
  margin-left: 132px !important;
  margin-right: 132px !important;
}

.m-v-132 {
  margin-top: 132px !important;
  margin-bottom: 132px !important;
}

.p-132 {
  padding: 132px !important;
}

.p-l-132 {
  padding-left: 132px !important;
}

.p-t-132 {
  padding-top: 132px !important;
}

.p-r-132 {
  padding-right: 132px !important;
}

.p-b-132 {
  padding-bottom: 132px !important;
}

.p-h-132 {
  padding-left: 132px !important;
  padding-right: 132px !important;
}

.p-v-132 {
  padding-top: 132px !important;
  padding-bottom: 132px !important;
}

.m-133 {
  margin: 133px !important;
}

.m-l-133 {
  margin-left: 133px !important;
}

.m-t-133 {
  margin-top: 133px !important;
}

.m-r-133 {
  margin-right: 133px !important;
}

.m-b-133 {
  margin-bottom: 133px !important;
}

.m-h-133 {
  margin-left: 133px !important;
  margin-right: 133px !important;
}

.m-v-133 {
  margin-top: 133px !important;
  margin-bottom: 133px !important;
}

.p-133 {
  padding: 133px !important;
}

.p-l-133 {
  padding-left: 133px !important;
}

.p-t-133 {
  padding-top: 133px !important;
}

.p-r-133 {
  padding-right: 133px !important;
}

.p-b-133 {
  padding-bottom: 133px !important;
}

.p-h-133 {
  padding-left: 133px !important;
  padding-right: 133px !important;
}

.p-v-133 {
  padding-top: 133px !important;
  padding-bottom: 133px !important;
}

.m-134 {
  margin: 134px !important;
}

.m-l-134 {
  margin-left: 134px !important;
}

.m-t-134 {
  margin-top: 134px !important;
}

.m-r-134 {
  margin-right: 134px !important;
}

.m-b-134 {
  margin-bottom: 134px !important;
}

.m-h-134 {
  margin-left: 134px !important;
  margin-right: 134px !important;
}

.m-v-134 {
  margin-top: 134px !important;
  margin-bottom: 134px !important;
}

.p-134 {
  padding: 134px !important;
}

.p-l-134 {
  padding-left: 134px !important;
}

.p-t-134 {
  padding-top: 134px !important;
}

.p-r-134 {
  padding-right: 134px !important;
}

.p-b-134 {
  padding-bottom: 134px !important;
}

.p-h-134 {
  padding-left: 134px !important;
  padding-right: 134px !important;
}

.p-v-134 {
  padding-top: 134px !important;
  padding-bottom: 134px !important;
}

.m-135 {
  margin: 135px !important;
}

.m-l-135 {
  margin-left: 135px !important;
}

.m-t-135 {
  margin-top: 135px !important;
}

.m-r-135 {
  margin-right: 135px !important;
}

.m-b-135 {
  margin-bottom: 135px !important;
}

.m-h-135 {
  margin-left: 135px !important;
  margin-right: 135px !important;
}

.m-v-135 {
  margin-top: 135px !important;
  margin-bottom: 135px !important;
}

.p-135 {
  padding: 135px !important;
}

.p-l-135 {
  padding-left: 135px !important;
}

.p-t-135 {
  padding-top: 135px !important;
}

.p-r-135 {
  padding-right: 135px !important;
}

.p-b-135 {
  padding-bottom: 135px !important;
}

.p-h-135 {
  padding-left: 135px !important;
  padding-right: 135px !important;
}

.p-v-135 {
  padding-top: 135px !important;
  padding-bottom: 135px !important;
}

.m-136 {
  margin: 136px !important;
}

.m-l-136 {
  margin-left: 136px !important;
}

.m-t-136 {
  margin-top: 136px !important;
}

.m-r-136 {
  margin-right: 136px !important;
}

.m-b-136 {
  margin-bottom: 136px !important;
}

.m-h-136 {
  margin-left: 136px !important;
  margin-right: 136px !important;
}

.m-v-136 {
  margin-top: 136px !important;
  margin-bottom: 136px !important;
}

.p-136 {
  padding: 136px !important;
}

.p-l-136 {
  padding-left: 136px !important;
}

.p-t-136 {
  padding-top: 136px !important;
}

.p-r-136 {
  padding-right: 136px !important;
}

.p-b-136 {
  padding-bottom: 136px !important;
}

.p-h-136 {
  padding-left: 136px !important;
  padding-right: 136px !important;
}

.p-v-136 {
  padding-top: 136px !important;
  padding-bottom: 136px !important;
}

.m-137 {
  margin: 137px !important;
}

.m-l-137 {
  margin-left: 137px !important;
}

.m-t-137 {
  margin-top: 137px !important;
}

.m-r-137 {
  margin-right: 137px !important;
}

.m-b-137 {
  margin-bottom: 137px !important;
}

.m-h-137 {
  margin-left: 137px !important;
  margin-right: 137px !important;
}

.m-v-137 {
  margin-top: 137px !important;
  margin-bottom: 137px !important;
}

.p-137 {
  padding: 137px !important;
}

.p-l-137 {
  padding-left: 137px !important;
}

.p-t-137 {
  padding-top: 137px !important;
}

.p-r-137 {
  padding-right: 137px !important;
}

.p-b-137 {
  padding-bottom: 137px !important;
}

.p-h-137 {
  padding-left: 137px !important;
  padding-right: 137px !important;
}

.p-v-137 {
  padding-top: 137px !important;
  padding-bottom: 137px !important;
}

.m-138 {
  margin: 138px !important;
}

.m-l-138 {
  margin-left: 138px !important;
}

.m-t-138 {
  margin-top: 138px !important;
}

.m-r-138 {
  margin-right: 138px !important;
}

.m-b-138 {
  margin-bottom: 138px !important;
}

.m-h-138 {
  margin-left: 138px !important;
  margin-right: 138px !important;
}

.m-v-138 {
  margin-top: 138px !important;
  margin-bottom: 138px !important;
}

.p-138 {
  padding: 138px !important;
}

.p-l-138 {
  padding-left: 138px !important;
}

.p-t-138 {
  padding-top: 138px !important;
}

.p-r-138 {
  padding-right: 138px !important;
}

.p-b-138 {
  padding-bottom: 138px !important;
}

.p-h-138 {
  padding-left: 138px !important;
  padding-right: 138px !important;
}

.p-v-138 {
  padding-top: 138px !important;
  padding-bottom: 138px !important;
}

.m-139 {
  margin: 139px !important;
}

.m-l-139 {
  margin-left: 139px !important;
}

.m-t-139 {
  margin-top: 139px !important;
}

.m-r-139 {
  margin-right: 139px !important;
}

.m-b-139 {
  margin-bottom: 139px !important;
}

.m-h-139 {
  margin-left: 139px !important;
  margin-right: 139px !important;
}

.m-v-139 {
  margin-top: 139px !important;
  margin-bottom: 139px !important;
}

.p-139 {
  padding: 139px !important;
}

.p-l-139 {
  padding-left: 139px !important;
}

.p-t-139 {
  padding-top: 139px !important;
}

.p-r-139 {
  padding-right: 139px !important;
}

.p-b-139 {
  padding-bottom: 139px !important;
}

.p-h-139 {
  padding-left: 139px !important;
  padding-right: 139px !important;
}

.p-v-139 {
  padding-top: 139px !important;
  padding-bottom: 139px !important;
}

.m-140 {
  margin: 140px !important;
}

.m-l-140 {
  margin-left: 140px !important;
}

.m-t-140 {
  margin-top: 140px !important;
}

.m-r-140 {
  margin-right: 140px !important;
}

.m-b-140 {
  margin-bottom: 140px !important;
}

.m-h-140 {
  margin-left: 140px !important;
  margin-right: 140px !important;
}

.m-v-140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important;
}

.p-140 {
  padding: 140px !important;
}

.p-l-140 {
  padding-left: 140px !important;
}

.p-t-140 {
  padding-top: 140px !important;
}

.p-r-140 {
  padding-right: 140px !important;
}

.p-b-140 {
  padding-bottom: 140px !important;
}

.p-h-140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.p-v-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.m-141 {
  margin: 141px !important;
}

.m-l-141 {
  margin-left: 141px !important;
}

.m-t-141 {
  margin-top: 141px !important;
}

.m-r-141 {
  margin-right: 141px !important;
}

.m-b-141 {
  margin-bottom: 141px !important;
}

.m-h-141 {
  margin-left: 141px !important;
  margin-right: 141px !important;
}

.m-v-141 {
  margin-top: 141px !important;
  margin-bottom: 141px !important;
}

.p-141 {
  padding: 141px !important;
}

.p-l-141 {
  padding-left: 141px !important;
}

.p-t-141 {
  padding-top: 141px !important;
}

.p-r-141 {
  padding-right: 141px !important;
}

.p-b-141 {
  padding-bottom: 141px !important;
}

.p-h-141 {
  padding-left: 141px !important;
  padding-right: 141px !important;
}

.p-v-141 {
  padding-top: 141px !important;
  padding-bottom: 141px !important;
}

.m-142 {
  margin: 142px !important;
}

.m-l-142 {
  margin-left: 142px !important;
}

.m-t-142 {
  margin-top: 142px !important;
}

.m-r-142 {
  margin-right: 142px !important;
}

.m-b-142 {
  margin-bottom: 142px !important;
}

.m-h-142 {
  margin-left: 142px !important;
  margin-right: 142px !important;
}

.m-v-142 {
  margin-top: 142px !important;
  margin-bottom: 142px !important;
}

.p-142 {
  padding: 142px !important;
}

.p-l-142 {
  padding-left: 142px !important;
}

.p-t-142 {
  padding-top: 142px !important;
}

.p-r-142 {
  padding-right: 142px !important;
}

.p-b-142 {
  padding-bottom: 142px !important;
}

.p-h-142 {
  padding-left: 142px !important;
  padding-right: 142px !important;
}

.p-v-142 {
  padding-top: 142px !important;
  padding-bottom: 142px !important;
}

.m-143 {
  margin: 143px !important;
}

.m-l-143 {
  margin-left: 143px !important;
}

.m-t-143 {
  margin-top: 143px !important;
}

.m-r-143 {
  margin-right: 143px !important;
}

.m-b-143 {
  margin-bottom: 143px !important;
}

.m-h-143 {
  margin-left: 143px !important;
  margin-right: 143px !important;
}

.m-v-143 {
  margin-top: 143px !important;
  margin-bottom: 143px !important;
}

.p-143 {
  padding: 143px !important;
}

.p-l-143 {
  padding-left: 143px !important;
}

.p-t-143 {
  padding-top: 143px !important;
}

.p-r-143 {
  padding-right: 143px !important;
}

.p-b-143 {
  padding-bottom: 143px !important;
}

.p-h-143 {
  padding-left: 143px !important;
  padding-right: 143px !important;
}

.p-v-143 {
  padding-top: 143px !important;
  padding-bottom: 143px !important;
}

.m-144 {
  margin: 144px !important;
}

.m-l-144 {
  margin-left: 144px !important;
}

.m-t-144 {
  margin-top: 144px !important;
}

.m-r-144 {
  margin-right: 144px !important;
}

.m-b-144 {
  margin-bottom: 144px !important;
}

.m-h-144 {
  margin-left: 144px !important;
  margin-right: 144px !important;
}

.m-v-144 {
  margin-top: 144px !important;
  margin-bottom: 144px !important;
}

.p-144 {
  padding: 144px !important;
}

.p-l-144 {
  padding-left: 144px !important;
}

.p-t-144 {
  padding-top: 144px !important;
}

.p-r-144 {
  padding-right: 144px !important;
}

.p-b-144 {
  padding-bottom: 144px !important;
}

.p-h-144 {
  padding-left: 144px !important;
  padding-right: 144px !important;
}

.p-v-144 {
  padding-top: 144px !important;
  padding-bottom: 144px !important;
}

.m-145 {
  margin: 145px !important;
}

.m-l-145 {
  margin-left: 145px !important;
}

.m-t-145 {
  margin-top: 145px !important;
}

.m-r-145 {
  margin-right: 145px !important;
}

.m-b-145 {
  margin-bottom: 145px !important;
}

.m-h-145 {
  margin-left: 145px !important;
  margin-right: 145px !important;
}

.m-v-145 {
  margin-top: 145px !important;
  margin-bottom: 145px !important;
}

.p-145 {
  padding: 145px !important;
}

.p-l-145 {
  padding-left: 145px !important;
}

.p-t-145 {
  padding-top: 145px !important;
}

.p-r-145 {
  padding-right: 145px !important;
}

.p-b-145 {
  padding-bottom: 145px !important;
}

.p-h-145 {
  padding-left: 145px !important;
  padding-right: 145px !important;
}

.p-v-145 {
  padding-top: 145px !important;
  padding-bottom: 145px !important;
}

.m-146 {
  margin: 146px !important;
}

.m-l-146 {
  margin-left: 146px !important;
}

.m-t-146 {
  margin-top: 146px !important;
}

.m-r-146 {
  margin-right: 146px !important;
}

.m-b-146 {
  margin-bottom: 146px !important;
}

.m-h-146 {
  margin-left: 146px !important;
  margin-right: 146px !important;
}

.m-v-146 {
  margin-top: 146px !important;
  margin-bottom: 146px !important;
}

.p-146 {
  padding: 146px !important;
}

.p-l-146 {
  padding-left: 146px !important;
}

.p-t-146 {
  padding-top: 146px !important;
}

.p-r-146 {
  padding-right: 146px !important;
}

.p-b-146 {
  padding-bottom: 146px !important;
}

.p-h-146 {
  padding-left: 146px !important;
  padding-right: 146px !important;
}

.p-v-146 {
  padding-top: 146px !important;
  padding-bottom: 146px !important;
}

.m-147 {
  margin: 147px !important;
}

.m-l-147 {
  margin-left: 147px !important;
}

.m-t-147 {
  margin-top: 147px !important;
}

.m-r-147 {
  margin-right: 147px !important;
}

.m-b-147 {
  margin-bottom: 147px !important;
}

.m-h-147 {
  margin-left: 147px !important;
  margin-right: 147px !important;
}

.m-v-147 {
  margin-top: 147px !important;
  margin-bottom: 147px !important;
}

.p-147 {
  padding: 147px !important;
}

.p-l-147 {
  padding-left: 147px !important;
}

.p-t-147 {
  padding-top: 147px !important;
}

.p-r-147 {
  padding-right: 147px !important;
}

.p-b-147 {
  padding-bottom: 147px !important;
}

.p-h-147 {
  padding-left: 147px !important;
  padding-right: 147px !important;
}

.p-v-147 {
  padding-top: 147px !important;
  padding-bottom: 147px !important;
}

.m-148 {
  margin: 148px !important;
}

.m-l-148 {
  margin-left: 148px !important;
}

.m-t-148 {
  margin-top: 148px !important;
}

.m-r-148 {
  margin-right: 148px !important;
}

.m-b-148 {
  margin-bottom: 148px !important;
}

.m-h-148 {
  margin-left: 148px !important;
  margin-right: 148px !important;
}

.m-v-148 {
  margin-top: 148px !important;
  margin-bottom: 148px !important;
}

.p-148 {
  padding: 148px !important;
}

.p-l-148 {
  padding-left: 148px !important;
}

.p-t-148 {
  padding-top: 148px !important;
}

.p-r-148 {
  padding-right: 148px !important;
}

.p-b-148 {
  padding-bottom: 148px !important;
}

.p-h-148 {
  padding-left: 148px !important;
  padding-right: 148px !important;
}

.p-v-148 {
  padding-top: 148px !important;
  padding-bottom: 148px !important;
}

.m-149 {
  margin: 149px !important;
}

.m-l-149 {
  margin-left: 149px !important;
}

.m-t-149 {
  margin-top: 149px !important;
}

.m-r-149 {
  margin-right: 149px !important;
}

.m-b-149 {
  margin-bottom: 149px !important;
}

.m-h-149 {
  margin-left: 149px !important;
  margin-right: 149px !important;
}

.m-v-149 {
  margin-top: 149px !important;
  margin-bottom: 149px !important;
}

.p-149 {
  padding: 149px !important;
}

.p-l-149 {
  padding-left: 149px !important;
}

.p-t-149 {
  padding-top: 149px !important;
}

.p-r-149 {
  padding-right: 149px !important;
}

.p-b-149 {
  padding-bottom: 149px !important;
}

.p-h-149 {
  padding-left: 149px !important;
  padding-right: 149px !important;
}

.p-v-149 {
  padding-top: 149px !important;
  padding-bottom: 149px !important;
}

.m-150 {
  margin: 150px !important;
}

.m-l-150 {
  margin-left: 150px !important;
}

.m-t-150 {
  margin-top: 150px !important;
}

.m-r-150 {
  margin-right: 150px !important;
}

.m-b-150 {
  margin-bottom: 150px !important;
}

.m-h-150 {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

.m-v-150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.p-150 {
  padding: 150px !important;
}

.p-l-150 {
  padding-left: 150px !important;
}

.p-t-150 {
  padding-top: 150px !important;
}

.p-r-150 {
  padding-right: 150px !important;
}

.p-b-150 {
  padding-bottom: 150px !important;
}

.p-h-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.p-v-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

html, body {
  min-height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

body {
  margin: 0;
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: -0.02em;
  -ms-overflow-style: none;
  overflow: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

section {
  padding: 25px 0;
  max-width: 1070px;
  min-height: calc(100vh - 200px);
  left: 0;
  right: 0;
  margin: auto;
}

@media screen and (max-width: 959px) {
  section {
    padding: 25px 0;
    min-height: calc(100vh - 225px);
  }
}
.wide-section {
  max-width: 1200px;
}

.narrow-section {
  max-width: 1100px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 25px 0px;
}

@media screen and (max-width: 959px) {
  .narrow-section {
    max-width: 1000px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #ffffff;
    padding: 15px 0 !important;
  }
}
.wide-section {
  max-width: 1150px;
  left: 0;
  right: 0;
  margin: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@media screen and (max-width: 959px) {
  .wide-section {
    left: 0;
    right: 0;
    margin: auto;
    background-color: #ffffff;
    padding: 10px 0 !important;
  }
}
.none-padding-section {
  max-width: 900px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 !important;
}

.banner {
  min-height: 60px !important;
}

.banner-xs {
  min-height: 20px !important;
}

.header {
  background: #fff;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33% !important;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.m-w-170 {
  min-width: 170px;
}

.m-h-190 {
  min-height: 190px;
}

.bg-smoke {
  background: whitesmoke !important;
}

.bg-white {
  background: white !important;
}

.bg-gray-fa {
  background-color: #fafafa !important;
}

.bg-gray-ea {
  background-color: #eaeaea !important;
}

.bg-gray-da {
  background-color: #dadada !important;
}

.bg-gray-ca {
  background-color: #cacaca !important;
}

.bg-gray-ba {
  background-color: #bababa !important;
}

.bg-gray-light {
  background-color: #f6f7f7 !important;
  color: #333333 !important;
}

.bg-gray {
  color: white !important;
  background-color: #999999 !important;
}

.bg-gray-deep {
  color: white !important;
  background-color: #666666 !important;
}

.bg-gray-dark {
  color: white !important;
  background-color: #212121 !important;
}

.bg-gray-33 {
  color: white;
  background-color: #333333;
}

.bg-primary-light {
  background: #08C18A !important;
  color: white !important;
}

.bg-primary-darker {
  background: #097F52 !important;
  color: white !important;
}

.bg-primary {
  background: #0FA564 !important;
  color: white !important;
}

.bg-accent {
  background: #FFD54F !important;
  color: #212121;
}

.bg-accent-light {
  background: #FFE07F !important;
  color: #212121 !important;
}

.bg-accent-darker {
  background: #E8C248 !important;
  color: #212121;
}

.bg-warn-light {
  background-color: #f2b3b9 !important;
}

.bg-warn {
  background-color: #F46A70 !important;
}

.bg-pink {
  background-color: #DB5291;
}

.bg-mint {
  background-color: #4bcfd0 !important;
  color: white !important;
}

.bg-mint-light {
  background-color: #33cccc !important;
  color: white !important;
}

.c-mint {
  color: #40bfc0 !important;
}

.bg-mint-dark {
  background-color: #14b2ae !important;
  color: white !important;
}

.c-mint-dark {
  color: #14b2ae !important;
}

.bg-p-coralparadise {
  background-color: #f05e73 !important;
}

.bg-p-flamingoplume {
  background-color: #de838d !important;
}

.bg-p-murexshell {
  background-color: #f8a3a4 !important;
}

.bg-p-blushingrose {
  background-color: #f2c1ac !important;
}

.bg-p-paledogwood {
  background-color: #efd1c6 !important;
}

.bg-p-graysand {
  background-color: #e5ccaf !important;
}

.bg-p-graylilac {
  background-color: #d4cacd !important;
}

.bg-p-agategray {
  background-color: #b1b09f !important;
}

.bg-p-pleinair {
  background-color: #bfcad6 !important;
}

.bg-p-09-mimosa {
  background-color: #f0c05a !important;
}

.bg-p-10-turquoise {
  background-color: #45b5aa !important;
}

.bg-p-11-honeysuckle {
  background-color: #d85a7b !important;
}

.bg-p-12-tangerinetango {
  background-color: #dd4124 !important;
}

.bg-p-13-emerald {
  background-color: #00997b !important;
}

.bg-p-14-radiantorchid {
  background-color: #ad5e99 !important;
}

.bg-p-15-marsala {
  background-color: #964f4c !important;
}

.bg-p-16-quartz {
  background-color: #f7caca !important;
}

.bg-p-16-serenity {
  background-color: #93a9d1 !important;
}

.bg-p-17-greenery {
  background-color: #88b04b !important;
}

.bg-p-18-ultraviolet {
  background-color: #5f4b8b !important;
}

.bg-p-19-livingcoral {
  background-color: #ff6f61 !important;
}

.c-p-19-livingcoral {
  color: #ff6f61 !important;
}

.bg-p-20-classicblue {
  background-color: #0f4c81 !important;
}

.bg-p-21-llluminatiing {
  background-color: #f5df4d !important;
}

.bg-p-22-veryperi {
  background-color: #6667ab !important;
}

.bg-p-23-vivamagent {
  background-color: #e24666 !important;
}

.c-p-23-vivamagent {
  color: #e24666 !important;
}

.bg-white-blur {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.bg-white-shadow {
  background: #ffffff;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid inherit;
}

.bg-blue-light {
  background: #F8FCFF;
}

.bg-sheet {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.bg-gn-light {
  background: #e6f6ef !important;
}

.bg-empty {
  background-color: #FFFFFF !important;
  border-radius: 20px !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-start {
  text-align: start !important;
}

.va-middle {
  vertical-align: middle !important;
}

.align-top {
  vertical-align: top;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.f-400 {
  font-weight: 400 !important;
}

.f-500 {
  font-weight: 500 !important;
}

.f-600 {
  font-weight: 600 !important;
}

.f-700 {
  font-weight: 700 !important;
}

.f-800 {
  font-weight: 800 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.c-primary-light {
  color: #08C18A !important;
}

.c-primary {
  color: #0FA564 !important;
}

.c-primary-darker {
  color: #097F52 !important;
}

.c-accent-light {
  color: #FFE07F;
}

.c-accent {
  color: #FFD54F;
}

.c-accent-darker {
  color: #E8C248;
}

.c-warn-light {
  color: #f2b3b9 !important;
}

.c-warn {
  color: #F46A70 !important;
}

.c-gray-light {
  color: #f6f7f7 !important;
}

.c-gray {
  color: #999999 !important;
}

.c-gray-deep {
  color: #666666 !important;
}

.c-gray-dark {
  color: #212121 !important;
}

.c-gray-ca {
  color: #cacaca !important;
}

.c-gray-da {
  color: #dadada !important;
}

.c-gray-ea {
  color: #eaeaea !important;
}

.c-white {
  color: white !important;
}

.c-mint {
  color: #25ccbe !important;
}

.c-mint-dark {
  color: #14b2ae !important;
}

.c-black {
  color: #212121 !important;
}

.c-dark {
  color: #000000;
}

.c-gn-light {
  color: #E7F2EE !important;
}

.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 0.5em !important;
  -webkit-transform: scale(0.7) !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-25 {
  font-size: 25px !important;
}

.f-30 {
  font-size: 30px !important;
}

.f-35 {
  font-size: 35px !important;
}

.f-40 {
  font-size: 40px !important;
}

.f-50 {
  font-size: 50px !important;
}

.f-60 {
  font-size: 60px;
}

.f-70 {
  font-size: 70px !important;
}

.f-80 {
  font-size: 80px !important;
}

.f-90 {
  font-size: 90px;
}

.f-100 {
  font-size: 100px;
}

.f-110 {
  font-size: 110px;
}

.f-120 {
  font-size: 120px;
}

.f-130 {
  font-size: 130px;
}

.lh-0 {
  line-height: 0 !important;
}

.lh-1-4 {
  line-height: 1.4 !important;
}

.lh-1-5 {
  line-height: 1.5 !important;
}

.lh-1-6 {
  line-height: 1.6 !important;
}

.lh-1-7 {
  line-height: 1.7 !important;
}

.lh-1-8 {
  line-height: 1.8 !important;
}

.lh-22 {
  line-height: 22px !important;
}

.number-circle {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background: #dadada;
  color: #555555;
  text-align: center;
  font-size: 12px;
}

.number-circle-subhead {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  display: inline-block;
  background: #08C18A;
  color: white;
  text-align: center;
  font-size: 12px;
  margin: 0 7px 10px 0;
}

.text-circle {
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
  line-height: 50px !important;
  border-radius: 50%;
  text-align: center;
}

.text-circle-mini {
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
  line-height: 50px !important;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
}

.text-circle-40 {
  display: inline-block;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px !important;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.text-circle-35 {
  display: inline-block;
  min-width: 35px;
  min-height: 35px;
  line-height: 35px !important;
  border-radius: 50%;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
}

.text-circle-32 {
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  text-align: center;
}

.text-circle-30 {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  text-align: center;
}

.text-circle-20 {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  text-align: center;
}

.text-circle-60 {
  display: inline-block;
  min-width: 60px;
  min-height: 60px;
  line-height: 60px !important;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
}

.text-circle-70 {
  display: inline-block;
  min-width: 70px;
  min-height: 70px;
  line-height: 70px !important;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
}

.text-badge {
  width: fit-content;
  border: 1px solid #f6f7f7;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.text-badge-gray {
  width: fit-content;
  border: 1px solid #999999;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.text-badge-warn-s {
  width: fit-content;
  border: 1px solid #F46A70;
  border-radius: 20px;
  padding: 3px 10px 5px 10px !important;
  font-size: 11px !important;
  font-weight: 600;
  line-height: 11px !important;
  color: #F46A70;
}

.text-badge-warn-fill {
  width: fit-content;
  background-color: #F46A70;
  border-radius: 20px;
  padding: 4px 10px 6px 10px !important;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 12px !important;
  color: #ffffff;
}

.text-badge-white {
  width: fit-content;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 15px;
  padding: 3px 8px !important;
  font-size: 12px;
  line-height: 12px !important;
  font-weight: 500;
}

.badge {
  background-color: #FFE07F;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 11px !important;
  line-height: 11px !important;
  font-weight: 500;
  color: #212121;
}

.badge-title {
  background-color: #097F52;
  color: #ffffff;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.badge-kr {
  margin-right: 5px;
  padding: 3px 10px 5px 10px !important;
  border-radius: 20px;
  font-size: 12px !important;
  line-height: 13px !important;
  font-weight: 500;
  text-align: center;
}

.badge-kr-md {
  min-width: 30px;
  padding: 6px 10px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}

.underline {
  line-height: 1.2;
  background-image: linear-gradient(transparent calc(100% - 3px), #212121 3px);
  background-repeat: no-repeat;
  transition: background-size 0.8s;
  color: #212121;
}

.underline-slim {
  line-height: 1.2;
  background-image: linear-gradient(transparent calc(100% - 3px), #212121 3px);
  background-repeat: no-repeat;
  transition: background-size 0.8s;
  color: inherit;
}

.underline {
  background-size: 100% 90%;
}

.underline-slim {
  background-size: 100% 110%;
}

.underline.primary-light {
  background-image: linear-gradient(transparent 70%, #08C18A 70%);
}

.underline.primary {
  background-image: linear-gradient(transparent 70%, #08C18A 20%);
}

.underline.accent {
  background-image: linear-gradient(transparent 70%, #FFE07F 20%);
}

.underline.mint {
  background-image: linear-gradient(transparent 70%, #8CD8D9 20%);
}

.underline.green {
  background-image: linear-gradient(transparent 70%, #85d58f 30%);
}

.underline.warn {
  background-image: linear-gradient(transparent 70%, #f2b3b9 20%);
}

.underline.gray {
  background-image: linear-gradient(transparent 70%, #dadada 20%);
}

.underline.blue {
  background-image: linear-gradient(transparent 70%, #89afee 20%);
}

.underline.white {
  background-image: linear-gradient(transparent 70%, #ffffff 20%);
}

.underline-slim.blue {
  background-image: linear-gradient(transparent 85%, #89afee 20%);
}

.underline-slim.primary {
  background-image: linear-gradient(transparent 85%, #08C18A 20%);
}

.underline-slim.accent {
  background-image: linear-gradient(transparent 85%, #FFD54F 20%);
}

.underline-slim.green {
  background-image: linear-gradient(transparent 85%, #85d58f 20%);
}

.underline-slim.gray {
  background-image: linear-gradient(transparent 85%, #dadada 20%);
}

.underline-slim.warn {
  background-image: linear-gradient(transparent 85%, #f2b3b9 20%);
}

.underline-slim.white {
  background-image: linear-gradient(transparent 85%, #ffffff 20%);
}

.number {
  letter-spacing: 1px;
  font-size: 14px;
}

.text-link {
  text-decoration: underline;
  color: black;
  cursor: pointer;
  font-weight: 600;
}

.text-pre {
  white-space: pre-line;
  line-height: 1.7;
}

.text-loss-100 {
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-loss-57 {
  width: 57px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

button {
  box-shadow: none !important;
  font-weight: 500;
}

button:not(.mat-fab):not(.mat-menu-item):not(.swal2-styled):not(.button-mini):not(.mat-mini-fab):not(.mat-icon-button) {
  border-radius: 30px;
  line-height: 25px;
}

.inline-button {
  display: inline !important;
}

.button-mini {
  border-radius: 20px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  padding: 9px 20px !important;
  color: #333333;
  border: 1px solid #dadada;
}

.button-mini-icon {
  border-radius: 20px !important;
  font-size: 13px !important;
  line-height: 13px !important;
  padding: 6px 15px !important;
  color: #333333;
  border: 1px solid #dadada;
}

.button-mini-noborder {
  border-radius: 20px !important;
  font-size: 12px;
  line-height: 13px !important;
  padding: 6px 6px;
  color: #212121;
}

.button-mini-w {
  border-radius: 20px !important;
  font-size: 13px !important;
  line-height: 13px !important;
  padding: 6px 8px !important;
  background-color: inherit !important;
  font-weight: 500;
  color: #444444;
}

.button-category-on {
  border-radius: 20px !important;
  border: 1px solid #097F52 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  background-color: inherit;
  padding: 2px 8px !important;
  color: #097F52 !important;
  margin-bottom: 5px !important;
}

.button-category-off {
  border-radius: 20px !important;
  border: 1px solid #999999 !important;
  font-size: 12px !important;
  line-height: 12px !important;
  background-color: inherit;
  padding: 6px 8px !important;
  color: #999999 !important;
  margin-bottom: 5px !important;
}

.button-mini-n {
  border-radius: 20px !important;
  font-size: 13px !important;
  line-height: 13px !important;
  background-color: inherit;
  padding: 6px 10px;
  padding-bottom: 5px !important;
}

.button-md {
  border-radius: 20px !important;
  font-size: 13px !important;
  padding: 8px 13px;
  line-height: 13px;
  box-shadow: none !important;
}

.button-md-white {
  border-radius: 10px !important;
  font-size: 13px !important;
  padding: 8px 13px;
  line-height: 13px;
  border: 1px solid #ffffff !important;
}

.button-lg {
  padding: 15px 0px !important;
  border-radius: 20px !important;
  font-size: 14px;
  font-weight: 600 !important;
}

.button-14 {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.button-suffix {
  height: 32px;
  margin-top: 10px !important;
  font-size: 13px !important;
  border: 1px solid #999999 !important;
  color: #999999;
  line-height: 30px !important;
}

.button-suffix-primary {
  height: 32px;
  margin-top: 10px !important;
  font-size: 13px !important;
  border: 1px solid #097F52 !important;
  color: #097F52;
  line-height: 32px !important;
}

.cancel-chip {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #F46A70;
  background-color: #F46A70;
  color: #FFFFFF;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.now-change {
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid #FFD54F;
  background-color: #FFD54F;
  color: #212121;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.now-change-guide {
  min-width: 35px;
  padding: 7px 12px 8px 12px;
  border-radius: 20px;
  border: 1px solid #f6f7f7;
  background-color: #f6f7f7;
  color: #212121;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
}

.target-chip {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #097F52;
  color: #097F52;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.target-chip-bg {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #097F52;
  background-color: #097F52;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #212121;
  color: #212121;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip-md {
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid #212121;
  color: #212121;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  text-align: center;
}

.black-chip-bg {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #f6f7f7;
  background-color: #f6f7f7;
  color: #212121;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.accent-chip-bg {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #FFE07F;
  background-color: #FFE07F;
  color: #212121;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.primary-bg-chip {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #0FA564;
  background-color: #0FA564;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip-lg {
  min-width: 35px;
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid #212121;
  color: #212121;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-bg-chip {
  min-width: 35px;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-bg {
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid #777777;
  background-color: #777777;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
}

.gray-chip-bg-shot {
  min-width: 24px;
  padding: 5px 9px 6px 9px;
  border-radius: 20px;
  border: 1px solid #999999;
  background-color: #999999;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
}

.black-chip-xs {
  min-width: 25px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #444444;
  color: #444444;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip-bg-xs {
  min-width: 25px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #999999;
  color: #333333;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip-bg {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid #444444;
  background-color: #444444;
  color: white;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip-bg-lg {
  min-width: 35px;
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid #555555;
  background-color: #555555;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.accent-chip-bg {
  min-width: 35px;
  padding: 6px 15px;
  border-radius: 20px;
  border: 1px solid #FFD54F;
  background-color: #FFD54F;
  color: #212121;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.white-chip {
  min-width: 35px;
  padding: 6px 7px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip {
  width: fit-content;
  min-width: 35px;
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid #666666;
  color: #666666;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-open {
  width: fit-content;
  min-width: 35px;
  padding: 5px 2px 6px 2px;
  border-radius: 20px;
  border: 1px solid #212121;
  color: #212121;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-bg {
  width: fit-content;
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid #eaeaea;
  background-color: #eaeaea;
  color: #444444;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-lg {
  width: fit-content;
  min-width: 35px;
  padding: 5px 9px 6px 9px;
  border-radius: 20px;
  border: 1px solid #666666;
  color: #666666;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.primary-bg-chip {
  min-width: 45px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  background-color: #0FA564;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
}

.send-bg-chip {
  min-width: 45px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: #0FA564;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.receive-bg-chip {
  min-width: 45px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.title-chip-xs {
  width: fit-content;
  min-width: 30px;
  padding: 4px 9px 5px 9px;
  text-align: center;
  border-radius: 20px;
  background-color: #097F52;
  color: #ffffff;
  font-size: 11px !important;
  line-height: 11px !important;
  font-weight: 600;
}

.title-chip-xs-auto {
  width: fit-content;
  min-width: 30px;
  padding: 4px 9px 5px 9px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #0FA564;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
}

.title-chip {
  width: fit-content;
  padding: 8px 40px 8px 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #ffffff;
  color: #444444;
  font-size: 13px !important;
  line-height: 13px;
  font-weight: 600 !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09) !important;
}

.title-chip-round {
  width: fit-content;
  padding: 6px 10px 6px 20px;
  border-radius: 50px;
  background-color: #0FA564;
  color: #ffffff;
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
}

.title-chip-round-info {
  width: fit-content;
  padding: 8px 10px 8px 10px;
  border-radius: 50px;
  background-color: #0FA564;
  color: #ffffff;
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
}

.expert-type {
  padding: 6px 20px;
  border-radius: 15px;
  font-size: 13px;
  font-weight: 600;
  background: #097F52;
  color: white;
  line-height: 13px;
}

.border-inherit {
  border: inherit !important;
}

.circle-step-warn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #F46A70;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 1px solid #F46A70;
}

.circle-step-black {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 1px solid #212121;
}

.circle-step-bg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 14px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  background: #eaeaea;
}

.circle-step-border {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #4283ef;
  text-align: center !important;
  line-height: 60px;
  font-size: 14px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 2px solid #4283ef;
}

.circle-step-bg-lg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center !important;
  line-height: 90px;
  font-size: 15px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  background: #eaeaea;
}

.circle-step-border-lg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  color: #4283ef;
  text-align: center !important;
  line-height: 90px;
  font-size: 15px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 2px solid #4283ef;
}

.cube-step-bg {
  border-radius: 20px;
  color: #212121;
  text-align: center !important;
  font-size: 14px;
  padding: 20px 15px !important;
  font-weight: 600;
  vertical-align: middle !important;
  background: #fafafa;
}

.circle-step-black-dot {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 1px dotted #212121;
}

.icon-circle-wrapper-xs {
  width: 70px;
  border-radius: 20px;
  line-height: 12px;
  padding: 6px 10px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 600;
  text-align: center;
}

.icon-circle-wrapper-xs-primary {
  width: 66px;
  padding: 6px 2px;
  border-radius: 20px;
  line-height: 12px;
  border: 1px solid #097F52;
  color: #097F52;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 600;
  text-align: center;
}

.mat-badge-accent .mat-badge-content {
  background: #FFD54F !important;
  color: #212121 !important;
}

.img-help {
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #212121;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0FA564 !important;
  background-color: #0FA564 !important;
}

.btn-icon-l {
  padding-right: 40%;
  padding-left: 10px;
}

.mdc-button {
  padding: 0 13px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: 1.25em;
}

.separator:not(:empty)::after {
  margin-left: 1.25em;
}

.border-e2 {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}

.border-bd {
  border: 1px solid #BDBDBD;
  border-radius: 4px;
}

.border-9e {
  border: 1px solid #9e9e9e;
  border-radius: 4px;
}

.border-gray {
  border: 1px solid #999999;
  border-radius: 4px;
}

.border-primary {
  border: 1px solid #0FA564;
  border-radius: 4px;
}

.border-warn {
  border: 1px solid #F46A70;
  border-radius: 4px;
}

.border-h-ea {
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}

.border-h-r {
  border-right: 1px solid #eaeaea;
}

.border-h-r-black {
  border-right: 1px dotted #555555;
}

.border-t {
  border-top: 1px solid #999999;
}

.border-v {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.border-v-primary {
  border-top: 1px solid #0FA564;
  border-bottom: 1px solid #0FA564;
}

.border-b-primary {
  border-bottom: 1px solid #0FA564;
}

.border-b-gray {
  border-bottom: 1px solid #999999;
}

.border-t-gray {
  border-top: 1px solid #999999;
}

.border-title {
  border-bottom: 1px solid #212121;
  padding-bottom: 5px;
}

.border-dashed-7 {
  border: 1px dashed #777777;
  border-radius: 4px;
}

.border-dashed-9 {
  border: 1px dashed #999999;
  border-radius: 4px;
}

.border-b-dashed {
  border-bottom: 1px dashed #777777;
  padding-bottom: 5px;
}

.border-b-dashed-gray {
  border-bottom: 1px dashed #999999;
  padding-bottom: 5px;
}

.border-b-e2 {
  border-bottom: 1px solid #e2e2e2;
}

.border-t-dashed {
  border-top: 1px dashed #777777;
}

.border-shadow {
  background: white;
  box-sizing: border-box;
  opacity: 1;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
  border-radius: 40px;
}

.border-shadow-light {
  background: white;
  box-sizing: border-box;
  opacity: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
}

.border-b-shadow {
  background: white;
  box-sizing: border-box;
  opacity: 1;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
}

.border-left-dotted {
  border-left: 1px dotted #212121;
}

.border-left-solid {
  border-left: 1px solid #212121;
}

.border-t-radius-20 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.border-t-radius-40 {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.border-none {
  border: none !important;
}

.brd-5 {
  border-radius: 5px;
}

.brd-10 {
  border-radius: 10px;
}

.brd-15 {
  border-radius: 15px;
}

.brd-20 {
  border-radius: 20px;
}

.brd-40 {
  border-radius: 40px;
}

.brd-50 {
  border-radius: 50px;
}

.shadow-box {
  box-shadow: 10px 10px 10px #ccc;
}

.shadow-box-xs {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.shadow-box-xxs {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
}

.cursor {
  cursor: pointer;
}

:focus {
  outline: none;
}

.hover:hover {
  background: inherit !important;
  font-weight: 700;
}

.hover-primary:hover {
  background: #097F52 !important;
  color: #ffffff !important;
}

.hover-gray-deep:hover {
  background: #666666 !important;
  color: #ffffff !important;
}

.hover-dark:hover {
  background: #333333 !important;
  color: #ffffff !important;
}

.hover-gray:hover {
  background: #f2f3f4 !important;
  cursor: pointer;
  border: 1px solid #f2f3f4;
}

.hover-gray-fa:hover {
  background: #fafafa !important;
  cursor: pointer;
}

.hover-white:hover {
  background: #ffffff !important;
  color: #097F52 !important;
}

.hover-primary-line:hover {
  background: #ffffff !important;
  color: #097F52 !important;
  border: 1px solid #097F52 !important;
}

.hover-gray-shadow:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
}

input.text-right::-webkit-outer-spin-button,
input.text-right::-webkit-inner-spin-button {
  display: none !important;
}

input.text-right {
  -moz-appearance: textfield !important;
}

.mat-mdc-form-field-icon-suffix {
  padding: 0 5px 0 4px !important;
}

.mat-mdc-input-element {
  font-weight: 600;
}

@font-face {
  font-family: "yg-jalnan";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "S-CoreDream-9Black";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-9Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "S-CoreDream-8Heavy";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-8Heavy.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "S-CoreDream-7ExtraBold";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-7ExtraBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "S-CoreDream-6Bold";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-6Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "S-CoreDream-5Medium";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-5Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "S-CoreDream-4Regular";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "S-CoreDream-3Light";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "S-CoreDream-2ExtraLight";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-2ExtraLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "S-CoreDream-1Thin";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-1Thin.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NEXON Lv1 Gothic OTF Light";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv1 Gothic OTF Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NEXON Lv1 Gothic OTF";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv1 Gothic OTF.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NEXON Lv1 Gothic OTF Bold";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv1 Gothic OTF Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.ff-nexon-light {
  font-family: "NEXON Lv1 Gothic OTF Light", serif !important;
}

.ff-nexon-regular {
  font-family: "NEXON Lv1 Gothic OTF", serif !important;
}

.ff-nexon-bold {
  font-family: "NEXON Lv1 Gothic OTF Bold", serif !important;
}

.ff-sc-9 {
  font-family: "S-CoreDream-9Black", serif !important;
}

.ff-sc-8 {
  font-family: "S-CoreDream-8Heavy", serif !important;
}

.ff-sc-7 {
  font-family: "S-CoreDream-7ExtraBold", serif !important;
}

.ff-sc-6 {
  font-family: "S-CoreDream-6Bold", serif !important;
}

.ff-sc-5 {
  font-family: "S-CoreDream-5Medium", serif !important;
}

.ff-sc-4 {
  font-family: "S-CoreDream-4Regular", serif !important;
}

.ff-sc-3 {
  font-family: "S-CoreDream-3Light", serif !important;
}

.ff-sc-2 {
  font-family: "S-CoreDream-2ExtraLight", serif !important;
}

.ff-sc-1 {
  font-family: "S-CoreDream-1Thin", serif !important;
}

.ff-jalnan {
  font-family: "yg-jalnan", serif !important;
  letter-spacing: 1px !important;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-symbols-outlined {
  vertical-align: middle !important;
}

.img-circle-25 {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.img-circle-30 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.img-circle-40 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.img-circle-50 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.img-circle-60 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.img-circle-80 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.img-circle-80 {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.img-circle-100 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.img-circle-120 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.img-circle-150 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.img-cube-40 {
  width: 40px;
  height: 40px;
  border-radius: 5%;
}

.img-cube-60 {
  width: 60px;
  height: 60px;
  border-radius: 5%;
  background-color: #f2f3f4;
}

.img-cube-80 {
  width: 80px;
  height: 80px;
  border-radius: 5%;
}

.img-size-14 {
  height: 14px;
  width: auto;
}

.img-size-15 {
  height: 15px;
  width: auto;
}

.img-size-16 {
  height: 16px;
  width: auto;
}

.img-size-20 {
  height: 20px;
  width: auto;
}

.img-size-30 {
  height: 30px !important;
  width: auto;
}

.img-size-35 {
  height: 35px;
  width: auto;
}

.img-size-38 {
  height: 38px;
  width: auto;
}

.img-size-40 {
  height: 40px;
  width: auto;
}

.img-size-45 {
  height: 45px;
  width: auto;
}

.img-size-50 {
  height: 50px;
  width: auto;
}

.img-size-55 {
  height: 55px;
  width: auto;
}

.img-size-60 {
  height: 60px;
  width: auto;
}

.img-size-65 {
  height: 65px;
  width: auto;
}

.img-size-70 {
  height: 70px;
  width: auto;
}

.img-size-80 {
  height: 80px;
  width: auto;
}

.img-size-85 {
  height: 85px;
  width: auto;
}

.img-size-90 {
  height: 90px;
  width: auto;
}

.img-size-95 {
  height: 95px;
  width: auto;
}

.img-size-100 {
  height: 100px;
  width: auto;
}

.img-size-103 {
  height: 103px;
  width: auto;
}

.img-size-105 {
  height: 105px;
  width: auto;
}

.img-size-108 {
  height: 108px;
  width: auto;
}

.img-size-110 {
  height: 110px;
  width: auto;
}

.img-size-115 {
  height: 115px;
  width: auto;
}

.img-size-117 {
  height: 117px;
  width: auto;
}

.img-size-120 {
  height: 120px;
  width: auto;
}

.img-size-130 {
  height: 130px;
  width: auto;
}

.img-size-133 {
  height: 133px;
  width: auto;
}

.img-size-134 {
  height: 134px;
  width: auto;
}

.img-size-135 {
  height: 135px;
  width: auto;
}

.img-size-138 {
  height: 138px;
  width: auto;
}

.img-size-140 {
  height: 140px;
  width: auto;
}

.img-size-150 {
  height: 150px;
  width: auto;
}

.img-size-155 {
  height: 155px;
  width: auto;
}

.img-size-160 {
  height: 160px;
  width: auto;
}

.img-size-165 {
  height: 165px;
  width: auto;
}

.img-size-170 {
  height: 170px;
  width: auto;
}

.img-size-171 {
  height: 171px;
  width: auto;
}

.img-size-172 {
  height: 172px;
  width: auto;
}

.img-size-173 {
  height: 173px;
  width: auto;
}

.img-size-180 {
  height: 180px;
  width: auto;
}

.img-size-185 {
  height: 185px;
  width: auto;
}

.img-size-190 {
  height: 190px;
  width: auto;
}

.img-size-200 {
  height: 200px;
  width: auto;
}

.img-size-205 {
  height: 205px;
  width: auto;
}

.img-size-207 {
  height: 207px;
  width: auto;
}

.img-size-210 {
  height: 210px;
  width: auto;
}

.img-size-212 {
  height: 212px;
  width: auto;
}

.img-size-220 {
  height: 220px;
  width: auto;
}

.img-size-223 {
  height: 223px;
  width: auto;
}

.img-size-225 {
  height: 225px;
  width: auto;
}

.img-size-230 {
  height: 230px;
  width: auto;
}

.img-size-232 {
  height: 232px;
  width: auto;
}

.img-size-240 {
  height: 240px;
  width: auto;
}

.img-size-242 {
  height: 242px;
  width: auto;
}

.img-size-245 {
  height: 245px;
  width: auto;
}

.img-size-248 {
  height: 248px;
  width: auto;
}

.img-size-250 {
  height: 250px;
  width: auto;
}

.img-size-260 {
  height: 260px;
  width: auto;
}

.img-size-265 {
  height: 265px;
  width: auto;
}

.img-size-267 {
  height: 267px;
  width: auto;
}

.img-size-270 {
  height: 270px;
  width: auto;
}

.img-size-273 {
  height: 273px;
  width: auto;
}

.img-size-275 {
  height: 275px;
  width: auto;
}

.img-size-280 {
  height: 280px;
  width: auto;
}

.img-size-290 {
  height: 290px;
  width: auto;
}

.img-size-295 {
  height: 295px;
  width: auto;
}

.img-size-300 {
  height: 300px;
  width: auto;
}

.img-size-302 {
  height: 302px;
  width: auto;
}

.img-size-305 {
  height: 305px;
  width: auto;
}

.img-size-310 {
  height: 310px;
  width: auto;
}

.img-size-315 {
  height: 315px;
  width: auto;
}

.img-size-320 {
  height: 320px;
  width: auto;
}

.img-size-321 {
  height: 321px;
  width: auto;
}

.img-size-323 {
  height: 323px;
  width: auto;
}

.img-size-325 {
  height: 325px;
  width: auto;
}

.img-size-330 {
  height: 330px;
  width: auto;
}

.img-size-332 {
  height: 332px;
  width: auto;
}

.img-size-335 {
  height: 335px;
  width: auto;
}

.img-size-340 {
  height: 340px;
  width: auto;
}

.img-size-345 {
  height: 345px;
  width: auto;
}

.img-size-350 {
  height: 350px;
  width: auto;
}

.img-size-353 {
  height: 353px;
  width: auto;
}

.img-size-360 {
  height: 360px;
  width: auto;
}

.img-size-365 {
  height: 365px;
  width: auto;
}

.img-size-367 {
  height: 367px;
  width: auto;
}

.img-size-370 {
  height: 370px;
  width: auto;
}

.img-size-373 {
  height: 373px;
  width: auto;
}

.img-size-375 {
  height: 375px;
  width: auto;
}

.img-size-380 {
  height: 380px;
  width: auto;
}

.img-size-385 {
  height: 385px;
  width: auto;
}

.img-size-390 {
  height: 390px;
  width: auto;
}

.img-size-392 {
  height: 392px;
  width: auto;
}

.img-size-393 {
  height: 393px;
  width: auto;
}

.img-size-395 {
  height: 395px;
  width: auto;
}

.img-size-400 {
  height: 400px;
  width: auto;
}

.img-size-405 {
  height: 405px;
  width: auto;
}

.img-size-410 {
  height: 410px;
  width: auto;
}

.img-size-417 {
  height: 417px;
  width: auto;
}

.img-size-420 {
  height: 420px;
  width: auto;
}

.img-size-425 {
  height: 425px;
  width: auto;
}

.img-size-430 {
  height: 430px;
  width: auto;
}

.img-size-432 {
  height: 432px;
  width: auto;
}

.img-size-435 {
  height: 435px;
  width: auto;
}

.img-size-440 {
  height: 440px;
  width: auto;
}

.img-size-445 {
  height: 445px;
  width: auto;
}

.img-size-450 {
  height: 450px;
  width: auto;
}

.img-size-460 {
  height: 460px;
  width: auto;
}

.img-size-465 {
  height: 465px;
  width: auto;
}

.img-size-470 {
  height: 470px;
  width: auto;
}

.img-size-475 {
  height: 475px;
  width: auto;
}

.img-size-478 {
  height: 478px;
  width: auto;
}

.img-size-480 {
  height: 480px;
  width: auto;
}

.img-size-486 {
  height: 486px;
  width: auto;
}

.img-size-495 {
  height: 495px;
  width: auto;
}

.img-size-500 {
  height: 500px;
  width: auto;
}

.img-size-505 {
  height: 505px;
  width: auto;
}

.img-size-508 {
  height: 508px;
  width: auto;
}

.img-size-510 {
  height: 510px;
  width: auto;
}

.img-size-520 {
  height: 520px;
  width: auto;
}

.img-size-530 {
  height: 530px;
  width: auto;
}

.img-size-533 {
  height: 533px;
  width: auto;
}

.img-size-540 {
  height: 540px;
  width: auto;
}

.img-size-543 {
  height: 543px;
  width: auto;
}

.img-size-545 {
  height: 545px;
  width: auto;
}

.img-size-548 {
  height: 548px;
  width: auto;
}

.img-size-565 {
  height: 565px;
  width: auto;
}

.img-size-570 {
  height: 570px;
  width: auto;
}

.img-size-571 {
  height: 571px;
  width: auto;
}

.img-size-575 {
  height: 575px;
  width: auto;
}

.img-size-580 {
  height: 580px;
  width: auto;
}

.img-size-587 {
  height: 587px;
  width: auto;
}

.img-size-590 {
  height: 590px;
  width: auto;
}

.img-size-605 {
  height: 605px;
  width: auto;
}

.img-size-610 {
  height: 610px;
  width: auto;
}

.img-size-615 {
  height: 615px;
  width: auto;
}

.img-size-625 {
  height: 625px;
  width: auto;
}

.img-size-631 {
  height: 631px;
  width: auto;
}

.img-size-640 {
  height: 640px;
  width: auto;
}

.img-size-660 {
  height: 660px;
  width: auto;
}

.img-size-662 {
  height: 662px;
  width: auto;
}

.img-size-690 {
  height: 690px;
  width: auto;
}

.img-size-735 {
  height: 735px;
  width: auto;
}

.img-size-800 {
  height: 800px;
  width: auto;
}

.img-size-890 {
  height: 890px;
  width: auto;
}

.img-size-35w {
  height: auto;
  width: 35px;
}

.img-size-40w {
  height: auto;
  width: 40px;
}

.img-size-45w {
  height: auto;
  width: 45px;
}

.img-size-50w {
  height: auto;
  width: 50px;
}

.img-size-55w {
  height: auto;
  width: 55px;
}

.img-size-60w {
  height: auto;
  width: 60px;
}

.img-size-65w {
  height: auto;
  width: 65px;
}

.img-size-70w {
  height: auto;
  width: 70px;
}

.img-size-75w {
  height: auto;
  width: 75px;
}

.img-size-80w {
  height: auto;
  width: 80px;
}

.img-size-85w {
  height: auto;
  width: 85px;
}

.img-size-90w {
  height: auto;
  width: 90px;
}

.img-size-95w {
  height: auto;
  width: 95px;
}

.img-size-100w {
  height: auto;
  width: 100px !important;
}

.img-size-110w {
  height: auto;
  width: 110px;
}

.img-size-120w {
  height: auto;
  width: 120px;
}

.img-size-125w {
  height: auto;
  width: 125px;
}

.img-size-130w {
  height: auto;
  width: 130px;
}

.img-size-140w {
  height: auto;
  width: 140px;
}

.img-size-150w {
  height: auto;
  width: 150px;
}

.img-size-40p {
  height: auto;
  width: 40%;
}

.img-size-45p {
  height: auto;
  width: 45%;
}

.img-size-50p {
  height: auto;
  width: 50%;
}

.img-size-60p {
  height: auto;
  width: 60%;
}

.img-size-65p {
  height: auto;
  width: 65%;
}

.img-size-70p {
  height: auto;
  width: 70%;
}

.img-size-75p {
  height: auto;
  width: 75%;
}

.img-size-80p {
  height: auto;
  width: 80%;
}

.img-size-85p {
  height: auto;
  width: 85%;
}

.img-size-90p {
  height: auto;
  width: 90%;
}

.img-size-95p {
  height: auto;
  width: 95%;
}

.img-size-98p {
  height: auto;
  width: 98%;
}

.img-size-100p {
  height: auto;
  width: 100%;
}

.img-size-wh-16 {
  width: 16px;
  height: 16px;
}

.img-size-wh-20 {
  width: 20px;
  height: 20px;
}

.img-size-wh-23 {
  width: 23px;
  height: 23px;
}

.img-size-wh-25 {
  width: 25px;
  height: 25px;
}

.img-size-wh-27 {
  width: 27px;
  height: 27px;
}

.img-size-wh-28 {
  width: 28px;
  height: 28px;
}

.img-size-wh-30 {
  width: 30px;
  height: 30px;
}

.img-size-wh-35 {
  width: 35px;
  height: 35px;
}

.img-size-wh-40 {
  width: 40px;
  height: 40px;
}

.img-size-wh-50 {
  width: 50px;
  height: 50px;
}

.img-size-wh-60 {
  width: 60px;
  height: 60px;
}

.img-size-wh-65 {
  width: 65px;
  height: 65px;
}

.img-size-wh-70 {
  width: 70px;
  height: 70px;
}

.img-size-wh-75 {
  width: 75px;
  height: 75px;
}

.img-size-wh-80 {
  width: 80px;
  height: 80px;
}

.img-size-wh-90 {
  width: 90px;
  height: 90px;
}

.img-size-wh-95 {
  width: 95px;
  height: 95px;
}

.img-size-wh-100 {
  width: 100px;
  height: 100px;
}

.img-size-wh-110 {
  width: 110px;
  height: 110px;
}

.img-size-wh-120 {
  width: 120px;
  height: 120px;
}

.img-size-wh-130 {
  width: 130px;
  height: 130px;
}

.img-size-wh-140 {
  width: 140px;
  height: 130px;
}

.img-size-wh-150 {
  width: 150px;
  height: 150px;
}

.img-size-wh-170 {
  width: 170px;
  height: 170px;
}

.img-size-wh-180 {
  width: 180px;
  height: 180px;
}

.img-size-wh-190 {
  width: 190px;
  height: 190px;
}

.img-size-wh-200 {
  width: 200px;
  height: 200px;
}

.img-size-wh-230 {
  width: 230px;
  height: 230px;
}

.img-size-wh-250 {
  width: 250px;
  height: 250px;
}

.img-size-wh-280 {
  width: 280px;
  height: 280px;
}

.img-size-wh-300 {
  width: 300px;
  height: 300px;
}

.icon-tran {
  transform: rotate(180deg);
}

.icon-tran-scalex {
  transform: scaleX(-1);
}

.img-gray {
  filter: grayscale(1);
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #0fa564;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #ffd54f;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #0fa564;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #0fa564;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ffd54f;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ffd54f;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ffd54f;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ffd54f;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0fa564;
  --mdc-linear-progress-track-color: rgba(15, 165, 100, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(15, 165, 100, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(15, 165, 100, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(15, 165, 100, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(15, 165, 100, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(15, 165, 100, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #ffd54f;
  --mdc-linear-progress-track-color: rgba(255, 213, 79, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 213, 79, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 213, 79, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 213, 79, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 213, 79, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 213, 79, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #0fa564;
  --mdc-filled-text-field-focus-active-indicator-color: #0fa564;
  --mdc-filled-text-field-focus-label-text-color: rgba(15, 165, 100, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #0fa564;
  --mdc-outlined-text-field-focus-outline-color: #0fa564;
  --mdc-outlined-text-field-focus-label-text-color: rgba(15, 165, 100, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(15, 165, 100, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.12;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #ffd54f;
  --mdc-filled-text-field-focus-active-indicator-color: #ffd54f;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 213, 79, 0.87);
  --mdc-outlined-text-field-caret-color: #ffd54f;
  --mdc-outlined-text-field-focus-outline-color: #ffd54f;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 213, 79, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(255, 213, 79, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(15, 165, 100, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 213, 79, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #0fa564;
  --mdc-chip-elevated-disabled-container-color: #0fa564;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color: #ffd54f;
  --mdc-chip-elevated-disabled-container-color: #ffd54f;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mdc-switch {
  --mdc-switch-disabled-handle-opacity: 0.38;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-state-layer-size: 40px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #097f52;
  --mdc-switch-selected-handle-color: #097f52;
  --mdc-switch-selected-hover-state-layer-color: #097f52;
  --mdc-switch-selected-pressed-state-layer-color: #097f52;
  --mdc-switch-selected-focus-handle-color: #005850;
  --mdc-switch-selected-hover-handle-color: #005850;
  --mdc-switch-selected-pressed-handle-color: #005850;
  --mdc-switch-selected-focus-track-color: #08c18a;
  --mdc-switch-selected-hover-track-color: #08c18a;
  --mdc-switch-selected-pressed-track-color: #08c18a;
  --mdc-switch-selected-track-color: #08c18a;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d1af41;
  --mdc-switch-selected-handle-color: #d1af41;
  --mdc-switch-selected-hover-state-layer-color: #d1af41;
  --mdc-switch-selected-pressed-state-layer-color: #d1af41;
  --mdc-switch-selected-focus-handle-color: #ff7000;
  --mdc-switch-selected-hover-handle-color: #ff7000;
  --mdc-switch-selected-pressed-handle-color: #ff7000;
  --mdc-switch-selected-focus-track-color: #ffd54f;
  --mdc-switch-selected-hover-track-color: #ffd54f;
  --mdc-switch-selected-pressed-track-color: #ffd54f;
  --mdc-switch-selected-track-color: #ffd54f;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0fa564;
  --mdc-radio-selected-hover-icon-color: #0fa564;
  --mdc-radio-selected-icon-color: #0fa564;
  --mdc-radio-selected-pressed-icon-color: #0fa564;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #0fa564;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ffd54f;
  --mdc-radio-selected-hover-icon-color: #ffd54f;
  --mdc-radio-selected-icon-color: #ffd54f;
  --mdc-radio-selected-pressed-icon-color: #ffd54f;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ffd54f;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #0fa564;
  --mdc-slider-focus-handle-color: #0fa564;
  --mdc-slider-hover-handle-color: #0fa564;
  --mdc-slider-active-track-color: #0fa564;
  --mdc-slider-inactive-track-color: #0fa564;
  --mdc-slider-with-tick-marks-inactive-container-color: #0fa564;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-mdc-slider-ripple-color: #0fa564;
  --mat-mdc-slider-hover-ripple-color: rgba(15, 165, 100, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(15, 165, 100, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #ffd54f;
  --mdc-slider-focus-handle-color: #ffd54f;
  --mdc-slider-hover-handle-color: #ffd54f;
  --mdc-slider-active-track-color: #ffd54f;
  --mdc-slider-inactive-track-color: #ffd54f;
  --mdc-slider-with-tick-marks-inactive-container-color: #ffd54f;
  --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
  --mat-mdc-slider-ripple-color: #ffd54f;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 213, 79, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 213, 79, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

html {
  --mat-menu-container-shape: 4px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0fa564;
  --mdc-radio-selected-hover-icon-color: #0fa564;
  --mdc-radio-selected-icon-color: #0fa564;
  --mdc-radio-selected-pressed-icon-color: #0fa564;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ffd54f;
  --mdc-radio-selected-hover-icon-color: #ffd54f;
  --mdc-radio-selected-icon-color: #ffd54f;
  --mdc-radio-selected-pressed-icon-color: #ffd54f;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0fa564;
  --mdc-checkbox-selected-hover-icon-color: #0fa564;
  --mdc-checkbox-selected-icon-color: #0fa564;
  --mdc-checkbox-selected-pressed-icon-color: #0fa564;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0fa564;
  --mdc-checkbox-selected-hover-state-layer-color: #0fa564;
  --mdc-checkbox-selected-pressed-state-layer-color: #0fa564;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ffd54f;
  --mdc-checkbox-selected-hover-icon-color: #ffd54f;
  --mdc-checkbox-selected-icon-color: #ffd54f;
  --mdc-checkbox-selected-pressed-icon-color: #ffd54f;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ffd54f;
  --mdc-checkbox-selected-hover-state-layer-color: #ffd54f;
  --mdc-checkbox-selected-pressed-state-layer-color: #ffd54f;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #0fa564;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #0fa564;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #0fa564;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0fa564;
  --mat-tab-header-active-ripple-color: #0fa564;
  --mat-tab-header-inactive-ripple-color: #0fa564;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0fa564;
  --mat-tab-header-active-hover-label-text-color: #0fa564;
  --mat-tab-header-active-focus-indicator-color: #0fa564;
  --mat-tab-header-active-hover-indicator-color: #0fa564;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #ffd54f;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ffd54f;
  --mat-tab-header-active-ripple-color: #ffd54f;
  --mat-tab-header-inactive-ripple-color: #ffd54f;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ffd54f;
  --mat-tab-header-active-hover-label-text-color: #ffd54f;
  --mat-tab-header-active-focus-indicator-color: #ffd54f;
  --mat-tab-header-active-hover-indicator-color: #ffd54f;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #0fa564;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #ffd54f;
  --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ffd54f;
  --mdc-checkbox-selected-hover-icon-color: #ffd54f;
  --mdc-checkbox-selected-icon-color: #ffd54f;
  --mdc-checkbox-selected-pressed-icon-color: #ffd54f;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ffd54f;
  --mdc-checkbox-selected-hover-state-layer-color: #ffd54f;
  --mdc-checkbox-selected-pressed-state-layer-color: #ffd54f;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0fa564;
  --mdc-checkbox-selected-hover-icon-color: #0fa564;
  --mdc-checkbox-selected-icon-color: #0fa564;
  --mdc-checkbox-selected-pressed-icon-color: #0fa564;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0fa564;
  --mdc-checkbox-selected-hover-state-layer-color: #0fa564;
  --mdc-checkbox-selected-pressed-state-layer-color: #0fa564;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-button {
  --mdc-text-button-label-text-color: #000;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: #000;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0fa564;
  --mat-text-button-state-layer-color: #0fa564;
  --mat-text-button-ripple-color: rgba(15, 165, 100, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ffd54f;
  --mat-text-button-state-layer-color: #ffd54f;
  --mat-text-button-ripple-color: rgba(255, 213, 79, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0fa564;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ffd54f;
  --mdc-filled-button-label-text-color: #000;
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0fa564;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ffd54f;
  --mdc-protected-button-label-text-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button:hover, .mat-mdc-raised-button:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-mdc-raised-button:active, .mat-mdc-raised-button:focus:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: #000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #000;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0fa564;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #0fa564;
  --mat-outlined-button-ripple-color: rgba(15, 165, 100, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ffd54f;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #ffd54f;
  --mat-outlined-button-ripple-color: rgba(255, 213, 79, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-button {
  --mdc-text-button-container-height: 36px;
}

.mat-mdc-raised-button {
  --mdc-protected-button-container-height: 36px;
}

.mat-mdc-unelevated-button {
  --mdc-filled-button-container-height: 36px;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-container-height: 36px;
}

.mat-mdc-icon-button {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: #000;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0fa564;
  --mat-icon-button-state-layer-color: #0fa564;
  --mat-icon-button-ripple-color: rgba(15, 165, 100, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ffd54f;
  --mat-icon-button-state-layer-color: #ffd54f;
  --mat-icon-button-ripple-color: rgba(255, 213, 79, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary,
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #0fa564;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent,
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #ffd54f;
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn,
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #ffd54f;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #0fa564;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #ffd54f;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #0fa564;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #ffd54f;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #0fa564;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(15, 165, 100, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(15, 165, 100, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(15, 165, 100, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #0fa564;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(15, 165, 100, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #ffd54f;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 213, 79, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 213, 79, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 213, 79, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 213, 79, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #ffd54f;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #0fa564;
}
.mat-icon.mat-accent {
  --mat-icon-color: #ffd54f;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #0fa564;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #0fa564;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #0fa564;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #ffd54f;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #ffd54f;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #ffd54f;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #0fa564;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #ffd54f;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-badge-text-font: Noto Sans KR;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 600 27px / 33px Noto Sans KR;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 600 24px / 30px Noto Sans KR;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 600 20px / 22px Noto Sans KR;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 500 14px / 22px Noto Sans KR;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 500 calc(13px * 0.83) / 22px Noto Sans KR;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 500 calc(13px * 0.67) / 22px Noto Sans KR;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 16px / 22px Noto Sans KR;
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 500 13px / 22px Noto Sans KR;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 500 12px / 22px Noto Sans KR;
  letter-spacing: normal;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 600 112px / 112px Noto Sans KR;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 600 56px / 56px Noto Sans KR;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 600 45px / 48px Noto Sans KR;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 600 34px / 40px Noto Sans KR;
  letter-spacing: normal;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Noto Sans KR;
  --mat-bottom-sheet-container-text-line-height: 22px;
  --mat-bottom-sheet-container-text-size: 13px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 500;
}

html {
  --mat-legacy-button-toggle-text-font: Noto Sans KR;
  --mat-standard-button-toggle-text-font: Noto Sans KR;
}

html {
  --mat-datepicker-calendar-text-font: Noto Sans KR;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 600;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 600;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 500;
}

html {
  --mat-expansion-header-text-font: Noto Sans KR;
  --mat-expansion-header-text-size: 16px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Noto Sans KR;
  --mat-expansion-container-text-line-height: 22px;
  --mat-expansion-container-text-size: 13px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 500;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 13px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 13px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Noto Sans KR;
  --mat-stepper-header-label-text-font: Noto Sans KR;
  --mat-stepper-header-label-text-size: 13px;
  --mat-stepper-header-label-text-weight: 500;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

html {
  --mat-toolbar-title-text-font: Noto Sans KR;
  --mat-toolbar-title-text-line-height: 30px;
  --mat-toolbar-title-text-size: 24px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 600;
}

html {
  --mat-tree-node-text-font: Noto Sans KR;
  --mat-tree-node-text-size: 13px;
  --mat-tree-node-text-weight: 500;
}

html {
  --mat-option-label-text-font: Noto Sans KR;
  --mat-option-label-text-line-height: 22px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 500;
}

html {
  --mat-optgroup-label-text-font: Noto Sans KR;
  --mat-optgroup-label-text-line-height: 22px;
  --mat-optgroup-label-text-size: 14px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 500;
}

html {
  --mat-card-title-text-font: Noto Sans KR;
  --mat-card-title-text-line-height: 30px;
  --mat-card-title-text-size: 24px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 600;
  --mat-card-subtitle-text-font: Noto Sans KR;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 16px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Noto Sans KR;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 500;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-label-text-font: Noto Sans KR;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 500;
  --mdc-outlined-text-field-label-text-font: Noto Sans KR;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 500;
  --mat-form-field-container-text-font: Noto Sans KR;
  --mat-form-field-container-text-line-height: 22px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 500;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Noto Sans KR;
  --mat-form-field-subscript-text-line-height: 22px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 500;
}

html {
  --mat-select-trigger-text-font: Noto Sans KR;
  --mat-select-trigger-text-line-height: 22px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 500;
}

html {
  --mdc-dialog-subhead-font: Noto Sans KR;
  --mdc-dialog-subhead-line-height: 30px;
  --mdc-dialog-subhead-size: 24px;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Noto Sans KR;
  --mdc-dialog-supporting-text-line-height: 22px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 500;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Noto Sans KR;
  --mdc-chip-label-text-line-height: 22px;
  --mdc-chip-label-text-size: 13px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 500;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-font: Noto Sans KR;
  --mat-slide-toggle-label-text-size: 13px;
  --mat-slide-toggle-label-text-tracking: normal;
  --mat-slide-toggle-label-text-line-height: 22px;
  --mat-slide-toggle-label-text-weight: 500;
}
.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Noto Sans KR));
  font-size: var(--mdc-typography-body2-font-size, 13px);
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: var(--mdc-typography-body2-font-weight, 500);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

html {
  --mdc-slider-label-label-text-font: Noto Sans KR;
  --mdc-slider-label-label-text-size: 16px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Noto Sans KR;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 22px;
  --mat-menu-item-label-text-weight: 500;
}

html {
  --mdc-list-list-item-label-text-font: Noto Sans KR;
  --mdc-list-list-item-label-text-line-height: 22px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 500;
  --mdc-list-list-item-supporting-text-font: Noto Sans KR;
  --mdc-list-list-item-supporting-text-line-height: 22px;
  --mdc-list-list-item-supporting-text-size: 13px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 500;
  --mdc-list-list-item-trailing-supporting-text-font: Noto Sans KR;
  --mdc-list-list-item-trailing-supporting-text-line-height: 22px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 500;
}

.mdc-list-group__subheader {
  font: 600 20px / 22px Noto Sans KR;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-font: Noto Sans KR;
  --mat-paginator-container-text-line-height: 22px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 500;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Noto Sans KR;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 600;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Noto Sans KR));
  font-size: var(--mdc-typography-body2-font-size, 13px);
  line-height: var(--mdc-typography-body2-line-height, 22px);
  font-weight: var(--mdc-typography-body2-font-weight, 500);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Noto Sans KR));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-raised-button {
  line-height: inherit;
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Noto Sans KR));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 600);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
html {
  --mdc-extended-fab-label-text-font: Noto Sans KR;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 600;
}

html {
  --mdc-snackbar-supporting-text-font: Noto Sans KR;
  --mdc-snackbar-supporting-text-line-height: 22px;
  --mdc-snackbar-supporting-text-size: 13px;
  --mdc-snackbar-supporting-text-weight: 500;
}

html {
  --mat-table-header-headline-font: Noto Sans KR;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 16px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Noto Sans KR;
  --mat-table-row-item-label-text-line-height: 22px;
  --mat-table-row-item-label-text-size: 13px;
  --mat-table-row-item-label-text-weight: 500;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Noto Sans KR;
  --mat-table-footer-supporting-text-line-height: 22px;
  --mat-table-footer-supporting-text-size: 13px;
  --mat-table-footer-supporting-text-weight: 500;
  --mat-table-footer-supporting-text-tracking: normal;
}

.mat-elevation-z1 {
  border-radius: 5px;
}

.mat-elevation-z1 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.09);
}

.mat-icon-size-10 {
  width: 10px !important;
  height: 10px !important;
  line-height: 10px;
  font-size: 10px;
}

.mat-icon-size-11 {
  width: 11px !important;
  height: 11px !important;
  line-height: 11px;
  font-size: 11px;
}

.mat-icon-size-12 {
  width: 12px !important;
  height: 12px !important;
  line-height: 12px;
  font-size: 12px;
}

.mat-icon-size-13 {
  width: 13px !important;
  height: 13px !important;
  line-height: 13px;
  font-size: 13px;
}

.mat-icon-size-14 {
  width: 14px !important;
  height: 14px !important;
  line-height: 14px;
  font-size: 14px;
}

.mat-icon-size-15 {
  width: 15px !important;
  height: 15px !important;
  line-height: 15px;
  font-size: 15px;
}

.mat-icon-size-16 {
  width: 16px !important;
  height: 16px !important;
  line-height: 16px;
  font-size: 16px;
}

.mat-icon-size-17 {
  width: 17px !important;
  height: 17px !important;
  line-height: 17px;
  font-size: 17px;
}

.mat-icon-size-18 {
  width: 18px !important;
  height: 18px !important;
  line-height: 18px;
  font-size: 18px;
}

.mat-icon-size-19 {
  width: 19px !important;
  height: 19px !important;
  line-height: 19px;
  font-size: 19px;
}

.mat-icon-size-20 {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px;
  font-size: 20px;
}

.mat-icon-size-21 {
  width: 21px !important;
  height: 21px !important;
  line-height: 21px;
  font-size: 21px;
}

.mat-icon-size-22 {
  width: 22px !important;
  height: 22px !important;
  line-height: 22px;
  font-size: 22px;
}

.mat-icon-size-23 {
  width: 23px !important;
  height: 23px !important;
  line-height: 23px;
  font-size: 23px;
}

.mat-icon-size-24 {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px;
  font-size: 24px;
}

.mat-icon-size-25 {
  width: 25px !important;
  height: 25px !important;
  line-height: 25px;
  font-size: 25px;
}

.mat-icon-size-26 {
  width: 26px !important;
  height: 26px !important;
  line-height: 26px;
  font-size: 26px;
}

.mat-icon-size-27 {
  width: 27px !important;
  height: 27px !important;
  line-height: 27px;
  font-size: 27px;
}

.mat-icon-size-28 {
  width: 28px !important;
  height: 28px !important;
  line-height: 28px;
  font-size: 28px;
}

.mat-icon-size-29 {
  width: 29px !important;
  height: 29px !important;
  line-height: 29px;
  font-size: 29px;
}

.mat-icon-size-30 {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px;
  font-size: 30px;
}

.mat-icon-size-31 {
  width: 31px !important;
  height: 31px !important;
  line-height: 31px;
  font-size: 31px;
}

.mat-icon-size-32 {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px;
  font-size: 32px;
}

.mat-icon-size-33 {
  width: 33px !important;
  height: 33px !important;
  line-height: 33px;
  font-size: 33px;
}

.mat-icon-size-34 {
  width: 34px !important;
  height: 34px !important;
  line-height: 34px;
  font-size: 34px;
}

.mat-icon-size-35 {
  width: 35px !important;
  height: 35px !important;
  line-height: 35px;
  font-size: 35px;
}

.mat-icon-size-36 {
  width: 36px !important;
  height: 36px !important;
  line-height: 36px;
  font-size: 36px;
}

.mat-icon-size-37 {
  width: 37px !important;
  height: 37px !important;
  line-height: 37px;
  font-size: 37px;
}

.mat-icon-size-38 {
  width: 38px !important;
  height: 38px !important;
  line-height: 38px;
  font-size: 38px;
}

.mat-icon-size-39 {
  width: 39px !important;
  height: 39px !important;
  line-height: 39px;
  font-size: 39px;
}

.mat-icon-size-40 {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px;
  font-size: 40px;
}

.mat-icon-size-41 {
  width: 41px !important;
  height: 41px !important;
  line-height: 41px;
  font-size: 41px;
}

.mat-icon-size-42 {
  width: 42px !important;
  height: 42px !important;
  line-height: 42px;
  font-size: 42px;
}

.mat-icon-size-43 {
  width: 43px !important;
  height: 43px !important;
  line-height: 43px;
  font-size: 43px;
}

.mat-icon-size-44 {
  width: 44px !important;
  height: 44px !important;
  line-height: 44px;
  font-size: 44px;
}

.mat-icon-size-45 {
  width: 45px !important;
  height: 45px !important;
  line-height: 45px;
  font-size: 45px;
}

.mat-icon-size-46 {
  width: 46px !important;
  height: 46px !important;
  line-height: 46px;
  font-size: 46px;
}

.mat-icon-size-47 {
  width: 47px !important;
  height: 47px !important;
  line-height: 47px;
  font-size: 47px;
}

.mat-icon-size-48 {
  width: 48px !important;
  height: 48px !important;
  line-height: 48px;
  font-size: 48px;
}

.mat-icon-size-49 {
  width: 49px !important;
  height: 49px !important;
  line-height: 49px;
  font-size: 49px;
}

.mat-icon-size-50 {
  width: 50px !important;
  height: 50px !important;
  line-height: 50px;
  font-size: 50px;
}

.mat-icon-size-51 {
  width: 51px !important;
  height: 51px !important;
  line-height: 51px;
  font-size: 51px;
}

.mat-icon-size-52 {
  width: 52px !important;
  height: 52px !important;
  line-height: 52px;
  font-size: 52px;
}

.mat-icon-size-53 {
  width: 53px !important;
  height: 53px !important;
  line-height: 53px;
  font-size: 53px;
}

.mat-icon-size-54 {
  width: 54px !important;
  height: 54px !important;
  line-height: 54px;
  font-size: 54px;
}

.mat-icon-size-55 {
  width: 55px !important;
  height: 55px !important;
  line-height: 55px;
  font-size: 55px;
}

.mat-icon-size-56 {
  width: 56px !important;
  height: 56px !important;
  line-height: 56px;
  font-size: 56px;
}

.mat-icon-size-57 {
  width: 57px !important;
  height: 57px !important;
  line-height: 57px;
  font-size: 57px;
}

.mat-icon-size-58 {
  width: 58px !important;
  height: 58px !important;
  line-height: 58px;
  font-size: 58px;
}

.mat-icon-size-59 {
  width: 59px !important;
  height: 59px !important;
  line-height: 59px;
  font-size: 59px;
}

.mat-icon-size-60 {
  width: 60px !important;
  height: 60px !important;
  line-height: 60px;
  font-size: 60px;
}

.mat-icon-size-61 {
  width: 61px !important;
  height: 61px !important;
  line-height: 61px;
  font-size: 61px;
}

.mat-icon-size-62 {
  width: 62px !important;
  height: 62px !important;
  line-height: 62px;
  font-size: 62px;
}

.mat-icon-size-63 {
  width: 63px !important;
  height: 63px !important;
  line-height: 63px;
  font-size: 63px;
}

.mat-icon-size-64 {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px;
  font-size: 64px;
}

.mat-icon-size-65 {
  width: 65px !important;
  height: 65px !important;
  line-height: 65px;
  font-size: 65px;
}

.mat-icon-size-66 {
  width: 66px !important;
  height: 66px !important;
  line-height: 66px;
  font-size: 66px;
}

.mat-icon-size-67 {
  width: 67px !important;
  height: 67px !important;
  line-height: 67px;
  font-size: 67px;
}

.mat-icon-size-68 {
  width: 68px !important;
  height: 68px !important;
  line-height: 68px;
  font-size: 68px;
}

.mat-icon-size-69 {
  width: 69px !important;
  height: 69px !important;
  line-height: 69px;
  font-size: 69px;
}

.mat-icon-size-70 {
  width: 70px !important;
  height: 70px !important;
  line-height: 70px;
  font-size: 70px;
}

.mat-icon-size-71 {
  width: 71px !important;
  height: 71px !important;
  line-height: 71px;
  font-size: 71px;
}

.mat-icon-size-72 {
  width: 72px !important;
  height: 72px !important;
  line-height: 72px;
  font-size: 72px;
}

.mat-icon-size-73 {
  width: 73px !important;
  height: 73px !important;
  line-height: 73px;
  font-size: 73px;
}

.mat-icon-size-74 {
  width: 74px !important;
  height: 74px !important;
  line-height: 74px;
  font-size: 74px;
}

.mat-icon-size-75 {
  width: 75px !important;
  height: 75px !important;
  line-height: 75px;
  font-size: 75px;
}

.mat-icon-size-76 {
  width: 76px !important;
  height: 76px !important;
  line-height: 76px;
  font-size: 76px;
}

.mat-icon-size-77 {
  width: 77px !important;
  height: 77px !important;
  line-height: 77px;
  font-size: 77px;
}

.mat-icon-size-78 {
  width: 78px !important;
  height: 78px !important;
  line-height: 78px;
  font-size: 78px;
}

.mat-icon-size-79 {
  width: 79px !important;
  height: 79px !important;
  line-height: 79px;
  font-size: 79px;
}

.mat-icon-size-80 {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px;
  font-size: 80px;
}

.mat-icon-size-81 {
  width: 81px !important;
  height: 81px !important;
  line-height: 81px;
  font-size: 81px;
}

.mat-icon-size-82 {
  width: 82px !important;
  height: 82px !important;
  line-height: 82px;
  font-size: 82px;
}

.mat-icon-size-83 {
  width: 83px !important;
  height: 83px !important;
  line-height: 83px;
  font-size: 83px;
}

.mat-icon-size-84 {
  width: 84px !important;
  height: 84px !important;
  line-height: 84px;
  font-size: 84px;
}

.mat-icon-size-85 {
  width: 85px !important;
  height: 85px !important;
  line-height: 85px;
  font-size: 85px;
}

.mat-icon-size-86 {
  width: 86px !important;
  height: 86px !important;
  line-height: 86px;
  font-size: 86px;
}

.mat-icon-size-87 {
  width: 87px !important;
  height: 87px !important;
  line-height: 87px;
  font-size: 87px;
}

.mat-icon-size-88 {
  width: 88px !important;
  height: 88px !important;
  line-height: 88px;
  font-size: 88px;
}

.mat-icon-size-89 {
  width: 89px !important;
  height: 89px !important;
  line-height: 89px;
  font-size: 89px;
}

.mat-icon-size-90 {
  width: 90px !important;
  height: 90px !important;
  line-height: 90px;
  font-size: 90px;
}

.mat-icon-size-91 {
  width: 91px !important;
  height: 91px !important;
  line-height: 91px;
  font-size: 91px;
}

.mat-icon-size-92 {
  width: 92px !important;
  height: 92px !important;
  line-height: 92px;
  font-size: 92px;
}

.mat-icon-size-93 {
  width: 93px !important;
  height: 93px !important;
  line-height: 93px;
  font-size: 93px;
}

.mat-icon-size-94 {
  width: 94px !important;
  height: 94px !important;
  line-height: 94px;
  font-size: 94px;
}

.mat-icon-size-95 {
  width: 95px !important;
  height: 95px !important;
  line-height: 95px;
  font-size: 95px;
}

.mat-icon-size-96 {
  width: 96px !important;
  height: 96px !important;
  line-height: 96px;
  font-size: 96px;
}

.mat-icon-size-97 {
  width: 97px !important;
  height: 97px !important;
  line-height: 97px;
  font-size: 97px;
}

.mat-icon-size-98 {
  width: 98px !important;
  height: 98px !important;
  line-height: 98px;
  font-size: 98px;
}

.mat-icon-size-99 {
  width: 99px !important;
  height: 99px !important;
  line-height: 99px;
  font-size: 99px;
}

.mat-icon-size-100 {
  width: 100px !important;
  height: 100px !important;
  line-height: 100px;
  font-size: 100px;
}

.mat-icon-size-101 {
  width: 101px !important;
  height: 101px !important;
  line-height: 101px;
  font-size: 101px;
}

.mat-icon-size-102 {
  width: 102px !important;
  height: 102px !important;
  line-height: 102px;
  font-size: 102px;
}

.mat-icon-size-103 {
  width: 103px !important;
  height: 103px !important;
  line-height: 103px;
  font-size: 103px;
}

.mat-icon-size-104 {
  width: 104px !important;
  height: 104px !important;
  line-height: 104px;
  font-size: 104px;
}

.mat-icon-size-105 {
  width: 105px !important;
  height: 105px !important;
  line-height: 105px;
  font-size: 105px;
}

.mat-icon-size-106 {
  width: 106px !important;
  height: 106px !important;
  line-height: 106px;
  font-size: 106px;
}

.mat-icon-size-107 {
  width: 107px !important;
  height: 107px !important;
  line-height: 107px;
  font-size: 107px;
}

.mat-icon-size-108 {
  width: 108px !important;
  height: 108px !important;
  line-height: 108px;
  font-size: 108px;
}

.mat-icon-size-109 {
  width: 109px !important;
  height: 109px !important;
  line-height: 109px;
  font-size: 109px;
}

.mat-icon-size-110 {
  width: 110px !important;
  height: 110px !important;
  line-height: 110px;
  font-size: 110px;
}

.mat-icon-size-111 {
  width: 111px !important;
  height: 111px !important;
  line-height: 111px;
  font-size: 111px;
}

.mat-icon-size-112 {
  width: 112px !important;
  height: 112px !important;
  line-height: 112px;
  font-size: 112px;
}

.mat-icon-size-113 {
  width: 113px !important;
  height: 113px !important;
  line-height: 113px;
  font-size: 113px;
}

.mat-icon-size-114 {
  width: 114px !important;
  height: 114px !important;
  line-height: 114px;
  font-size: 114px;
}

.mat-icon-size-115 {
  width: 115px !important;
  height: 115px !important;
  line-height: 115px;
  font-size: 115px;
}

.mat-icon-size-116 {
  width: 116px !important;
  height: 116px !important;
  line-height: 116px;
  font-size: 116px;
}

.mat-icon-size-117 {
  width: 117px !important;
  height: 117px !important;
  line-height: 117px;
  font-size: 117px;
}

.mat-icon-size-118 {
  width: 118px !important;
  height: 118px !important;
  line-height: 118px;
  font-size: 118px;
}

.mat-icon-size-119 {
  width: 119px !important;
  height: 119px !important;
  line-height: 119px;
  font-size: 119px;
}

.mat-icon-size-120 {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px;
  font-size: 120px;
}

.mat-icon-size-121 {
  width: 121px !important;
  height: 121px !important;
  line-height: 121px;
  font-size: 121px;
}

.mat-icon-size-122 {
  width: 122px !important;
  height: 122px !important;
  line-height: 122px;
  font-size: 122px;
}

.mat-icon-size-123 {
  width: 123px !important;
  height: 123px !important;
  line-height: 123px;
  font-size: 123px;
}

.mat-icon-size-124 {
  width: 124px !important;
  height: 124px !important;
  line-height: 124px;
  font-size: 124px;
}

.mat-icon-size-125 {
  width: 125px !important;
  height: 125px !important;
  line-height: 125px;
  font-size: 125px;
}

.mat-icon-size-126 {
  width: 126px !important;
  height: 126px !important;
  line-height: 126px;
  font-size: 126px;
}

.mat-icon-size-127 {
  width: 127px !important;
  height: 127px !important;
  line-height: 127px;
  font-size: 127px;
}

.mat-icon-size-128 {
  width: 128px !important;
  height: 128px !important;
  line-height: 128px;
  font-size: 128px;
}

.mat-icon-size-129 {
  width: 129px !important;
  height: 129px !important;
  line-height: 129px;
  font-size: 129px;
}

.mat-icon-size-130 {
  width: 130px !important;
  height: 130px !important;
  line-height: 130px;
  font-size: 130px;
}

.mat-icon-size-131 {
  width: 131px !important;
  height: 131px !important;
  line-height: 131px;
  font-size: 131px;
}

.mat-icon-size-132 {
  width: 132px !important;
  height: 132px !important;
  line-height: 132px;
  font-size: 132px;
}

.mat-icon-size-133 {
  width: 133px !important;
  height: 133px !important;
  line-height: 133px;
  font-size: 133px;
}

.mat-icon-size-134 {
  width: 134px !important;
  height: 134px !important;
  line-height: 134px;
  font-size: 134px;
}

.mat-icon-size-135 {
  width: 135px !important;
  height: 135px !important;
  line-height: 135px;
  font-size: 135px;
}

.mat-icon-size-136 {
  width: 136px !important;
  height: 136px !important;
  line-height: 136px;
  font-size: 136px;
}

.mat-icon-size-137 {
  width: 137px !important;
  height: 137px !important;
  line-height: 137px;
  font-size: 137px;
}

.mat-icon-size-138 {
  width: 138px !important;
  height: 138px !important;
  line-height: 138px;
  font-size: 138px;
}

.mat-icon-size-139 {
  width: 139px !important;
  height: 139px !important;
  line-height: 139px;
  font-size: 139px;
}

.mat-icon-size-140 {
  width: 140px !important;
  height: 140px !important;
  line-height: 140px;
  font-size: 140px;
}

.mat-icon-size-141 {
  width: 141px !important;
  height: 141px !important;
  line-height: 141px;
  font-size: 141px;
}

.mat-icon-size-142 {
  width: 142px !important;
  height: 142px !important;
  line-height: 142px;
  font-size: 142px;
}

.mat-icon-size-143 {
  width: 143px !important;
  height: 143px !important;
  line-height: 143px;
  font-size: 143px;
}

.mat-icon-size-144 {
  width: 144px !important;
  height: 144px !important;
  line-height: 144px;
  font-size: 144px;
}

.mat-icon-size-145 {
  width: 145px !important;
  height: 145px !important;
  line-height: 145px;
  font-size: 145px;
}

.mat-icon-size-146 {
  width: 146px !important;
  height: 146px !important;
  line-height: 146px;
  font-size: 146px;
}

.mat-icon-size-147 {
  width: 147px !important;
  height: 147px !important;
  line-height: 147px;
  font-size: 147px;
}

.mat-icon-size-148 {
  width: 148px !important;
  height: 148px !important;
  line-height: 148px;
  font-size: 148px;
}

.mat-icon-size-149 {
  width: 149px !important;
  height: 149px !important;
  line-height: 149px;
  font-size: 149px;
}

.mat-icon-size-150 {
  width: 150px !important;
  height: 150px !important;
  line-height: 150px;
  font-size: 150px;
}

.mat-icon-size-151 {
  width: 151px !important;
  height: 151px !important;
  line-height: 151px;
  font-size: 151px;
}

.mat-icon-size-152 {
  width: 152px !important;
  height: 152px !important;
  line-height: 152px;
  font-size: 152px;
}

.mat-icon-size-153 {
  width: 153px !important;
  height: 153px !important;
  line-height: 153px;
  font-size: 153px;
}

.mat-icon-size-154 {
  width: 154px !important;
  height: 154px !important;
  line-height: 154px;
  font-size: 154px;
}

.mat-icon-size-155 {
  width: 155px !important;
  height: 155px !important;
  line-height: 155px;
  font-size: 155px;
}

.mat-icon-size-156 {
  width: 156px !important;
  height: 156px !important;
  line-height: 156px;
  font-size: 156px;
}

.mat-icon-size-157 {
  width: 157px !important;
  height: 157px !important;
  line-height: 157px;
  font-size: 157px;
}

.mat-icon-size-158 {
  width: 158px !important;
  height: 158px !important;
  line-height: 158px;
  font-size: 158px;
}

.mat-icon-size-159 {
  width: 159px !important;
  height: 159px !important;
  line-height: 159px;
  font-size: 159px;
}

.mat-icon-size-160 {
  width: 160px !important;
  height: 160px !important;
  line-height: 160px;
  font-size: 160px;
}

.mat-icon-size-161 {
  width: 161px !important;
  height: 161px !important;
  line-height: 161px;
  font-size: 161px;
}

.mat-icon-size-162 {
  width: 162px !important;
  height: 162px !important;
  line-height: 162px;
  font-size: 162px;
}

.mat-icon-size-163 {
  width: 163px !important;
  height: 163px !important;
  line-height: 163px;
  font-size: 163px;
}

.mat-icon-size-164 {
  width: 164px !important;
  height: 164px !important;
  line-height: 164px;
  font-size: 164px;
}

.mat-icon-size-165 {
  width: 165px !important;
  height: 165px !important;
  line-height: 165px;
  font-size: 165px;
}

.mat-icon-size-166 {
  width: 166px !important;
  height: 166px !important;
  line-height: 166px;
  font-size: 166px;
}

.mat-icon-size-167 {
  width: 167px !important;
  height: 167px !important;
  line-height: 167px;
  font-size: 167px;
}

.mat-icon-size-168 {
  width: 168px !important;
  height: 168px !important;
  line-height: 168px;
  font-size: 168px;
}

.mat-icon-size-169 {
  width: 169px !important;
  height: 169px !important;
  line-height: 169px;
  font-size: 169px;
}

.mat-icon-size-170 {
  width: 170px !important;
  height: 170px !important;
  line-height: 170px;
  font-size: 170px;
}

.mat-icon-size-171 {
  width: 171px !important;
  height: 171px !important;
  line-height: 171px;
  font-size: 171px;
}

.mat-icon-size-172 {
  width: 172px !important;
  height: 172px !important;
  line-height: 172px;
  font-size: 172px;
}

.mat-icon-size-173 {
  width: 173px !important;
  height: 173px !important;
  line-height: 173px;
  font-size: 173px;
}

.mat-icon-size-174 {
  width: 174px !important;
  height: 174px !important;
  line-height: 174px;
  font-size: 174px;
}

.mat-icon-size-175 {
  width: 175px !important;
  height: 175px !important;
  line-height: 175px;
  font-size: 175px;
}

.mat-icon-size-176 {
  width: 176px !important;
  height: 176px !important;
  line-height: 176px;
  font-size: 176px;
}

.mat-icon-size-177 {
  width: 177px !important;
  height: 177px !important;
  line-height: 177px;
  font-size: 177px;
}

.mat-icon-size-178 {
  width: 178px !important;
  height: 178px !important;
  line-height: 178px;
  font-size: 178px;
}

.mat-icon-size-179 {
  width: 179px !important;
  height: 179px !important;
  line-height: 179px;
  font-size: 179px;
}

.mat-icon-size-180 {
  width: 180px !important;
  height: 180px !important;
  line-height: 180px;
  font-size: 180px;
}

.mat-icon-size-181 {
  width: 181px !important;
  height: 181px !important;
  line-height: 181px;
  font-size: 181px;
}

.mat-icon-size-182 {
  width: 182px !important;
  height: 182px !important;
  line-height: 182px;
  font-size: 182px;
}

.mat-icon-size-183 {
  width: 183px !important;
  height: 183px !important;
  line-height: 183px;
  font-size: 183px;
}

.mat-icon-size-184 {
  width: 184px !important;
  height: 184px !important;
  line-height: 184px;
  font-size: 184px;
}

.mat-icon-size-185 {
  width: 185px !important;
  height: 185px !important;
  line-height: 185px;
  font-size: 185px;
}

.mat-icon-size-186 {
  width: 186px !important;
  height: 186px !important;
  line-height: 186px;
  font-size: 186px;
}

.mat-icon-size-187 {
  width: 187px !important;
  height: 187px !important;
  line-height: 187px;
  font-size: 187px;
}

.mat-icon-size-188 {
  width: 188px !important;
  height: 188px !important;
  line-height: 188px;
  font-size: 188px;
}

.mat-icon-size-189 {
  width: 189px !important;
  height: 189px !important;
  line-height: 189px;
  font-size: 189px;
}

.mat-icon-size-190 {
  width: 190px !important;
  height: 190px !important;
  line-height: 190px;
  font-size: 190px;
}

.mat-icon-size-191 {
  width: 191px !important;
  height: 191px !important;
  line-height: 191px;
  font-size: 191px;
}

.mat-icon-size-192 {
  width: 192px !important;
  height: 192px !important;
  line-height: 192px;
  font-size: 192px;
}

.mat-icon-size-193 {
  width: 193px !important;
  height: 193px !important;
  line-height: 193px;
  font-size: 193px;
}

.mat-icon-size-194 {
  width: 194px !important;
  height: 194px !important;
  line-height: 194px;
  font-size: 194px;
}

.mat-icon-size-195 {
  width: 195px !important;
  height: 195px !important;
  line-height: 195px;
  font-size: 195px;
}

.mat-icon-size-196 {
  width: 196px !important;
  height: 196px !important;
  line-height: 196px;
  font-size: 196px;
}

.mat-icon-size-197 {
  width: 197px !important;
  height: 197px !important;
  line-height: 197px;
  font-size: 197px;
}

.mat-icon-size-198 {
  width: 198px !important;
  height: 198px !important;
  line-height: 198px;
  font-size: 198px;
}

.mat-icon-size-199 {
  width: 199px !important;
  height: 199px !important;
  line-height: 199px;
  font-size: 199px;
}

.mat-icon-size-200 {
  width: 200px !important;
  height: 200px !important;
  line-height: 200px;
  font-size: 200px;
}

.mat-mini-fab .mat-button-wrapper {
  font-size: 12px;
  font-weight: 400 !important;
  line-height: 12px !important;
}

.mat-mini-fab {
  font-size: 13px;
  font-weight: 600 !important;
  line-height: 13px !important;
}

.mat-fab .mat-button-wrapper {
  font-size: 13px;
  line-height: 13px !important;
  font-weight: 600;
}

.mat-raised-button {
  padding: 6px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

.mat-stroked-button {
  padding: 6px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.25);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #212121;
  background-color: #212121;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle, .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #ffffff;
}

.mat-radio-label-content {
  font-weight: 600;
}

.mat-chip .mat-chip-trailing-icon.mat-icon, .mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.mat-chip.mat-standard-chip {
  background-color: #f1f2f3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
  border-bottom: none !important;
}

.mat-bottom-sheet-container {
  border-radius: 10px 10px 0 0;
}

.mat-horizontal-stepper-header-container {
  display: none !important;
}

.mat-horizontal-content-container {
  padding: 0 5px 24px 5px !important;
}

.mat-horizontal-stepper-content {
  padding: 1px;
}

.mat-padding-bottom-60 {
  padding-bottom: 60% !important;
}

.mat-padding-bottom-50 {
  padding-bottom: 50% !important;
}

.mat-padding-bottom-15 {
  padding-bottom: 15% !important;
}

.mat-padding-bottom-5 {
  padding-bottom: 5% !important;
}

.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

.mat-form-field-suffix > button {
  top: -3px;
}

.mat-form-field-suffix > .mat-icon-button {
  top: 3px;
}

.mat-form-field-subscript-wrapper {
  font-size: 13px !important;
  padding-left: 0.5em !important;
  padding-right: 0 !important;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  vertical-align: bottom;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-form-field {
  font-size: 14px;
  padding-bottom: 8px;
  font-weight: 600;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0.6em 1em;
}

.mdc-label {
  font-weight: 500;
}

.mdc-radio {
  margin-right: 0 !important;
  padding: 0 0 !important;
}

mat-hint {
  padding: 0 5px !important;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 5px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay {
  background-color: #FFFFFF !important;
}

.mdc-form-field > label, mat-radio-group mat-radio-button > label {
  padding-left: 4px !important;
  padding-right: 10px !important;
  font-weight: 600 !important;
}

@media (max-width: 400px) {
  .mdc-form-field > label, mat-radio-group mat-radio-button > label {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .mdc-radio {
    margin-right: 5px !important;
  }
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 20px;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-accordion .mat-expansion-panel {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: 1px solid #eaeaea;
}

.mat-tooltip {
  font-size: 13px;
  background-color: #666666;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #333333;
}

.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.mat-ink-bar {
  position: absolute;
  bottom: 0;
  height: 3px !important;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
  background-color: #212121 !important;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: "S-CoreDream-5Medium", "Noto Sans CJK KR";
  --mat-tab-header-label-text-size: 15px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 600;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #212121;
  --mdc-tab-indicator-active-indicator-color: #0fa564;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #0fa564;
  --mat-tab-header-active-ripple-color: #0fa564;
  --mat-tab-header-inactive-ripple-color: #0fa564;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0fa564;
  --mat-tab-header-active-hover-label-text-color: #0fa564;
  --mat-tab-header-active-focus-indicator-color: #0fa564;
  --mat-tab-header-active-hover-indicator-color: #0fa564;
}

.mat-mdc-tab-label-container {
  border-bottom: 1px solid #E4E4E4 !important;
}

.mat-mdc-dialog-title, .mdc-dialog__title {
  font-weight: 700 !important;
}

.mat-mdc-dialog-content, .mdc-dialog__content {
  color: #212121 !important;
  padding-bottom: 60px !important;
  line-height: 1.8 !important;
}

.mat-mdc-raised-button.mat-mdc-button-base {
  height: 40px !important;
}

.mdc-button__label {
  font-weight: 700 !important;
}

@media (max-width: 400px) {
  .mat-mdc-dialog-content, .mdc-dialog__content {
    padding-bottom: 30px !important;
  }
}
.mat-mdc-paginator-container {
  padding: 0 0 !important;
  color: #555555 !important;
}

.mat-mdc-paginator {
  margin-top: 0 !important;
}

.swiper-button-next, .swiper-button-prev {
  display: inline;
}

.swiper-pagination-bullet-active {
  width: 7px !important;
  height: 7px !important;
  border-color: transparent !important;
  background: #666666 !important;
  border: 1px solid #666666;
  opacity: 1 !important;
}

.swiper-button-next, .swiper-button-prev {
  align-items: center;
  color: #999999 !important;
  cursor: pointer;
  display: flex;
  height: var(--swiper-navigation-size);
  justify-content: center;
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 10) !important;
  z-index: 10;
}

@media screen and (max-width: 599px) {
  .swiper-button-next, .swiper-button-prev {
    color: #f6f7f7 !important;
    opacity: 0.1 !important;
  }
}