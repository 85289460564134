.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

.mat-form-field-suffix > button {
  top: -3px;
}

.mat-form-field-suffix > .mat-icon-button {
  top: 3px;
}

.mat-form-field-subscript-wrapper {
  font-size: 13px !important;
  padding-left: 0.5em !important;
  padding-right: 0 !important;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  vertical-align: bottom;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-form-field {
  font-size: 14px;
  padding-bottom: 8px;
  font-weight: 600;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0.6em 1em;
}

.mdc-label{
  font-weight: 500;
}

.mdc-radio{
  margin-right: 0!important;
  padding: 0 0 !important;
}

mat-hint {
  padding: 0 5px !important;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 5px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay {
  background-color: #FFFFFF !important;
}

.mdc-form-field>label, mat-radio-group mat-radio-button>label{
  padding-left: 4px !important;
  padding-right: 10px !important;
  font-weight: 600!important;
}

@media (max-width: 400px){
  .mdc-form-field > label, mat-radio-group mat-radio-button > label{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .mdc-radio{
    margin-right: 5px !important;
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
  padding-top: 20px;
}
