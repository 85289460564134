@import './variables';

.bg-smoke {
  background: whitesmoke !important;
}

.bg-white {
  background: white !important;
}

.bg-gray-fa {
  background-color: #fafafa !important;
}

.bg-gray-ea {
  background-color: #eaeaea !important;
}

.bg-gray-da {
  background-color: #dadada !important;
}
.bg-gray-ca {
  background-color: #cacaca !important;
}

.bg-gray-ba {
  background-color: #bababa !important;
}

.bg-gray-light {
  background-color: $gray-light !important;
  color: #333333 !important;
}

.bg-gray {
  color: white !important;
  background-color: $gray !important;
}

.bg-gray-deep {
  color: white !important;
  background-color: $gray-deep !important;
}

.bg-gray-dark {
  color: white !important;
  background-color: $gray-dark !important;

}

.bg-gray-33 {
  color: white;
  background-color: #333333;

}

.bg-primary-light {
  background: $primary-light !important;
  color: white !important;
}

.bg-primary-darker {
  background: $primary-darker !important;
  color: white !important;
}

.bg-primary {
  background: $primary !important;
  color: white !important;
}

.bg-accent {
  background: $accent !important;
  color: #212121;
}

.bg-accent-light {
  background: $accent-light !important;
  color: #212121 !important;
}

.bg-accent-darker {
  background: #E8C248 !important;
  color: #212121;
}

.bg-warn-light {
  background-color: $warn-light !important;
}

.bg-warn {
  background-color: $warn !important;
}

.bg-pink {
  background-color: #DB5291;
}

.bg-mint {
  background-color: #4bcfd0 !important;
  color: white !important;
}

.bg-mint-light {
  background-color: #33cccc !important;
  color: white !important;
}

.c-mint {
  color: #40bfc0 !important;
}

.bg-mint-dark {
  background-color: #14b2ae !important;
  color: white !important;
}

.c-mint-dark {
  color: #14b2ae !important;
}

//pantone color
.bg-p-coralparadise {
  background-color: #f05e73 !important;
}

.bg-p-flamingoplume {
  background-color: #de838d !important;
}

.bg-p-murexshell {
  background-color: #f8a3a4 !important;
}

.bg-p-blushingrose {
  background-color: #f2c1ac !important;
}

.bg-p-paledogwood {
  background-color: #efd1c6 !important;
}

.bg-p-graysand {
  background-color: #e5ccaf !important;
}

.bg-p-graylilac {
  background-color: #d4cacd !important;
}

.bg-p-agategray {
  background-color: #b1b09f !important;
}

.bg-p-pleinair {
  background-color: #bfcad6 !important;
}


.bg-p-09-mimosa {
  background-color: #f0c05a !important;
}

.bg-p-10-turquoise {
  background-color: #45b5aa !important;
}

.bg-p-11-honeysuckle {
  background-color: #d85a7b !important;
}

.bg-p-12-tangerinetango {
  background-color: #dd4124 !important;
}

.bg-p-13-emerald {
  background-color: #00997b !important;
}

.bg-p-14-radiantorchid {
  background-color: #ad5e99 !important;
}

.bg-p-15-marsala {
  background-color: #964f4c !important;
}

.bg-p-16-quartz {
  background-color: #f7caca !important;
}

.bg-p-16-serenity {
  background-color: #93a9d1 !important;
}

.bg-p-17-greenery {
  background-color: #88b04b !important;
}

.bg-p-18-ultraviolet {
  background-color: #5f4b8b !important;
}

.bg-p-19-livingcoral {
  background-color: #ff6f61 !important;
}

.c-p-19-livingcoral {
  color: #ff6f61 !important;
}

.bg-p-20-classicblue {
  background-color: #0f4c81 !important;
}

.bg-p-21-llluminatiing {
  background-color: #f5df4d !important;
}

.bg-p-22-veryperi {
  background-color: #6667ab !important;
}

.bg-p-23-vivamagent {
  background-color: #e24666 !important;
}

.c-p-23-vivamagent {
  color: #e24666 !important;
}

.bg-white-blur {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}


.bg-white-shadow {
  background: #ffffff;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid inherit;
}

.bg-blue-light {
  background: #F8FCFF;
}

.bg-sheet {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.bg-gn-light{
  background: #E6F6EFFF !important;
}

.bg-empty{
  background-color: #FFFFFF !important;
  border-radius: 20px !important;
}
